<template>
  <div class="container mt-5">
    <BackAndMainButtons :show-main="false"></BackAndMainButtons>

  </div>
  <div v-if="loadCompany" class="block__loader">
    <loader></loader>
  </div>
  <div v-else class="form bg-white p-5 mt-3 mx-2 rounded-4">
    <CompanyInfoForm :data="{
    name: this.name,
    phone: this.phone,
    fieldActivity: this.fieldActivity,
    numberEmployees: this.numberEmployees,
    admin: this.admin,
    admins: this.admins
  }" @submit="update"/>

  </div>

</template>

<script>
import UserProfileInput from "@/components/User/UserProfileInput.vue";
import api from "@/api";
import Loader from "@/components/UI/Loader.vue";
import CompanyInfoForm from "@/components/Company/CompanyInfoForm.vue";
import BackAndMainButtons from "@/components/UI/BackAndMainButtons.vue";
import autoritiesMixin from '@/mixins/autoritiesMixin';

export default {
  name: "CompanySettings",
  components: {BackAndMainButtons, CompanyInfoForm, Loader, UserProfileInput},
  data() {
    return {
      loadCompany: true,
      name: "",
      phone: "",
      fieldActivity: "",
      numberEmployees: "",
      admin: {},
      admins: []
    }
  },
  mixins: [autoritiesMixin],
  mounted() {
    this.getCompany()
  },
  methods: {
    getCompany() {
      this.loadCompany = true
      api.getCompanyById(this.$route.params.id).then(r => {
        this.name = r.data.name;
        this.phone = r.data.phone;
        this.fieldActivity = r.data.fieldOfActivity;
        this.numberEmployees = r.data.gapOfEmployees;
        this.admin = r.data.representative;
        this.admins = r.data.admins;
        this.loadCompany = false;
      })
    },
    update(data) {
      this.loadCompany = true;

      if (this.isSuperAdmin()) {
        api.updateCompanyAdmin(this.$route.params.id, data.nameCompany, data.phone, data.fieldActivity, data.numberEmployees, data.adminId)
            .then(r => {
              this.name = r.data.name;
              this.phone = r.data.phone;
              this.fieldActivity = r.data.fieldOfActivity;
              this.numberEmployees = r.data.gapOfEmployees;
              this.admin = r.data.representative;
              this.admins = r.data.admins;
              this.loadCompany = false;


            }).catch(err => this.loadCompany = false)
      } else {
        api.updateCompany(this.$route.params.id, data.nameCompany, data.phone, data.fieldActivity, data.numberEmployees, data.adminId).then(r => {
          this.name = r.data.name;
          this.phone = r.data.phone;
          this.fieldActivity = r.data.fieldOfActivity;
          this.numberEmployees = r.data.gapOfEmployees;
          this.admin = r.data.representative;
          this.admins = r.data.admins;
          this.loadCompany = false;


        }).catch(err => this.loadCompany = false)
      }


    }
  }

}
</script>

<style scoped>
.block__loader {
  width: 100%;
  min-height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  width: 100%;
}

@media (min-width: 768px) {
  .form {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .form {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .form {
    width: 40%;
  }
}
</style>