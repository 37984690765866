<template>

  <div class="text-center my-5">
    <h1>{{code}}</h1>
    <h3>{{message}}</h3>
  </div>

</template>

<script>


export default {
  props:{
    code:{
      type: String,
      required: true
    },
    message:{
      type: String,
      required: true
    },
  }

}
</script>

<style scoped>

</style>
