<template>
	<div id="main" class="">
		<div class="wrapper">
			<div class="container register">

				<div class="row">
					<div class="col d-flex justify-content-center">
						<p class="registerTitle h3">{{ $t("register.title") }}</p>
					</div>
				</div>
				<div class="row my-3">

					<div class="col d-flex justify-content-center">
						<form id="formRegisterPage" action="" method="post" @submit.prevent="register">
							<FormTokenDescription v-if="token" :token="token"></FormTokenDescription>

							<div class="input__container">
								<div class="my-1">
									<label class="label__input" for="name">{{ $t("register.first_name") }}</label><br>
									<input id="name" v-model.trim="firstName" autofocus class="registerForm" name="name"
										:placeholder="$t('register.first_name')" type="text">
								</div>
								<div :class="{ messageShow: isFirstNameError }" class="message">
										<p id="firstName" class="error">{{ $t("register.fill_field") }}</p>
							  </div>
							</div>
							<div class="input__container">
								<div class="my-1">
									<label class="label__input" for="name">{{ $t("register.last_name") }}</label><br>
									<input id="lastName" v-model.trim="lastName" autofocus class="registerForm" name="surname"
										:placeholder="$t('register.last_name')" type="text">
								</div>
								<div :class="{ messageShow: isLastNameError }" class="message">
										<p id="lastName" class="error">{{ $t("register.fill_field") }}</p>
							  </div>
							</div>
							<div class="input__container">

<!--                <FormItem :isError="isEmailError" :label="$t('register.email')">-->
<!--                  <input id="email" v-model.trim="email" class="registerForm" placeholder="E-mail"-->
<!--                         type="email" @input="resetLoginError" @keydown.space.prevent>-->
<!--                </FormItem>-->
								<div class="my-1">
									<label class="label__input" for="name">{{ $t("register.email") }}</label><br>
									<input id="email" v-model.trim="email" class="registerForm" name="email" placeholder="E-mail"
										type="email" @input="resetLoginError">
								</div>
								<div :class="{ messageShow: isEmailError }" class="message">
									<p id="email" class="error">{{ $t("register.fill_field") }}</p>
								</div>
							</div>

							<div class="input__container">
								<div class="my-1">
									<label class="label__input" for="name">{{$t("register.phone")}}</label><br>
                  <input v-if="!isLocaleEn" v-imask="mask" :value="phone" class="registerForm" placeholder="$t('popUp_registration.mask_number')"
                         type="text" @accept="phoneAccept" @complete="phoneComplete" @keydown.space.prevent >

                  <input v-else v-imask="maskEn" :value="phone" class="registerForm" placeholder="$t('popUp_registration.mask_number')"
                         type="text" @accept="phoneAccept" @complete="phoneComplete" @keydown.space.prevent >
									<div :class="{ messageShow: isPhoneError }" class="message">
										<p id="phone" class="error">{{ $t("register.fill_field") }}</p>
									</div>
								</div>
							</div>
							<div class="input__container">
								<div class="my-1 password">
									<label class="label__input" for="name">{{ $t("register.password") }}</label><br>
									<input id="password-input" v-model.trim="password" :type="showPassword ? 'text' : 'password'"
										class="registerForm" name="pass" :placeholder="$t('register.password')" @input="checkPass">
									<a :class="{ view: showPassword }" class="password-control" href="#"
										@click="show_hide_password()"></a><br>
									<div :class="{ messageShow: isPasswordError }" class="message">
										<p>{{ $t("register.rule_password") }}</p>
										<p v-if="passwordErrors.length" id="length" class="error">{{ $t("register.rule_min_characters") }}</p>
										<p v-if="passwordErrors.upperCase" id="capital" class="error">{{ $t("register.rule_apital_letter") }}
										</p>
									</div>
									<p v-if="isLoginError" class="error">{{ $t("register.user_exists") }}</p>
									<div v-for="err in passwordErrorMessages" v-show="isPasswordValidError">
										<p class="error">{{ err }}</p>
									</div>
								</div>
							</div>
							<div class="mb-3 mx-1 " v-if="!isLocaleEn">
								<input type="checkbox" id="checkbox" class="custom-checkbox" v-model="checkedPolicy">
								<label for="checkbox" class="toLogin d-inline" :class="{ checkBoxError: errorPolicy }">
									Я принимаю <br class="d-lg-none">
									<a download href="/api/resources/policy.odt">Политику конфеденциальности</a></label>
								<!--<a :href="fileUrl.loc" download>Политику конфеденциальности</a></label>-->
							</div>
							<div class="input__container">
								<div class="my-1 d-flex justify-content-center">
									<loader v-if="isLoading" />
									<input v-else :disabled="isFormDisabled" class="registerButton" type="submit"
										:value="$t('register.sign_up')">
								</div>
							</div>
						</form>
					</div>
				</div>
				<div class="row">
					<div class="col d-flex justify-content-center">
						<p class="toLogin">{{ $t('register.question') }} <a @click="this.$router.push('/login')">
								{{ $t('register.link_to_login') }}</a></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FormTokenDescription from "@/components/Form/FormTokenDescription.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Loader from "@/components/UI/Loader.vue";
import { IMaskDirective } from "vue-imask";
import FormItem from "@/components/Form/FormItem.vue";

export default {
	name: "NewRegister",
	components: {FormItem, Loader, FormTokenDescription },
	data() {
		return {
      showPassword: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      isLocaleEn: false,
      checkedPolicy: false,
      errorPolicy: false,
      passwordErrors: {
        length: false,
        upperCase: false,
      },
      phoneError: false,
      emailError: false,
      lastNameError: false,
      firstNameError: false,
      mask: {
        mask: '+7(000)000-00-00',
        lazy: false,  // make placeholder always visible
      },
      maskEn: {
        mask: '+0(000)000-00-00',
        lazy: false,  // make placeholder always visible
      },
      token: this.$route.query.token,
    }
	},
	directives: {
		imask: IMaskDirective
	},
	created() {
		this.getLocal();
	},
	watch() {

	},
	computed: {
		isPasswordError() {
			return this.passwordErrors.length || this.passwordErrors.upperCase
		},
		isPhoneError() {
			return this.phoneError;
		},
		isEmailError() {
			return this.emailError;
		},
		isLastNameError() {
			return this.lastNameError;
		},
		isFirstNameError() {
			return this.firstNameError;
		},
		isFormDisabled() {
      if (!this.isLocaleEn) {
        return this.isLoginError || this.isPasswordValidError || this.isPhoneError || !this.checkedPolicy || this.isLastNameError || this.isFirstNameError || this.isEmailError;
      }	else {
        return this.isLoginError || this.isPasswordValidError || this.isPhoneError || this.isLastNameError || this.isFirstNameError || this.isEmailError;
      }

      },
		...mapGetters(
			{
				isLoginError: 'AuthModule/isLoginError',
				isLoading: 'isAnyLoading',
				isPasswordValidError: 'AuthModule/isPasswordError',
				passwordErrorMessages: 'AuthModule/getPasswordErrorMessages'
			}
		),
	},
	mounted() {
		this.resetLoginError();
		this.createEventListeners();
	},
	methods: {
		...mapMutations({
			resetLoginError: 'AuthModule/resetLoginError'
		}),

		isAgreePolicy(event) {
			this.errorPolicy = !this.checkedPolicy;
		},

		createEventListeners() {
			let element = document.querySelector('.registerButton');
			element.addEventListener('pointerover', (e) => { this.isAgreePolicy(e) });
		},

		...mapActions({
			registerNewUser: 'AuthModule/onRegister'
		}),
		show_hide_password() {
			this.showPassword = !this.showPassword
		},
		resetErrors() {
			this.resetLoginError()
			this.passwordErrors = {
				length: false,
				upperCase: false,
			}
		},
		checkPass() {
			this.resetErrors();
			if (this.password.length < 8)
				this.passwordErrors.length = true
			if (!(/[A-Z]/.test(this.password)))
				this.passwordErrors.upperCase = true
		},
		checkPhone() {
			this.phoneError = (this.phone.length == 0);
		},
		checkEmail() {
			this.emailError = (this.email.length == 0);
		},
		checkLastName() {
			this.lastNameError = (this.lastName.length == 0);
		},
		checkFirstName() {
			this.firstNameError = (this.firstName.length == 0);
		},
		checkAll() {
			this.checkPass();
			this.checkPhone();
			this.checkEmail();
			this.checkLastName();
			this.checkFirstName();
		},

		register() {
			this.checkAll();
			if (this.isFormDisabled) {
				return
			}
			this.registerNewUser({
				email: this.email,
				password: this.password,
				firstName: this.firstName,
				lastName: this.lastName,
				phone: this.phone,
				token: this.token
			})
		},
		getLocal() {
			const currentLocale = this.$i18n.locale;
			if (currentLocale === 'en') {
				this.isLocaleEn = true;
			}
		},
		phoneAccept(e) {
			this.phone = e.detail.value
		},
		phoneComplete(e) {
			const maskRef = e.detail;
			this.phone = maskRef.value
		},
	}
}
</script>

<style lang="scss" scoped>
#main {
	position: fixed;
	top: 0;
	left: 0;
	overflow-y: scroll;
	background-image: url(@/assets/dark_back.png);
	width: 100%;
	height: 100%;
	color: white;
}

.register {

	font-weight: 400;
	margin-top: 100px;
	margin-bottom: 50px;
}

form {
	width: 40%;
}

.error-label {
	text-align: center;
	color: red;
}

.registerForm {
	width: 100%;
	color: white;
	padding: 15px;
	background: transparent;
	border: 1px #6B6B6B solid;
	border-radius: 20px;
}

.registerForm:focus {
	border: 1px #202FD4 solid;
	outline: none;
	transition: 0.2s;
}

.registerButton {
	width: 100%;
	display: block;
	outline: none;
	padding: 15px 20px;
	background: #2835C1;
	border: none;
	border-radius: 15px;
	text-decoration: none;
	color: #F6F6F5;
	font-size: 20px;
	font-family: Ubuntu;
	font-weight: 400;
	transition: 0.2s;
}

.registerButton[disabled]:hover {
	background-color: grey;
	box-shadow: none;
}

.registerButton:hover {
	box-shadow: 0 0 20px 0.5px #0015FF;
	background: #0017EF;
	transition: 0.2s;
}

.registerButton:active {
	background: #0014CC;
	transition: 0.2s;
}

.label__input {
	margin-left: 15px;
	margin-bottom: 10px;
	font-weight: 700;
	text-transform: uppercase;
}

.password {
	position: relative;
}

.password-control {
	position: absolute;
	top: 53px;
	right: 30px;
	display: inline-block;
	width: 20px;
	height: 20px;
	background: url(@/assets/password/view_icon.png) 0 0 no-repeat;
}

.password-control.view {
	background: url(@/assets/password/no_view_icon.png) 0 0 no-repeat;
}

.message {
	transition: 0.2s;
	font-weight: 400;
	height: 0;
	margin-left: 15px;
	//margin-top: 15px;
	overflow: hidden;
}

.messageShow {
	transition: 0.2s;
	font-weight: 500;
	height: auto;
	margin-left: 15px;
	//margin-top: 15px;
}

.message p {
	margin-bottom: 0;
	font-size: 14px;
}

.input__container {
  display: flex;
  flex-direction: column;
}

.error {
	display: block;
	color: red;
}

.toLogin>a {
	color: #2835C1;
	text-decoration: none;
}

.toLogin>a:hover {
	text-decoration: underline;
}

.custom-checkbox {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

label.toLogin {
	padding: 0 5px;
	cursor: default;
	color: rgba(189, 186, 186, 0.85);
}

label.toLogin {
	display: inline-flex;
	align-items: center;
	user-select: none;
}

label.toLogin::before {
	content: '';
	display: inline-block;
	width: 1em;
	height: 1em;
	flex-shrink: 0;
	flex-grow: 0;
	border: 1px solid #adb5bd;
	border-radius: 0.25em;
	margin-right: 0.5em;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
}

.custom-checkbox:checked+label::before {
	border-color: #0b76ef;
	background-color: #0b76ef;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
	border-color: #b3d7ff;
}

.custom-checkbox:not(:disabled):active+label::before {
	background-color: #b3d7ff;
	border-color: #b3d7ff;
}

.custom-checkbox:focus+label::before {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.53);
}

.custom-checkbox:focus:not(:checked)+label::before {
	border-color: #80bdff;
}

.custom-checkbox:disabled+label::before {
	background-color: #e9ecef;
}

label.checkBoxError::before {
	border-color: red;
	box-shadow: 0 0 5px 0.2rem rgba(210, 112, 112, 0.9);
}

@media (min-width: 100px) and (max-width: 767px) {
	form {
		width: 70%;
	}

	.registerButton {
		padding: 15px 5px;
	}
}

@media (max-width: 408px) {
	.messageShow {
		height: auto;
	}
}
</style>