<template>
	<div id="back">

		<div class="dropdown" :class="{ upwardDirection: isUpwardDirection, 'align-items-center': isTeamPage }">
      <button v-if="isTeamPage"  @click="toogle()" class="button team">{{$t("drop_down_menu_talent.all_role")}}</button>
			<button v-else  @click="toogle()" class="button">{{$t("drop_down_menu_talent.another_role")}}</button>

			<div id="myDropdown" class="dropdown-content">
        		<transition-group name="fade">
				<div :key="n" v-for="(type,n) in this.typeName" class="menu">

						<div class=" menu__item d-flex justify-content-between " @click="$router.push('/types/' + type)">
							{{ this.displayTypeName(type) }}
							<div :style="{ backgroundColor: this.colorOfType(type) }" class="round"></div>
						</div>
				</div>
					</transition-group>

			</div>
		</div>

		<transition name="fade">
			<div class="translucent-background" :class="{rounded : isTeamPage}" v-show="isShowBackground" @click="toogle()"></div>
		</transition>
	</div>
</template>

<script>
import typesUtils from '@/mixins/typesUtils';

export default {
	mixins: [typesUtils],
	name: "DropDownMenuTalent",
	props: {
		isUpwardDirection: {
			type: Boolean,
			default: false
		},
    isTeamPage:{
      type: Boolean,
      default: false
    }
	},
	data() {
		return {
			typeName: ["creator", "driver", "teambuilder", "networker", "realizator", "checkman", "analyst", "improver"],
			isShowBackground: false,
		}
	},
	methods: {
		toogle() {
			this.isShowBackground = !this.isShowBackground;
			document.getElementById("myDropdown").classList.toggle("show");
		}
	}
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}


#back {
	padding-left: 0 !important;
}

button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 500;
	font-size: 14px;
	color: #070C1F;
	padding: 5px 11px;
	border: none;
	background: #FFFFFF;
	border-radius: 11px;
	transition: 0.2s all linear;
  &.team{
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    background: #F6F6F6;
    border-radius: 15px;

    padding: 7px 55px;
  }
	&:hover {
		transform: scale(98%);
	}
}

.dropdown {
	position: relative;
	display: flex;
	flex-direction: column;
	z-index: 99;
	align-items: end;
}

.dropdown-content {
	display: none;
	position: absolute;
	top: 30px;
	background-color: rgba(0, 0, 0, 0);
	min-width: 160px;
	z-index: 1;

	font-weight: 700;
	font-size: 14px;
	line-height: 100%;
}

.upwardDirection {
	justify-content: end;

	.dropdown-content {
		top: -362px;
	}
}

.show {
	display: block;
}

.menu {

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #fff;
		margin: 10px 0;
		padding: 10px 20px;
		border-radius: 12px;
		cursor: pointer;

		&:hover {
			transform: scale(98%);
		}
	}
}

.round {
	width: 14px;
	height: 14px;
	border-radius: 50%;
}

.translucent-background {
	position: fixed;
	overflow-y: auto;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	height: 100%;
	width: 100%;
	z-index: 5;
}
</style>
