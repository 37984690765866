

export default {
    //Color and actual names of type;
    methods: {
        displayTypeName(systemTypeName) {
            var actualName;
            switch (systemTypeName) {
                case 'improver':
                    actualName = this.$t("type_names.improver");
                    break;
                case 'creator':
                    actualName = this.$t("type_names.creator");
                    break;
                case 'driver':
                    actualName = this.$t("type_names.driver");
                    break;
                case 'teambuilder':
                    actualName = this.$t("type_names.teambuilder");
                    break;
                case 'networker':
                    actualName = this.$t("type_names.networker");
                    break;
                case 'realizator':
                    actualName = this.$t("type_names.realizator");
                    break;
                case 'checkman':
                    actualName = this.$t("type_names.checkman");
                    break;
                case 'analyst':
                    actualName = this.$t("type_names.analyst");
                    break;
                default : actualName = this.$t("type_names.no_information")
            }
            return actualName
        },
        colorOfType(systemTypeName){
            var color;
            switch (systemTypeName) {
                case 'improver':
                    color = '#C229A1';
                    break;
                case 'creator':
                    color = '#D12937';
                    break;
                case 'driver':
                    color = '#FF8D00';
                    break;
                case 'teambuilder':
                    color = '#FFE600';
                    break;
                case 'networker':
                    color = '#2AB573';
                    break;
                case 'realizator':
                    color = '#41D1D5';
                    break;
                case 'checkman':
                    color = '#316CA9';
                    break;
                case 'analyst':
                    color = '#734197';
                    break;
            }
            return color
        }
    },


}