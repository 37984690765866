<template>
  <h3>{{$t('setting.personal_info')}}</h3>
  <UserProfileInput v-model="firstName" :errors="v$.firstName.$errors" :label="$t('setting.first_name')"
                    :type="'text'"></UserProfileInput>
  <UserProfileInput v-model="lastName" :errors="v$.lastName.$errors" :label="$t('setting.last_name')"
                    :type="'text'"></UserProfileInput>
  <UserProfileInput v-model="position" :errors="v$.position.$errors" :label="$t('setting.position')"
                    :type="'text'"></UserProfileInput>
<!--  <FormItem :isError="v$.phoneUser.$errors.length != 0" :label="$t('popUp_registration.contact_number')">-->
  <div  class="form__item">
    <label class="form__label" :class="{red: v$.phoneUser.$errors.length !== 0}">{{$t('setting.phone')}}</label>
    <input
        v-imask="mask"
        :value="phoneUser"
        class="form__input"
        placeholder="+0 (000) 000-00-00"
        type="Text"
        @accept="phoneAccept" @complete="phoneComplete" @keydown.space.prevent
    />

    <div class="error">
      <p class="mx-1">{{ v$.phoneUser.$errors.$message }}</p>
    </div>
  </div>
<!--  </FormItem>-->

  <UserProfileInput v-model="email" :errors="[]" :label="'E-mail'" :readonly="true" :type="'email'"></UserProfileInput>
  <div class="d-flex align-items-center mt-3">
    <input v-model="hideTalent" class="form-check-input" name="hideTalent" type="checkbox">
    <p class="mb-0 mx-2 hide-talent">{{$t('setting.hide_test_result')}}</p>
  </div>
  <button :disabled="v$.$errors.length !== 0" class="form__btn" @click="update">{{$t('setting.save')}}</button>
</template>

<script>
import UserProfileInput from "@/components/User/UserProfileInput.vue";
import useVuelidate from "@vuelidate/core";
import {IMaskDirective} from "vue-imask";
import {helpers, maxLength, required} from "@vuelidate/validators";
import FormItem from "@/components/Form/FormItem.vue";

const mustBePhone = (value) => new RegExp("^((8|\\+7|\\+1|\\+3)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$").test(value);

export default {
  name: "UserFormWrapper",
  components: {FormItem, UserProfileInput},
  emits: ["update"],
  props: {
    firstName: {
      type: String,
      required: true
    },
    lastName: {
      type: String,
      required: true
    },
    position: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    },
    hideTalent: {
      type: Boolean,
      required: true
    }
  },

  mounted() {
    this.phoneUser = this.$props.phone
  },
  setup() {
    return {v$: useVuelidate()}
  },
  directives: {
    imask: IMaskDirective
  },
  data() {
    return {
      mask: {
        mask: '+{0}(000)000-00-00',
        lazy: false,  // make placeholder always visible
      },
      phoneUser: "",
    }
  },
  methods: {
    async update() {
      await this.v$.$validate()
      if (this.v$.$invalid) {
        return;
      }

      this.$emit("update", {
        firstName: this.firstName,
        lastName: this.lastName,
        position: this.position,
        phone: this.phoneUser,
        hideTalent: this.hideTalent
      });

    },
    phoneComplete(e) {
      const maskRef = e.detail;
      this.phoneUser = maskRef.value
    },
    phoneAccept(e) {
      // const maskRef = e.detail;
      // this.phone = maskRef.value;
      this.phoneUser = e.detail.value

    }
  },
  validations() {
    return {
      firstName: {
        required: helpers.withMessage(this.$t('setting.name_required'), required),
        maxLength: helpers.withMessage(this.$t('setting.name_less_30_char'), maxLength(30))
      },
      lastName: {
        required: helpers.withMessage(this.$t('setting.lastname_required'), required),
        maxLength: helpers.withMessage(this.$t('setting.lastname_less_30_char'), maxLength(30))
      },
      position: {
        required: helpers.withMessage(this.$t('setting.position_required'), required),
        maxLength: helpers.withMessage(this.$t('setting.position_less_30'), maxLength(30))
      },
      phoneUser: { mustBePhone,
        required: helpers.withMessage('номер телефона обязателен', required)
      }
    }
  },
  watch: {
    firstName(val) {
      this.v$.firstName.$touch();
    },
    lastName(val) {
      this.v$.lastName.$touch();
    },
    position(val) {
      this.v$.position.$touch();
    },
    phone: function (newPhoneValue) {
      this.phoneUser = newPhoneValue;
    }

  }
}
</script>

<style lang="scss" scoped>
.hide-talent {
  font-size: 14px;
  margin-top: 2px;
}

h3 {
  font-size: 22px;
  margin: 0;
  padding: 0;
}

.form-check-input:checked {
  background-color: #0A0F3F;
  border-color: #0A0F3F;;
}

.form {
  &__btn {
    margin-top: 10px;
    float: right;
    border: none;
    background: #E6E6E6;
    border-radius: 8.84647px;
    padding: 7px 30px;
    font-weight: 500;
    font-size: 10.5802px;

    &:hover {
      transform: scale(1.02);
    }

    &:active {
      transform: scale(1.05);
    }
  }

}

button[disabled] {
  &:hover {
    transform: none
  }
}


.form {

  &__item {
    margin-top: 12px;

  }

  &__label {
    font-size: 12px;
    opacity: 0.5;
  }

  &__input {
    border: none;
    width: 100%;
    background: #E6E6E6;
    border-radius: 9.47142px;
    font-size: 11.9797px;
    color: #000000;
    padding: 5px 12px;

    &:focus {
      outline-color: #090D39;
    }
  }
}

.red{
  color:red;
  opacity: 1;
}
.error {
  position: absolute;
  color: red;
  font-size: 12px;
  display: flex;
}

</style>
