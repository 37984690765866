<template>
  <div id="main">
    <loader v-show="isLoading"></loader>
    <div v-show="!isLoading" style="width: 100%;">

      <section id="title">
        <TitleBlock></TitleBlock>
      </section>


        <WeHelpBlock></WeHelpBlock>

      <FormDemo :id-title="'form_one'"></FormDemo>

      <LandingKPI></LandingKPI>

      <LandingTypeProfile></LandingTypeProfile>

      <LandingUsefulness></LandingUsefulness>

      <FormDemo :id-title="'form_second'"></FormDemo>

      <LandingTeamWisdom></LandingTeamWisdom>

      <LandingPlans></LandingPlans>

      <LandingReviews></LandingReviews>

      <LandingStart></LandingStart>

      <LandingFooter></LandingFooter>
    </div>

  </div>

  <v-popup-demo :is-show-pop-up="isShowPopUp" @notShow="showPopup()" :parent-block="'Интересующий блок: Появляющийся через минуту попап'"></v-popup-demo>
</template>

<script>
import TitleBlock from "@/components/Landings/LandingProfileGenius/LandingTitleBlock.vue";
import WeHelpBlock from "@/components/Landings/LandingProfileGenius/LandingWeHelp.vue";
import FormDemo from "@/components/Landings/FormDemo.vue";
import LandingTypeProfile from "@/components/Landings/LandingProfileGenius/LandingTypeProfile.vue";
import LandingKPI from "@/components/Landings/LandingProfileGenius/LandingKPI.vue";
import LandingUsefulness from "@/components/Landings/LandingProfileGenius/LandingUsefulness.vue";
import LandingTeamWisdom from "@/components/Landings/LandingProfileGenius/LandingTeamWisdom.vue";
import LandingPlans from "@/components/Landings/LandingPlans.vue";
import LandingReviews from "@/components/Landings/LandingReviews.vue";
import LandingStart from "@/components/Landings/LandingStart.vue";
import LandingFooter from "@/components/Landings/LandingFooter.vue";
import PopupDemo from "@/components/Landings/component/PopupDemo.vue";
import Loader from "@/components/UI/Loader.vue";
import {mapGetters} from "vuex";

export default {
  metaInfo: {
    title: 'Управляйте человеческими ресурсами, зная типажи своих сотрудников',
    meta: [
      {
        name: 'description',
        content: '«Профиль Гения» покажет типаж каждого участника команды, свойственную ему деятельность, его сильные и слабые стороны. Руководителю на основе результатов тестирования будет проще грамотно распределять задачи. Это уменьшит риск выгорания сотрудников и увеличит общую эффективность работы команды.'
      }
    ]
  },
  components: {
    Loader,
    TitleBlock,
    WeHelpBlock,
    FormDemo,
    LandingTypeProfile,
    LandingKPI,
    LandingUsefulness,
    LandingTeamWisdom,
    LandingPlans,
    LandingReviews,
    LandingStart,
    LandingFooter,
    "vPopupDemo":PopupDemo,
  },
  data() {
    return {
      isShowPopUp: false,
      isLoading: true,
    }
  },
  methods: {
    showPopup() {
      this.isShowPopUp = !this.isShowPopUp;
    },
    loading() {
      this.isLoading = !this.isLoading;
    }

  },
  mounted() {
    setTimeout(() => {
      this.showPopup();
    }, 60000);
    setTimeout(() => {
      this.loading();
    }, 200);
  },
  computed: {
  },
}

</script>

<style scoped lang="scss">

#main {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #0B0215;
  background-size: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.container {
  max-width: 1200px;
}

.loader{
  opacity: 0%;
}

.landing {
  transition: all .3s linear;
  opacity: 1;
}


</style>