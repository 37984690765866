<template>
<UserBlock :is-green="isGreen" :is-red="isRed" :without-padding="withoutPadding">
  <h4 class="title" :class="{'d-none' : !title}">{{title}}</h4>
  <div class="text" v-html="text"></div>
</UserBlock>
</template>

<script>
import UserBlock from "@/components/User/UserBlock";

export default {
  name: "UserTextBlock",
  components: {UserBlock},
  props:{
    isGreen:{
      type: Boolean,
      require: false
    },
    withoutPadding:{
      type: Boolean,
      require: false
    },
    isRed:{
      type: Boolean,
      require: false
    },
    title:{
      type: String,
      require: false
    },
    text:{
      type: String,
      require: true
    }
  }

}
</script>

<style scoped>
h4{
  margin: 0;
  padding: 0;
}
.title{

  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}
.text{
  font-size: 14px;

}
</style>
