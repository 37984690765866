<template>
<div  id="main" class="">
		<div class="wrapper">
			<div class="container register">
        <div class="col d-flex justify-content-center">
          <p class="registerTitle h3" v-html="$t('popUp_registration.title')">

          </p>
        </div>
        <div class="info__form w-100">
          <CompanyInfoForm :promocode-info="promocodeError" :is-register-user="true" @submit="send"/>
			</div>
		</div>
	</div>
</div>

<div v-if="sended" class="popupShow container-fluid popupNotification d-flex justify-content-center" @click="navigateToHomePage">
  <transition name="slide-fade">
    <div class="popup_body align-self-center">
      <div class="popup_contentNotification popup_contentShow col-sm-">
        <div class="row">
          <div class="col d-flex justify-content-center">
            <div class="popup_title h5" >Спасибо! Наш менеджер свяжется с Вами в ближайшее время.</div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import Loader from "@/components/UI/Loader.vue";
import FormItem from "@/components/Form/FormItem.vue";
import CompanyInfoForm from "@/components/Company/CompanyInfoForm.vue";
import api from "@/api";


export default {
  name: "NewLogin",
  components: {CompanyInfoForm, FormItem, Loader},
  data(){
    return{
      showPassword: false,
      email: "",
      password: "",
      action: this.$route.query.action,
      isLocaleEn: false,
      sended: false,

      promocodeError: {
        isError: false,
        PromocodeErrorMsg: ''
      }
    }
  },
  created() {
    this.getResultGenius();
  },
  computed: {
    ...mapGetters(
      {
        isLoginError: 'AuthModule/isLoginError',
        isLoading: 'isAnyLoading'
      }
    ),
  },
   mounted() {
    this.resetLoginError();

  },
  methods: {
    ...mapMutations({
      resetLoginError: 'AuthModule/resetLoginError'
    }),
    ...mapActions({
      onLogin: 'AuthModule/onLogin'
    }),

    send(data) {
        api.sendCompanyRegistrationWithoutRegister(data.nameUser, data.email, data.nameCompany, data.phone, data.fieldActivity, data.numberEmployees, data.promocode)
            .then(() => {
              this.sended = true;
            })
            .catch(error => {this.promocodeError.PromocodeErrorMsg = error.response.data.message; this.promocodeError.isError = true})
    },
    navigateToHomePage() {
      this.sended = false;
      this.$router.push('/genius-profile');
    },

    show_hide_password(){
      this.showPassword = !this.showPassword
    },
    login(){
      console.log("onLogin");
      this.onLogin({
        email: this.email,
        password: this.password,
        redirect: true
      })
    },
    getResultGenius() {
      const currentLocale = this.$i18n.locale;

      if (currentLocale == 'en') {
        this.isLocaleEn = true;
      }

    }
  }
}
</script>

<style scoped>
#main{
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  background-image: url(@/assets/dark_back.png);
  width: 100%;
  height: 100%;
  color: white;
}

.hideText {
  display: none;
}

.register{
	font-family: Ubuntu;
	font-weight: 400;
	margin-top: 100px;
	margin-bottom: 50px;
}

.registerTitle {
  text-align: center;
  margin-bottom: 30px;
}

.info__form {
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
}

form{
	width: 40%;
}
.error-label{
  text-align: center;
  color:red;
}
.registerForm{
	width: 100%;
	color: white;
	padding: 15px;
	background: transparent;
	border: 1px #6B6B6B solid;
  border-radius: 20px;
}
.registerForm:focus{
	border: 1px #202FD4 solid;
	outline: none;
	transition: 0.2s;
}
.registerButton{
	width: 100%;
	display: block;
	outline: none;
	padding: 15px 20px;
	background: #2835C1;
	border: none;
	border-radius: 15px;
	text-decoration: none;
	color: #F6F6F5;
	font-size: 20px;
	font-family: Ubuntu;
	font-weight: 400;
	transition: 0.2s;
}
.registerButton[disabled]{
  background-color: grey;
}
.registerButton[disabled]:hover{
  background-color: grey;
  box-shadow: none;
}
.registerButton:hover{
	box-shadow: 0px 0px 20px 0.5px #0015FF;
	background: #0017EF;
	transition: 0.2s;
}
.registerButton:active{
	background: #0014CC;
	transition: 0.2s;
}
label{
	margin-left: 15px;
	margin-bottom: 10px;
	font-weight: 700;
}
.password{
	position: relative;
}
.password-control{
	position: absolute;
	top: 53px;
	right: 30px;
	display: inline-block;
	width: 20px;
	height: 20px;
	background: url(@/assets/password/view_icon.png) 0 0 no-repeat;
}
.password-control.view{
	background: url(@/assets/password/no_view_icon.png) 0 0 no-repeat;
}

.message {
	transition: 0.2s;
	font-weight: 400;
	height: 0px;
	margin-left: 15px;
  margin-top: 15px;
  overflow: hidden;
}
.messageShow {
	transition: 0.2s;
	font-weight: 500;
	height: 70px;
	margin-left: 15px;
  margin-top: 15px;
}
.message p {
	margin-bottom: 0px;
  font-size: 14px;
}
.valid {
  display: none;
}
.invalid {
	display: block;
  color: red;
}

.toLogin>a{
	color: #2835C1;
	text-decoration: none;
}
.toLogin>a:hover{
	text-decoration: underline;
}

.popupShow {
  opacity: 1;
  visibility: visible;
  transform: perspective(600px) translate(0px, 0px) rotateX(0deg);
}

.popupNotification {
  position: fixed;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 1100;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.5s;
}
@media (min-width: 100px) and (max-width: 767px){
  form{
		width: 70%;
	}
	.registerButton{
		padding: 15px 5px;
	}
}
@media (max-width: 408px){
  .messageShow {
		height: auto;
	}
}
</style>