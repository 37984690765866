<template>
  <div class="modal__background">
    <div class="modal">
      <img src="@/assets/closeModalWhite.svg" @click="close()" class="modal__close">
      <div class="modal__title bg-white w-100">
        <h3 class="p-0 m-0">Настройки видимости</h3>
      </div>

        <div class="modal__form w-100 bg-white">

          <div style="max-width: 600px; margin-right: 30px;">
            <div class="modal__item">
              <label class="d-flex justify-content-start">
                <input style="margin-right: 10px;" type="checkbox" class="form-check-input" v-model="isTeamSealed" @change="isChange = true">
                <p class="m-0">скрыть эту команду от других сотрудников, кроме...</p>
              </label>
            </div>
            <div class="modal__item">
              <label class="d-flex justify-content-start">
                <input style="margin-right: 10px;" type="checkbox" class="form-check-input" v-model="isResSealed" @change="isChange = true">
                <p class="m-0">скрыть результаты участников этой команды от участников этой команды</p>
              </label>
            </div>
            <div class="modal__item">
              <label class="d-flex justify-content-start">
                <input style="margin-right: 10px;" type="checkbox" class="form-check-input" v-model="isOthersSealed" @change="isChange = true">
                <p class="m-0">скрыть результаты других команд от участников этой команды</p>
              </label>
            </div>


              <div v-if="isLoading" style="padding-top: 20px;">
              <loader ></loader>
              </div>
            <transition  v-else name="fade">
              <button  class="button" :class="{disable: isSending || !isChange}" @click="updateSetting" :disable="isSending">Ок</button>
            </transition>
          </div>
          <div>
            <ListUsers :team-id="teamId" :users="usersAllowed" @sending="toogleSending"></ListUsers>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import FormItem from "@/components/Form/FormItem.vue";
import Modal from "@/components/UI/Modal/ModalInfo.vue";
import ListUsers from "@/components/Team/seal-company-team/ListUsers.vue";
import Loader from '@/components/UI/Loader.vue';

export default {
	components: { Modal, FormItem, ListUsers, Loader },
	props: {
		othersSealed: {
			type: Boolean,
			required: true
		},
		resSealed: {
			type: Boolean,
			required: true
		},
		teamSealed: {
			type: Boolean,
			required: true
		},

		teamId: {
			type: Number,
			required: true
		},
		usersAllowed: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			isOthersSealed: this.othersSealed,
			isResSealed: this.resSealed,
			isTeamSealed: this.teamSealed,
			isSending: false,
      isChange: false,
      isLoading: false
		}
	},
	methods: {
		toogleSending() {
			this.isSending = !this.isSending
		},
    async updateSetting() {
      this.isLoading = true;
      try {


        // if (this.isResSealed !== this.resSealed) {
        //   this.isSending = true;
          await api.setResSealed(this.teamId, this.isResSealed);
        //   this.isSending = false;
        // }

        // if (this.isTeamSealed !== this.teamSealed) {
        //   this.isSending = true;
          await api.setTeamSealed(this.teamId, this.isTeamSealed);
        //   this.isSending = false;
        // }

        // if (this.isOthersSealed !== this.othersSealed) {
        //   this.isSending = true;
          await api.setOthersSealed(this.teamId, this.isOthersSealed);
        //   this.isSending = false;
        // }

        // window.location.reload();
      } catch (error) {
        console.error('Ошибка при обновлении настроек:', error);
      } finally {
        this.isLoading = false;
        this.isChange = false;
      }
    },
    close() {
      this.$emit('close');
    }
	},
	watch: {

	},
}

</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.form-check-input:checked {
  background-color: #0A0F3F;
  border-color: #0A0F3F;;
}

.modal {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: visible;
  max-width: 740px;

  &__close {
    align-self: flex-end;
    margin-bottom: 5px;
    cursor: pointer;
  }

  &__background {
    overflow-y: auto;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
  }

	&__title {
		padding: 20px 20px;
    margin: 0 auto;
		text-align: center;
		border-radius: 15px;
		margin-bottom: 15px;
    max-width: 700px;

		h3 {
			font-weight: 700;
			font-size: 18px;
			line-height: 21px;
		}
	}

	&__form {
		font-weight: 400;
		font-size: 10px;
		line-height: 15px;

    max-width: 700px;
		border-radius: 20px;
		margin-bottom: 10px;
		padding: 20px;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}

	&__item {
		font-size: 16px;

		label {
			margin-top: 15px;

			p {
				max-width: 400px;
			}
		}
	}
}



.menu {
	p {
		margin-bottom: 5px;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #fff;
		margin: 10px 0;
		border-radius: 12px;
		cursor: pointer;

		&:hover {
			transform: scale(98%);
		}
	}
}

.greyColor {
	color: #969696;
}


button {
  margin-top: 20px;
  background-color: #070C1F;
  color: #FFFFFF;
  text-align: center;
  border-radius: 8px;
  border: none;
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  font-size: 14px;
  transition: all 0.3s ease;

	&:hover {
		transform: scale(98%);
	}
}


.disable {
	color: #969696;
	background-color: #d2d2d2;
  border: none;
}

@media (min-width: 768px) {
	.modal__form {
		flex-direction: row;
		padding: 40px;
	}
}
</style>