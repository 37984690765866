<template>
  <div class="container blockKPI">
    <div class="blockKPI__title"><h2>«Профиль Гения» для вас,<br class="d-sm-none"> если вы:</h2></div>
    <div class="blockKPI__content justify-content-between">
      <img src="../../../assets/landing-profile-genius/Usefulness.png" alt="Usefulness">
      <List :list-info="listInfo" class="blockKPI__list" :path="'multicolored-checkmark'"></List>
    </div>

  </div>
</template>

<script>
import List from "@/components/Landings/component/List.vue";
export default {
  components: {
    List,
  },
  data() {
    return {
      listInfo: ['Ищете нового сотрудника в команду',
        'Проводите кадровые изменения',
        'Участвуете в акселераторе или хакатоне',
        'Запускаете стартап',
        'Хотите увеличить эффективность команды'
      ]
    }
  }
}

</script>

<style scoped lang="scss">
.blockKPI {
  margin-top: 120px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;

  display: flex;
  flex-direction: column;
  align-items: center;


  &__title {
    margin-bottom: 40px;
    max-width: 600px;
    color: #F6F6F5;
  }

  &__list {

    align-self: center;
    justify-content: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

img {
  max-width: 200px;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .blockKPI {
    margin-top: 200px;
  }

}

@media (min-width: 992px) {
  .blockKPI {
    &__list {
        margin-left: 100px;
    }

    &__content {
      flex-direction: row;
    }
  }

  img {
    max-width: 240px;
  }
}

@media (min-width: 1200px) {
  .blockKPI {
    &__list {
      margin-left: 180px;
    }
  }
}
</style>