<link rel="stylesheet" href="../../../../genius-landing/css/style.css">
<template>
	<div v-if="isShowForm" style="z-index: 997;">
		<div class="modal-mask" @click="this.$emit('notShow', this.isShowForm)">
			<template v-if="!sended">
				<div class="registration" @click.stop>
					<div class="header" @click.stop="this.$emit('notShow', this.isShowForm)"><img src="@/assets/closeModal.svg">
					</div>
					<div class="registration__title">
						<h2 class="gradient_font_color">{{ $t("popUp_add_registration.title") }}</h2>
					</div>

					<div class="registration__content">
						<div class="">
							<div v-for="element of contentTariff">
								<span class="paragraph">
									<img alt="" src="@/assets/check-mark.svg">
									<p>{{ $t(`plans.${element}`)}}</p>
								</span>
							</div>
							<div class="d-flex align-items-center mt-4 mb-5 mb-md-0">
								<div class="m-0"><span style="font-size: 16px; font-weight: 500; margin-left: 10px">{{ $t("popUp_add_registration.price_employee") }} </span>
									<br class="d-xl-none">
									<span style="font-size: 25px; font-weight: 700; margin: 0 10px 0;" class="gradient_font_color">{{$t("popUp_add_registration.price_one")}}
										{{ $t("popUp_add_registration.currency") }}</span>
									<span style="font-size: 16px; font-weight: 400;"><s>{{ $t("popUp_add_registration.price_without_discount") }} {{ $t("popUp_add_registration.currency") }}</s> </span>
								</div>
							</div>
						</div>
						<div class="d-flex flex-column justify-content-between" style="align-self: stretch">
              <div>
                <div class="cost">
                  <div class="d-flex align-items-center" style="text-align: left">
                    <p class="mb-0">{{ $t("popUp_add_registration.number_registrations") }}</p>

                    <input @keydown.space.prevent id="numberEmployees" v-model.number="numberEmployees" class="modal__input"
                      :class="{ error: isFieldFilledMsg }" min="1" max="2999" type="number" placeholder="1">
                  </div>
                    <FormError v-show="isFieldFilledMsg" :msg="$t('popUp_add_registration.field_filled_incorrectly')">
                    </FormError>


                </div>
                <div class="mt-2 d-flex justify-content-between">
                  <FormItem :label="''" >
                    <input id="promoCode" v-model.trim="promoCode" class="promoCode__input " type="text" @input="isPromoError = false" placeholder="Промокод"
                           @keydown.space.prevent :class="{'isErrorPromoInput': isPromoError}">
                    <img v-show="isPromoError" alt="" src="@/assets/alert.svg" class="errorPromo">

                  </FormItem>
                  <button class="promoCode__button mt-2" @click="checkPromo" :disabled="isPromoError" :class="{'active': isPromoError}">Применить</button>
                </div>
              </div>
              <div>
                <div class="mt-4">
                  <span style="font-size: 18px; font-weight: 700;">{{ $t("popUp_add_registration.total") }}</span>
                  <span style="font-size: 20px; font-weight: 500; margin: 0 10px;">{{ this.totalPrice }}
                    {{ $t("popUp_add_registration.currency") }}</span>
                  <span style="font-size: 14px; font-weight: 400; font-color: #959595;"><s>{{
                    this.numberEmployees * priceWithoutDiscount
                  }} {{ $t("popUp_add_registration.currency") }}</s></span>
                </div>

                <div class="mt-2 text-center text-md-start">
                  <button class="modal__button" @click="savedData" :class="{'active': isPromoError}">{{$t("popUp_add_registration.send")}}</button>
                </div>
              </div>


            </div>
					</div>

          <div class="discount" v-if="!isLocaleEn">
            <div class="discount__title">
              Особая выгода!
            </div>
            <div class="discount__price">
              <span class="discount__text">10 сотрудников за </span>
              <span class="discount__price_with_discount">10 000 ₽ </span>
              <br class="d-sm-none">
              /
              <span class="discount__price_without_discount"><del>15 000 ₽</del></span>
            </div>
          </div>
					<div class="premium-rate">
						<div class="premium-rate__title" @click="toogle()">
							<div>
								<h3>{{ $t("plans.individual_title") }}</h3>
								<p>{{ $t("plans.individual_price") }}</p>
							</div>
							<div><img :class="{ arrow: isShowPremiumRate }" id="rotateImage" src="@/assets/arrow.svg" alt="arrow"></div>
						</div>

						<transition name="fade">
							<div class="registration__content" v-if="isShowPremiumRate">
								<div class="">
									<div v-for="element of contentPremiumRate">
										<span class="paragraph">
											<img alt="" src="@/assets/check-mark-white.svg">
											<p>{{ $t(`plans.${element}`)}}</p>
										</span>
									</div>
								</div>
								<div>
									<h4 style="font-size: 18px; font-weight: 700; line-height: 19px; ">
                    {{ $t("popUp_add_registration.leave_application_tariff") }}
									</h4>
									<FormItem :isError="nameError" :label="$t('popUp_registration.contact_person')">
										<input id="lastName" v-model.trim="name" autofocus class="premiumRate__input" name="surname"
											:placeholder="$t('popUp_registration.contact_person')" type="text">
									</FormItem>
									<FormItem :isError="phoneError" :label="$t('popUp_registration.contact_number')">
										<input v-imask="mask" v-model.trim="phone" class="premiumRate__input"
											:placeholder="$t('popUp_registration.mask_number')" type="text" @accept="phoneAccept"
											@complete="phoneComplete" @keydown.space.prevent>
									</FormItem>
									<button class="premiumRate__button" @click="changeTariffPremium">{{ $t("popUp_add_registration.btn_send") }}</button>
								</div>
							</div>
						</transition>

					</div>

				</div>

			</template>
			<template v-else>
				<div>
					<div class="header"><img src="@/assets/closeModalWhite.svg"
							@click.stop="this.$emit('notShow', this.isShowForm)">
					</div>
					<div class="modal__title bg-white">
						<h3 class="p-0 m-0" v-html="$t('request_consult.info_after_filling')"></h3>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import Modal from '@/components/UI/Modal/Modal.vue';
import FormItem from '@/components/Form/FormItem.vue';
import FormError from '@/components/Form/FormError.vue';
import api from "@/api";
import { IMaskDirective } from "vue-imask";
export default {

	components: {
		Modal, FormItem, FormError
	},
	watch: {
		numberEmployees(val) {
			if (val <= 0) {
				this.numberEmployees = '';
			} else if (val >= 2000) {
				this.numberEmployees = 1999;
			}
		}
	},

	data() {
		return {
			contentTariff: ['standard_number_teams', 'standard_testing', 'standard_automatic_tips', 'standard_knowledge_base', 'standard_add_testing', 'standard_2_consultations'],
			contentPremiumRate: ['individual_number_teams', 'individual_testing', 'individual_automatic_tips', 'individual_knowledge_base', 'individual_add_testing', 'individual_personal_consultations', 'individual_executive_training', 'individual_access_candidate_database', 'individual_company_introduction'],
			numberEmployees: 1,
			isFieldFilledMsg: false,
			sended: false,
			price: Number(this.$t('popUp_add_registration.price_one')),
			priceWithoutDiscount: Number(this.$t('popUp_add_registration.price_without_discount')),
			isShowPremiumRate: false,
			name: '',
			phone: '',
			phoneError: false,
			nameError: false,
      isLocaleEn: false,
			mask: {
				mask: '+0(000)000-00-00',
				lazy: false,  // make placeholder always visible
			},
      promoCode:'',
      isPromoError: false,
      promocodes: ['FREEZE500'],
		}
	},
	directives: {
		imask: IMaskDirective
	},
	computed: {
		isPhoneError() {
			return this.phoneError;
		},
		isLastNameError() {
			return this.nameError;
		},
    totalPrice() {
      let remain = this.numberEmployees % 10;
      let wholeInt = this.numberEmployees - remain;


      if (this.numberEmployees >= 10) {
        if ( remain === 0) {
          return 1000 * this.numberEmployees;
        } else {
          return (wholeInt * 1000) + (remain * this.price);
        }
      } else {
        return this.price * this.numberEmployees;
      }


    }
	},
	props: {
		isShowForm: {
			type: Boolean,
			default: false
		},
		id: {
			type: Number,
			required: true
		}
	},
	methods: {
    checkPromo() {
      let isPromo = this.promocodes.includes(this.promoCode);
      if (this.promoCode !== '' && !isPromo) {
        this.isPromoError = true;
        return
      } else if (isPromo) {
        api.checkPromoCodeToCompanyPayment(this.id, this.promoCode)
            .then(r =>
            {
              this.price = 500;
              this.priceWithoutDiscount = 1500;
            })
            .catch(e => {
              this.isPromoError = true;
            })
      }
    },
		toogle() {
			this.isShowPremiumRate = !this.isShowPremiumRate;
		},
		checkPhone() {
			this.phoneError = (this.phone.length == 0);
		},
		checkName() {
			this.nameError = (this.name.length == 0);
		},
		savedData() {
      this.sendDataCompany();
		},
    sendDataCompany() {
      if ((Number(this.numberEmployees))) {
        this.isFieldFilledMsg = false;
        if (!this.isLocaleEn) {
          api.sendRequestAddUsersToCompanyPayment(this.id, this.numberEmployees, this.promoCode)
              .then(response => {
                let url = new URL(response.data)
                window.open(url, '_blank');
              })
              .then(this.$emit('notShow', this.isShowForm));
        } else {
          api.sendRequestAddUsersToCompany(this.id, this.numberEmployees).then(
              this.sended = true
          )
        }
      } else {
        this.isFieldFilledMsg = true;
      }
    },
		changeTariffPremium() {
			this.checkPhone();
			this.checkName();
			if (!this.nameError && !this.phoneError) {
				api.sendCompanychangeTariffPremium(this.name, this.phone, this.id).then(this.sended = true);
			}

		},

		phoneComplete(e) {
			const maskRef = e.detail;
			this.phone = maskRef.value
		},
		phoneAccept(e) {
			this.phone = e.detail.value
		},
    getLocalLanguage() {
      const currentLocale = this.$i18n.locale;
      //const currentLocale = 'en'
      if (currentLocale === 'en') {
        this.isLocaleEn = true;
      }
    },
	},
  created() {
    this.getLocalLanguage();
  },
}

</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

.active {
  background-color: #f0f0f0;
}

.header {
	display: flex;
	justify-content: end;
	position: relative;

	img {
		cursor: pointer;
		margin-bottom: 15px;
		//transform: translateX(40px);
	}
}

.modal-mask {
	overflow-y: auto;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	transition: opacity 0.3s ease;
}

.modal {
	&__input {
		width: 60px;
		height: 40px;
		margin-left: 10px;
		border-radius: 8px;
		border: 1px solid #6B6B6B;
		text-align: center;

    &:focus {
      border: 1px solid #000;
    }
	}

	&__button {
		background-color: #090D39;
		color: #fff;
		font-weight: 700;
		font-size: 16px;
		line-height: 21px;
		padding: 16px 30px;
		border-radius: 14px;
		border: none;
		width: 100%;
    transition: all 0.2s linear;

    &:hover {
      background: #060E64;
    }
	}

	&__title {
		padding: 10px 20px;
		margin: auto;
		text-align: center;
		border-radius: 15px;
		margin-bottom: 15px;

		h3 {
			font-weight: 700;
			font-size: 15px;
			line-height: 21px;
		}
	}
}

.discount {
  padding: 16px 20px;
  background-image: linear-gradient(97.19deg, #B927B5 7.13%, #3E77FF 95.26%);
  border-radius: 20px;
  margin-top: 30px;
  color: #fff;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  &__title{
    font-size: 27px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 10px;
  }

  &__price{
    margin-left: 10px;
    text-align: center;
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 19px;
  }

  &__price_with_discount {
    font-size: 22px;
    font-weight: 700;
    line-height: 31px;
  }

  &__price_without_discount {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.registration {
	position: absolute;
	top: 60px;
	z-index: 997;
	background-color: #EAEDF6;
	height: 100vh;
	width: 100%;
	padding: 20px;
	overflow-y: auto;

	

	&::-webkit-scrollbar {
		width: 12px;
	}

	&::-webkit-scrollbar-track {
		background: #EAEDF6;
	}

	&::-webkit-scrollbar-thumb {
		background: #EAEDF6;

		&:hover {
			background: #EAEDF6;
		}
	}

	&__title {
		h2 {
			margin: 0;
			font-size: 28px;
			font-weight: 700;
			line-height: 30px;
			letter-spacing: 0em;
			text-align: center;
		}
	}

	&__content {
		margin: 20px auto 0;
	}
}

.cost {
	display: flex;
  flex-direction: column;
	justify-content: start;
	flex-wrap: nowrap;

	p {
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		max-width: 100%;
		white-space: nowrap;
	}
}

.paragraph {

	p {
		text-align: left;
		margin-left: 20px;
		font-size: 14px;
		line-height: 19.6px;
		font-weight: 400;
	}

	img {
		float: left;
		margin-top: 3px;
	}
}

.gradient_font_color {
	background-color: #3E77FF;
	background-image: linear-gradient(97.19deg, #B927B5 7.13%, #3E77FF 95.26%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.error {
	color: #D12937;
	border-color: #D12937;
}

.premium-rate {
	border-radius: 20px;
	background-color: #090D39;
	color: #fff;
	padding: 30px 20px;
	margin-top: 40px;

	&__title {
		display: flex;
		justify-content: space-between;
		cursor: pointer;

		h3 {
			font-size: 28px;
			font-weight: 700;
			line-height: 30px;
			margin-bottom: 20px;
		}

		p {
			font-size: 16px;
			font-weight: 400;
			line-height: 17px;
			margin: 0 0;
		}
	}
}

#rotateImage {
	transition: transform 0.5s ease-in;
}

.arrow {
	transform: rotate(180deg);
}

.promoCode {
  &__input {
    width: 200px;
    padding: 15px;
    border: 1px #6B6B6B solid;
    border-radius: 8px;
    height: 40px;
    margin-top: 10px;

    &:focus {
      border: 1px solid #000;
    }
  }

  &__button {
    background-color: #fff;
    color: #090D39;
    padding: 9px 15px;
    margin-left: 10px;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    border: none;
    width: 100%;
    max-width: 200px;
    transition: all 0.2s linear;

    &:hover {
      transform: scale(1.02);
    }
  }
}

.errorPromo {
  height: 25px;
  position: absolute;
  left: 93%;
  top: 33%;
}

.isErrorPromoInput {
  border-color: red;
}

  .premiumRate {
    &__input {
      width: 100%;
      color: white;
      padding: 15px;
      background: transparent;
      border: 1px #6B6B6B solid;
      border-radius: 8px;
      height: 40px;
      margin-top: 10px;
    }

    &__button {
      width: 100%;
      background-color: #fff;
      padding: 16px 30px;
      border-radius: 14px;
      margin-top: 20px;
      transition: 0.5s;

      &:hover {
        background-color: #e6e8ff;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @media(min-width: 550px) {
    .promoCode {
      &__input {
        width: auto;
      }
    }
  }

  @media (min-width: 768px) {
    .registration {

      &__title {
        h2 {
          font-size: 36px;
          line-height: 42px;
        }
      }

      &__content {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin: 40px auto 0;
      }
    }

    .modal {
      &__button {
        width: auto;
      }
    }

    .discount {
      padding: 30px 50px;
      flex-direction: row;

      &__title {
        margin-bottom: 0;
      }
    }

  }

  @media (min-width: 992px) {
    .registration {
      width: 800px;
      padding: 30px;
    }

    .header {
      img {
        cursor: pointer;
        transform: translateX(15px);
        margin: 0;
      }
    }

    .premium-rate {
      padding: 30px;
    }

    .discount {
      justify-content: flex-start;
      &__price{
        margin-left: 70px;
      }
    }

  }

  @media (min-width: 1200px) {
    .registration {
      width: 1000px;
      padding: 30px 60px;
    }

    .header {
      img {
        cursor: pointer;
        transform: translateX(35px);
      }
    }

    .premium-rate {
      padding: 40px;
    }
  }

  @media (min-width: 1400px) {
    .registration {
      top: 80px;
    }
  }

</style>