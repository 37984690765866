<template>
  <div class="user__not-active__activate" >
    <div>
      <h4>Тестирование «Профиль гения» доступны только для пользователей в компании</h4>
      <h5>Обратитесь к вашему администратору<br>Или зарегистрируйте компанию</h5>

      <button @click="this.$router.push('/teams')">Зарегистрировать компанию</button>

      <span>
        <p>Вы можете пройти тестирования:</p>

        <span class="other_tests">
          <a @click="changeTest(2)" class="link">Уровни осознанности</a>,
          <a @click="changeTest(3)" class="link">Тест на компетенции</a>
        </span>
      </span>
    </div>

  </div>
</template>
<script>

import Loader from "@/components/UI/Loader.vue";

export default {
  name: 'InCompanyVerificationModal',
  components: {Loader},
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    changeTest(test) {
      this.$emit('changeTest', test)
    },
  }
}
</script>
<style lang='scss' scoped>

button {
  background-color: #090D39;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 7px;
  margin-top: 15px;
  transition: all 0.5s ease-in;

  &:hover {
    background-color: #060E64;
    transform: scale(101%);
  }
}

.user {
  &__not-active {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(234, 237, 246, 0.44);
    //TODO: Сейчас можно убрать через css. Исправить
    backdrop-filter: blur(5.5px);
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;

    &__activate {
      text-align: center;
      background: #FFFFFF;
      border-radius: 25px;
      margin-top: 25px;
      padding: 23px 30px;



      & h4, h5 {
        font-weight: normal;
        margin-bottom: 0;
        font-size: 15px;
      }

      & h5 {
        margin-top: 30px;
      }

      & p {
        margin-top: 27px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 14px;

        & .link {
          cursor: pointer;
          color: #2835C1;

          &:hover {
            color: #212c87;
          }
        }
      }
    }
  }

}

.other_tests {
  .link {
    cursor: pointer;
    color: #2835C1;
    margin-left: 7px;
    margin-right: 3px;
    text-decoration: none;
    transition: color 0.3s ease-in;

    &:hover {
      color: #121c62;
    }
  }
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .user {
    &__not-active {


      &__activate {
        text-align: center;
        background: #FFFFFF;
        border-radius: 25px;
        margin-top: 25px;
        padding: 43px 50px;

        & h4, h5 {
          font-weight: bold;
          margin-bottom: 0;
          font-size: 18px;
        }

        & h5 {
          margin-top: 30px;
          font-weight: normal;
        }

        & p {
          margin-top: 27px;
          margin-bottom: 0;
          font-size: 14px;
          line-height: 14px;

          & .link {
            cursor: pointer;
            color: #2835C1;

            &:hover {
              color: #212c87;
            }
          }
        }
      }
    }

  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
}

/*// X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}

/*// XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {


}
</style>