<template>
  <div class="company__promo">
    <div style="max-width: 300px">
      <p class="company__promo__title"> Приведи компанию <br class=" d-none d-lg-block">и получи 5 доступов бесплатно</p>
      <a class="company__promo__link" @click="showModal">Подробнее об акции</a>
    </div>
    <div class="align-self-end align-self-md-center"
         style="position: relative">

        <button id="promoCode" class="company__promo__button" @click="copyTextPromoCode()">{{ this.promoCode }}
        </button>

      <transition name="fade" mode="out-in">
      <p v-if="isPromoCopied.button" id="my-text" class="tooltipContent fade-out" @transitionend="hideMessage">Промокод скопирован</p>
      </transition>
    </div>
  </div>


  <Modal v-if="isShowInfo" :isTransparentBackground="true" @close="showModal">
    <div class="modal__title bg-white w-80">
      <h3 class="p-0 m-0">Получите 5 дополнительных доступов для своей компании бесплатно!</h3>
    </div>

    <div class="modal__form bg-white w-100">
      <div>
        <p>Как это сделать:</p>

        <ol>
          <li>Мы создали для вас персональный промокод <span class="promoCode">{{ this.promoCode }}</span>. Разошлите этот код своим партнерам или опубликуйте в социальных сетях.</li>
          <li>Компания, которая зарегистрируется с использованием этого промокода, получит 5 дополнительных бесплатных доступов на платформу Team Wisdom.</li>
          <li>Как только они будут использованы, вы также получите для своей компании 5 дополнительных доступов на платформу Team Wisdom бесплатно!</li>
        </ol>

        <p>Желаем вам эффективных решений!</p>
      </div>

      <div class="block_button d-flex justify-content-center">
        <button class="button__promo" @click="copyTextInvitation()" :disabled="this.isPromoCopied.modal" :class="{'disable': this.isPromoCopied.modal}">{{ (!this.isPromoCopied.modal)? 'Скопировать приглашение' : 'Приглашение скопировано'}}</button>
      </div>
    </div>



  </Modal>

</template>

<script>
import Modal from "@/components/UI/Modal/Modal.vue";
import Tooltip from "@/components/UI/Tooltip.vue";

export default {
  components: {
    Tooltip,
    Modal
  },
  props: {
    promoCode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isShowInfo: false,
      isPromoCopied: {
        button: false,
        modal: false
      },
    }
  },
  mounted() {

  },
  methods: {
    showModal() {
      this.isShowInfo = !this.isShowInfo;
    },
    async copyTextInvitation() {
      if (this.textPromoCode) {
        navigator.clipboard.writeText(this.textPromoCode)
            .catch(err => {
              console.log('Something went wrong', err);
            });
        this.isPromoCopied.modal = true;
      }
    },
    async copyTextPromoCode() {
      if (this.promoCode) {
        navigator.clipboard.writeText(this.promoCode.toUpperCase())
            .catch(err => {
              console.log('Something went wrong', err);
            });
        this.isPromoCopied.button = true;
      }
    },
    hideMessage() {
      this.isPromoCopied.button = false;
    }
   
  },
  computed: {
    textPromoCode() {
      let text = "Мы используем платформу Team Wisdom для эффективного решения задач по управлению персоналом. Хотите более рационально использовать способности ваших сотрудников и улучшить качество подбора персонала? Регистрируйтесь на платформе, проходите тестирование и читайте рекомендации для вашей команды!\n\n" +
           "Введите промокод " + this.promoCode.toUpperCase() + " при регистрации компании на платформе Team Wisdom (https://teamwisdom.ru/) и получите 5 дополнительных доступов для своей компании бесплатно!";

      return text;
    }
  }
}

</script>

<style lang="scss" scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}

.promoCode {
  text-transform: uppercase;
  font-weight: 600;
}

.tooltipContent {
  padding: 6px 12px;
  text-align: center;
  transition: all .6s ease-in-out;
  //width: 125px;
  font-size: 12px;
  position: absolute;
  top: 70px;
  left: 30px;
  z-index: 9999;
  background: #090D39;
  color: #FFFFFF;
  border-radius: 5px;
}

  .company__promo {
    background-image: linear-gradient(97.19deg, #B927B5 7.13%, #3E77FF 95.26%);
    color: #FFFFFF;
    border-radius: 20px;
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 20px;

    &__title {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0px;
      text-align: left;
      color: #FFFFFF;
      margin-bottom: 15px;
    }

    &__button {
      padding: 0px 40px;
      margin: 0;
      margin-left: 15px;
      margin-top: 15px;
      height: 60px;
      border-radius: 3px;
      border: 2px dotted #FFFFFF;
      background-color: rgba(0, 0, 0, 0);
      color: #FFFFFF;
      text-transform: uppercase;
      align-self: flex-end;
      transition: 0.5s;

      &:hover {
        transform: scale(1.02);
        border: 2px solid #FFFFFF;
      }
    }

    &__link {
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-decoration: none;
      color: #FFFFFF;
      margin: 0;
    }
  }

  .modal {

    &__title {
      padding: 20px;
      margin: auto;
      text-align: center;
      border-radius: 15px;
      margin-bottom: 15px;

      h3 {
        font-size: 26px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
      }
    }

    &__form {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;

      border-radius: 20px;
      margin-bottom: 10px;
      padding: 20px;

      ol {
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        margin: 24px 0;

        li {
          margin-bottom: 10px;
        }
      }

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
      }
    }

  }

  .block_button {
    margin-top: 20px;
  }

  .button__promo {
    background-color: #2835C1;
    color: #FFFFFF;
    padding: 18px 60px;
    border-radius: 14px;
    border: none;


    font-size: 16px;
    font-weight: 500;
    line-height: 18px;

    &:hover {
      transform: scale(1.02);
    }
  }

  .disable {
    transition: all .6s ease-in-out;
    background-color: #cccccc;
    color: #000;
  }

@media (min-width: 768px) {
  .company {
    &__promo {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &__button {
        align-self: center;
        margin-top: 0;
      }
    }
  }

  .modal {

    &__title {
      font-size: 20px;
    }

    &__form {
      padding: 40px;

      ol {
        font-size: 16px;
      }
    }
  }

  .block_button {
    margin-top: 50px;
  }
}

@media (min-width: 992px) {
  .company__promo {
    max-width: 100%;
    margin-left: 15px;
    margin-top: 0;
  }

  .modal {

    &__title {
      padding: 30px 90px;
    }
  }
}

  @media (min-width: 1200px) {
    .company__promo {
      padding: 30px;
    }
  }

@media (min-width: 1400px) {
  .company__promo {
    padding: 30px 35px;

    &__title {
      font-size: 24px;
    }
  }
}

</style>