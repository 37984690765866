<template>
  <div v-if="isLoading" class="block__loader">
    <loader></loader>
  </div>
  <div v-else id="test" class="container m-3" :class="{'page-blocked': isPageBlocked}">
    <button class="button_info" @click="this.isShowModal = !this.isShowModal">
      <img src="../../assets/info.svg" class="mr-1" alt="info">
      {{ $t("genius_profile.btn_info") }}
    </button>
    <div class="test">
      <div :class="{ 'shake': isNeedGiveAnswer }" class="test__content">
        <div class="test__text-content">
          <div class="test__question text-center">
            {{ this.questions[this.currentQuestion]["question"]["text"] }}
          </div>
          <div v-show="isNeedGiveAnswer" class="alert alert-danger" role="alert">
            Вы не можете пропустить вопрос
          </div>
          <div class="test__answers">
            <div v-for="(answer,index) in this.answers">
              <test-answer :class="{ 'active': checkAnswer(index) }" :question="answer"
                           @click="changeAnswer(index)"></test-answer>
            </div>
          </div>
        </div>
      </div>

      <div class="test__control">
        <button id="back" class="control__button" @click="prevQuestion">
          <img alt="" class="control__arrow back" src="../../assets/back.svg">

        </button>
        <test-progress-bar :class="'d-lg-flex'" :current-question="currentQuestion"
                           :questions-length="questions.length">
        </test-progress-bar>
        <button v-show="(currentQuestion < questions.length - 1)" id="next" class="control__button"
                @click="nextQuestion">

          <img alt="" class="control__arrow next" src="../../assets/next.svg">
        </button>
        <button v-show="(currentQuestion === questions.length - 1) && !checkAnswer('')" id="next"
                class="control__button finish" @click="sendResults">Завершить
        </button>
      </div>
    </div>
  </div>
  <transition name="modal">
    <ModalInfo v-if="isShowModal" :isTransparentBackground="true" @close="isShowModal = false">
      <div class="plug">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="plug__text bg-white my-auto mx-auto h-100">
              <div class="plug__title">Уровни осознанности (демо-режим)</div>
              <div>
                <p class="mb-3">
                  Уровень осознанности — это сочетание переживаний, убеждений и потребностей, свойственных человеку в
                  определенный период жизни. Ваш текущий уровень сознания влияет на ваш образ мышления и отражается на всем,
                  что
                  вы делаете.
                  На каждом уровне есть доминирующая эмоция и модель восприятия, от которых зависят не только желания и
                  мотивация
                  человека, но и его физические и моральные силы.
                </p>
                <p class="m-0">
                  Наш опрос поможет вам разобраться, как именно вы видите окружающий мир. Вы не только узнаете распределение
                  своего сознания по 7 уровням, но и получите ценные советы для выстраивания эффективного взаимодействия с
                  окружающими
                  и развития своей сознательности.
                </p>
              </div>

            </div>
          </div>

          <div class="col-12 col-lg-6 mt-3 mt-lg-0 d-flex flex-column justify-content-between">
            <div>
              <div class="plug__warn w-100 bg-white">
                <img alt="icon" src="../../assets/user/visible.svg">
                <div>Ваш результат будет <b>виден только вам и вашему руководителю.</b></div>
              </div>
              <div class="plug__warn w-100 bg-white">
                <img alt="icon" src="../../assets/user/clock.svg">
                <div>Прохождение занимает <b>примерно 15 минут.</b></div>
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <button class="plug__button mt-3 mt-lg-0"
                      @click="isShowModal = false">К тесту
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalInfo>
  </transition>

</template>

<script>

import TestAnswer from "@/components/Test/TestAnswer.vue";
import TestProgressBar from "@/components/Test/TestProgressBar.vue";
import Loader from "@/components/UI/Loader.vue"
import {mapActions, mapGetters} from "vuex";
import TestRound from "@/components/Test/TestRounds.vue"
import api, {backendUrl} from "@/api";
import Tooltip from "@/components/UI/Tooltip.vue";
import ModalInfo from "@/components/UI/Modal/ModalInfo.vue";

export default {
  name: "Test",
  components: {ModalInfo, Tooltip, TestProgressBar, TestAnswer, Loader, TestRound},


  computed: {

    ...mapGetters(
        {
          getUsername: 'AuthModule/getUsername',
          isAllowedForTests: "AuthModule/isAllowedForTests",
        }
    ),
  },

  data() {
    return {
      isLoading: true,
      showImage: true,
      currentQuestion: 0,
      mixedResponses: [],
      answers: [ "абсолютно не согласен",
        "скорее не согласен",
        "частично согласен",
        "согласен",
        "полностью согласен",],
      questions: [],
      isNeedGiveAnswer: false,
      results: [],
      avg:0,
      images: [],
			isPageBlocked: false,
      isShowModal: false
    }
  },


  beforeMount() {
        api.getLevelTests().then(r => {
      this.questions = r.data.reverse();
      this.isLoading = false;
    });


  },

  methods: {
    ...mapActions({
      onSendLevelResult: 'AuthModule/onSendLevelResult',
    }),
    download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], {type: contentType});
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    },

    countResult() {
      let helpArray = [0, 0, 0, 0, 0, 0, 0];
      let totalResult = 0;
      for (let i in this.questions) {
        helpArray[this.questions[i].level] += this.questions[i].answer;
        totalResult += this.questions[i].answer;
      }

      let temp = 0;
      for (let i = 0; i <= 6; i++) {
        temp += + (i+1) * helpArray[i]
      }

      for (let i = 0; i <= 6; i++) {
        this.results[i] = (helpArray[i] * 100 / 28).toFixed(2);
      }


      this.avg = (temp/totalResult).toFixed(1);
      if(isNaN(this.avg)) this.avg = 0;
      console.log(this.results)
      console.log(this.avg)

    },

    sendResults() {
			this.isPageBlocked = true;
			this.isLoading = true;
      this.countResult();
       this.onSendLevelResult({data:this.results, avg:this.avg})
      setTimeout(() => this.$router.push('/users?show=2'),500);
    },


    changeAnswer(answer) {
      this.questions[this.currentQuestion].answer = answer;
      this.isNeedGiveAnswer = false;
      if (this.currentQuestion < this.questions.length - 1) {
        setTimeout(() => {
          this.nextQuestion();
        }, 400)
      }
    }
    ,
    checkAnswer(answer) {
      return this.questions[this.currentQuestion].answer === answer
    }
    ,

    nextQuestion() {
      if ((this.currentQuestion < this.questions.length - 1) && (this.questions[this.currentQuestion].answer !== "")) {
        this.currentQuestion++;
      } else {
        this.isNeedGiveAnswer = true;
      }
    }
    ,
    prevQuestion() {
      this.isNeedGiveAnswer = false;
      if (this.currentQuestion > 0) {
        this.currentQuestion--;
      }
    }
  },

}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all .4s ease;
}

.slide-fade-leave-active {
  transition: all .4s ease
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}

.button_info {
  background: #FFFFFF;
  border: #FFFFFF 1px solid;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 106%;
  padding: 10px 20px;
  margin: 7px auto 0;
  transition: all 0.2s linear;
  cursor: pointer;

  img {
    margin-right: 5px;
  }

  &:hover {
    border-color: #090D39;
  }
}

.block__loader {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}



.page-blocked {
	pointer-events: none;
	opacity: 0.5;
}

.shake {
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 10000px;
}

.plug {
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 30px;

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    margin: 0 0 15px;

  }

  &__text {
    font-weight: 400;
    font-size: 13px;
    padding: 16px 22px;
    margin: 0;
    border-radius: 20px;
    text-align: justify;
  }

  &__warn {
    font-weight: 400;
    font-size: 13px;
    border-radius: 13px;
    padding: 16px 22px;
    display: flex;
    text-align: justify;

    p {
      margin: 0;
    }

    img {
      margin-right: 20px;
      align-self: start;
    }

  }

  &__button {
    background: #090D39;
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    padding: 15px 120px;
    //width: 100%;
  }
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}


.test {
  max-width: 500px;
  margin: 20px auto 0;
  padding: 0;
  display: flex;
  flex-direction: column;


  &__control {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: calc(8px + 15 * (100vw / 2560));
    flex-wrap: wrap;
  }


  &__content {
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column-reverse;
  }

  &__text-content {
    padding: 25px 30px;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__question {
    font-weight: bold;
    font-size: calc(10px + 22 * (100vw / 3500));
    margin-bottom: 15px;
  }

}


.control {
  //&__arrow {
  //width: 20px;
  //height: 20px;

  //&.back {
  //	margin-right: 10px;
  //}

  //&.next {
  //	margin-left: 10px;
  //}
  //}

  &__button {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 10px 10px;
    border-radius: 50%;
    transition: all .1s linear;
    white-space: nowrap;
    //min-width: 30%;

    &:hover {
      transform: scale(103%);
    }

    &.finish {
      background: #1C2E76;
      font-weight: bold;
      color: white;
      border-radius: 10px;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 768px) {

  .test {
    max-width: 100%;

    &__control {
      margin: 30px 0;
    }

    &__content {
      border-radius: 18px;
    }

    &__img {
      object-fit: cover;
      width: 100%;
      min-height: 400px;
      max-height: 500px;
    }

    &__text-content {
      padding: 15px 20px;
    }
  }

  .control__button {
    border-radius: 18px;
    padding: 10px 30px;

  }

}

// Medium devices (tablets, less than 992px)
@media (min-width: 991.98px) {
  #back {
    order: 1;
  }

  #next {
    order: 3;
  }

  .test__control {
    flex-wrap: nowrap;
    align-items: center;
    margin: 20px 0 0;
    max-height: 500px;
    max-height: 580px;
  }

  .control__button {
    //min-width: 27%;

    &.finish {
      border-radius: 18px;
    }
  }

  .test {
    display: flex;
    justify-content: center;

    &__content {
      min-height: 350px;
      max-width: 600px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0 auto;
    }
  }

}

@media(min-width: 1440px) {

  .test__content {
    min-height: 400px;
  }

  .test__text-content {
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
