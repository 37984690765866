<template>
  <div id="notification">
    <div class="accesses">
      <img src="@/assets/company/attention.svg" alt="attention">
      <p v-if="accesses > 0 ">
        {{ (accesses === 1) ? $t('access_counter.count_registrations_one') : $t('access_counter.count_registrations')}}
        {{ accesses }}
        {{ getNotificationString }}
      </p>
      <p v-else>{{$t('access_counter.no_registrations')}}</p>
    </div>
    <button class="buy-accesses" @click="showBuy = true">{{$t("access_counter.buy")}}</button>
  </div>

  <PopUpAddRegistrations :id="this.id"  :is-show-form="showBuy" @notShow="showBuy = false"></PopUpAddRegistrations>
</template>

<script>
import PopUpAddRegistrations from "@/components/Company/Pop-upAddRegistrations.vue";
import api from "@/api";

export default {
  name: "AccessLeftNotification",
  components: {PopUpAddRegistrations},

  props: {
    accesses: {
      type: Number,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data(){
    return {
      showBuy: false,
    }
  },

  computed: {
    getNotificationString() {
      switch (this.accesses % 10) {
        case 1 :
          return this.$t('access_counter.one_registration');
          break;
        case 2:
        case 3:
        case 4:
          return this.$t('access_counter.many_registrations');
          break;
        default :
          return this.$t('access_counter.default')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#notification {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #FF8D004D;
  padding: 15px 34px;
  border-radius: 20px;
}

.accesses {
  max-width: 270px;
  margin-right: 15px;
  text-align: center;
  padding: 9px;
  border-radius: 17.8988px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: flex-start;


  & p {
    color: #000;
    font-size: 16px;
    margin-bottom: 0;
  }

  & img {
    margin-right: 10px;
  }
}

.buy-accesses {
  cursor: pointer;
  color: #FFFFFF;
  background: #090D39;
  border-radius: 14px;
  height: 100%;
  padding: 16px 0;
  text-align: center;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
  border: none;

  &:hover {
    background: #060E64;
    font-weight: 500;
  }

  &:active {
    transform: scale(105%);
  }
}

@media (min-width: 768px) {
  #notification {
    flex-direction: row;
    justify-content: space-between;
  }


}

@media(min-width: 1000px) {
  .accesses span {
    font-size: 16px;
  }

  .accesses {
    max-width: 100%;
    width: 100%;
    align-items: center;
  }
}
</style>