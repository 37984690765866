<template>
	<div v-if="show" class="position-absolute w-100 h-100 bg-transparent top-0 " @click="show = false"
		style="left: 0; z-index: 99  "></div>
	<div class="position-relative d-flex align-items-center">
		<Tooltip :content="$t('tooltip.share')" :id="'share'">
			<button id="share" class="share" @click="show = !show" :class="{ active_button: show }">
				<img alt="" src="../../assets/user/icon/icon_share.svg">
			</button>
		</Tooltip>

		<ul :class="{ 'd-block': show }" class="share-drop">
			<li class="share-item" @click="share('VK')">
				<img alt="" src="@/assets/vk.svg">
				<span>VK</span>
			</li>
			<li class="share-item" @click="share('TELEGRAM')">
				<img alt="" src="@/assets/telegram.svg">
				<span>Telegram</span>
			</li>
      <li class="share-item" @click="share('LINK')">
        <img alt="" src="@/assets/user/link1.svg">
        <span>Ссылка</span>
<!--        <transition name="fade" mode="out-in">-->
<!--          <p v-if="isCopiedLink" id="my-text" class="tooltipContent fade-out" @transitionend="hideMessage">Ссылка скопирована</p>-->
<!--        </transition>-->
      </li>
		</ul>
	</div>

  <transition name="modal">
    <Modal v-if="isCopiedLink" :isTransparentBackground="true"
           :title="''"
           @close="isCopiedLink = false">
      <div class="bg-white d-flex justify-content-center text-center" style="border-radius: 20px; padding: 30px 20px;">
        Ссылка на ваш результат успешно скопирована.<br>Теперь вы можете поделиться результатом
      </div>
    </Modal>
  </transition>
</template>



<script>
import typesUtils from "@/mixins/typesUtils";
import Tooltip from "@/components/UI/Tooltip.vue"
import Modal from "@/components/UI/Modal/Modal.vue";

export default {
	components: {Modal, Tooltip },
	mixins: [typesUtils],
	name: "TalentShareMenu",
	data() {
		return {
			show: false,
			type: '',
      isCopiedLink: false
		}
	},
	props: {
		results: {
			type: Object,
			required: true
		},
    link: {
      type: String,
      default: ""
    }
	},
	methods: {
		share(target) {

			this.$emit('share', target)

			switch (target) {
				case 'VK': {
          this.show = false;
					let text = `Мой внутренний Гений — ${this.displayTypeName(this.type)}! А какой твой?`

          if (this.link) {
            let textLink =  'https://teamwisdom.ru/user/share/' + this.link;
            window.open(`http://vk.com/share.php?url=${textLink}&title=${text}&image=https://teamwisdom.ru/api/resources/images/share.png`, '_blank');
          } else {
            window.open(`http://vk.com/share.php?url=https://teamwisdom.ru&title=${text}&image=https://teamwisdom.ru/api/resources/images/share.png`, '_blank');
          }
          break;
				}
        case 'TELEGRAM': {
          this.show = false;
					let text = `Мой внутренний Гений — ${this.displayTypeName(this.type)}! А какой твой? Team Wisdom — это заманчивый способ узнать о своём Гении. Пройди тест и раскрой свои внутренние таланты с https://teamwisdom.ru!`

          if (this.link) {
            let textLink = 'https://teamwisdom.ru/user/share/' + this.link;
            window.open(`https://t.me/share/url?url=${textLink}&text=${text}`, '_blank');
          } else {
            window.open(`https://t.me/share/url?url=https://teamwisdom.ru&text=${text}`, '_blank');
          }
					break;
				}
        case 'LINK': {
          console.log(this.link)
          this.copyTextInvitation();
        }
			}
		},
    async copyTextInvitation() {
      if (this.link) {
        let text = 'https://' + window.location.host + '/user/share/' + this.link;
        navigator.clipboard.writeText(text)
            .catch(err => {
              console.log('Something went wrong', err);
            });
        this.isCopiedLink = true;
      }
    },
    hideMessage() {
      this.isCopiedLink = false;
      // this.show = false;
    }
	},
	mounted() {
		let max = 'creator';
		for (let resultsKey in this.results) {
			let currentValue = this.results[resultsKey]
			if (currentValue > this.results[max]) {
				max = resultsKey
			}
		}
		this.type = max;
	}
}
</script>

<style lang="scss" scoped>
li {
	list-style: none;
}

ul {
	padding-left: 0;
}

.share {
	cursor: pointer;
	background: white;
	padding: 6px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 11px;
	transition: all 0.2s linear;
	border: 1px solid white;
	margin-left: 10px;

	&:hover {
		border-color: #090D39;
	}

	&:active {
		transform: scale(110%);
	}

	& img {
		width: 16px;
	}

	&-drop {
		display: none;
		transition: all .6s ease-in-out;
		width: 125px;
		position: absolute;
		top: 50px;
		right: -100%;
		border-radius: 11px;
		z-index: 9997;
		background: #ffffff;
		overflow: hidden;
	}

	&-item {
		padding: 6px 0px 6px 20px;
		cursor: pointer;
		float: left;
		overflow: hidden;
		transition: .2s all linear;
    width: 100%;

		&:active {
			transform: scale(105%);
		}

		& img {
			width: 22.5px;
		}

		&:hover {
			background: rgba(128, 128, 128, 0.2);

		}

		& span {
			padding-left: 12.5px;
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;
		}
	}
}

.active_button {
	background-color: rgba(0, 0, 0, 0);
	border-color: #090D39;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}

.tooltipContent {
  padding: 6px 12px;
  text-align: center;
  transition: all .6s ease-in-out;
  //width: 125px;
  font-size: 10px;
  position: absolute;
  top: 70px;
  left: 30px;
  z-index: 9999;
  background: #090D39;
  color: #FFFFFF;
  border-radius: 5px;
}
</style>
