<template>
  <div class="container footer">
    <div class="container row mb-3">
      <div class="col-12 col-md">

        <p class="©TM" >{{$t('footer.info_company')}}</p>

      </div>

      <div class="supportEmail col-12 col-md text-md-end mb-2 mb-md-0">
        <span>support@teamwisdom{{$t('footer.email')}}</span>
        <br>
        <a style="color: #2835C1; text-decoration: none;" download href="resources/policy.odt">{{$t('footer.privacy_policy')}} </a>
        <br>
        <a download href="resources/contract-offer.odt" style="color: #2835C1; text-decoration: none;" >{{ $t('footer.contract_offer') }}</a>
      </div>
    </div>
  </div>

</template>

<script setup>

</script>

<style scoped lang="scss">
.footer {

  font-weight: 400;
  margin: 100px auto 15px;
  max-width: 1200px;
  display: flex;
  justify-content: center;
}

.footer a {
  color: white;
  text-decoration: none;
}

.©TM {
  float: left;
  max-width: 200px;
}

.supportEmail {
  float: right;
}
</style>