<template>
  <div v-if="levelResult != null">

    <div v-if="info!=null" class="container">
      <div class="mb-4">
        <div class="d-flex justify-content-between flex-column-reverse flex-lg-row">
          <div class="d-flex justify-content-between flex-column">
            <UserBlock >
              <div class="level__title">
                <h3>Ваш уровень осознанности:</h3>
                <h4>{{ info["levelName"] }}</h4>
                <h3 class="mt-4">Ваш средний уровень сознания:</h3>
                <h4>{{ levelResult.avg }}</h4>
              </div>
            </UserBlock>
            <div class="user__item d-md-flex mt-2 mt-xl-4">
              <div v-for="text in info['emotions']">
                <UserItem >{{ text }}</UserItem>
              </div>
            </div>
          </div>


          <div class="bg-white level__wrapper mb-3 mb-lg-0">
            <LevelTestDisplay :result="levelResult.result"/>
          </div>
        </div>
      </div>



      <div class="mb-4">
        <UserBlockTitle :title="info['fullNameLevel']"></UserBlockTitle>
      </div>


      <div class="mb-4">
        <UserBlock>
          <div v-html="info['descriptionLevel']"></div>
        </UserBlock>
      </div>

      <div class="mb-4">
        <div class=" d-flex flex-column">
          <UserBlock>

            <h4 class="menege__title">Тип руководителя: {{ this.info["NameTypesManager"] }}</h4>
            <div v-html=" this.info['typesManager']"></div>

          </UserBlock>
        </div>
      </div>

      <div class="row d-flex ">
        <div class="col-12 col-lg-6">
          <div class="mb-4">
            <UserBlockTitle :title="'Совет для вас:'"></UserBlockTitle>
          </div>
          <div class="mb-4">
            <UserBlock>
              <ul>
                <li v-for="text in info['adviceYou']">{{ text }}</li>
              </ul>
            </UserBlock>
          </div>


        </div>
        <div class="col-12 col-lg-6">
          <div class="mb-4">
            <UserBlockTitle :title="'Совет для коллег:'"></UserBlockTitle>
          </div>
          <div class="mb-4">
            <UserBlock>
              <ul>
                <li v-for="text in  info['adviceColleagues']">{{ text }}</li>
              </ul>
            </UserBlock>
          </div>
        </div>
      </div>

      <div class="d-block mt-3">
        <UserBlockTitle :title="'Смотрите также:'"></UserBlockTitle>
      </div>
      <div class="row gx-2 mt-3">
        <div v-for="(level,index) in Object.keys(this.levelNames)" v-show="level != info['levelNumber'] + 1"
             class="col-12 col-md-6 col-lg-4 p-0 ">
          <UserButtonPureType :bg-color="'#0A0F3F'" :name="this.levelNames[level]" :number="level"
                              :type="this.levelNames[level]" @click="$router.push('/levels/' + level)"> {{
              level
            }}
          </UserButtonPureType>
        </div>
      </div>
    </div>
  </div>

  <div v-else-if="isOwnPage" class="plug d-flex flex-column align-items-center">
    <div class="row mb-3">
      <div class="col-12 col-lg-6">
        <div class="plug__text bg-white my-auto mx-auto h-100">
          <div class="plug__title">Уровни осознанности (демо-режим)</div>
          <div>
            <p class="mb-3">
              Уровень осознанности — это сочетание переживаний, убеждений и потребностей, свойственных человеку в
              определенный период жизни. Ваш текущий уровень сознания влияет на ваш образ мышления и отражается на всем,
              что
              вы делаете.
              На каждом уровне есть доминирующая эмоция и модель восприятия, от которых зависят не только желания и
              мотивация
              человека, но и его физические и моральные силы.
            </p>
            <p class="m-0">
              Наш опрос поможет вам разобраться, как именно вы видите окружающий мир. Вы не только узнаете распределение
              своего сознания по 7 уровням, но и получите ценные советы для выстраивания эффективного взаимодействия с
              окружающими
              и развития своей сознательности.
            </p>
          </div>

        </div>
      </div>

      <div class="col-12 col-lg-6 mt-3 mt-lg-0 d-flex flex-column justify-content-between">
        <div>
          <div class="plug__warn w-100 bg-white">
            <img alt="icon" src="@/assets/user/visible.svg">
            <div>Ваш результат будет <b>виден только вам и вашему руководителю.</b></div>
          </div>
          <div class="plug__warn w-100 bg-white">
            <img alt="icon" src="@/assets/user/clock.svg">
            <div>Прохождение занимает <b>примерно 15 минут.</b></div>
          </div>
          <div class="plug__warn w-100 bg-white ">
            <img alt="icon" src="@/assets/user/info_black.svg">
            <div v-if="!haveFeedback && !justSendFeedback">
              <p class="mb-2">Чтобы получить бесплатный доступ к прохождению теста, оставьте отзыв на наш опрос <b>"Профиль Гения"</b></p>
              <br>
              <p>
                <b>Оставившим отзыв</b>, прохождение теста "Уровни осознанности" <b>будет доступно бесплатно</b>!
              </p>
            </div>
            <div v-else>
              <p class="mb-2"><b>Спасибо за отзыв! </b></p>
              <p>
                Вы можете начать прохождение теста "Уровни осознанности".</p>
            </div>
          </div>
        </div>

        <div>
          <button v-if="!haveFeedback && !justSendFeedback && !isShowFeedback" class="plug__button mt-3 mt-lg-0"
                  @click="isShowFeedback = true">Далее
          </button>
          <button v-if="haveFeedback" class="plug__button mt-3 mt-lg-0"
                  @click="this.$router.push('/level')">Пройти тест
          </button>
        </div>
      </div>
    </div>


    <div v-if="!haveFeedback && !justSendFeedback" class="w-100 ">

      <Transition name="show-feedback">
        <TalentFeedbackModal v-if="isShowFeedback" @sended="this.$emit('sendFeedback')"/>
      </Transition>
    </div>


  </div>
  <div class="container " v-else><h4 class="text-center">Тест не пройден</h4></div>
</template>

<script>
import UserBlock from "@/components/User/UserBlock";
import NewResultDisplay from "@/components/User/TalentResultDisplay";
import Loader from "@/components/UI/Loader";
import UserBlockTitle from "@/components/User/UserBlockTitle";
import UserItem from "@/components/User/UserItem.vue";
import UserButtonPureType from "@/components/User/UserButtonPureType.vue"
import typesUtils from '@/mixins/typesUtils'
import FormTokenDescription from "@/components/Form/FormTokenDescription.vue";
import TalentFeedbackModal from "@/components/User/TalentFeedbackModal.vue";
import LevelTestDisplay from "@/components/Test/LevelTestDisplay.vue";
import api from "@/api";
import {mapActions, mapGetters} from "vuex";

export default {
  mixins: [typesUtils],
  name: "UserTalentTest",
  components: {
    LevelTestDisplay,
    TalentFeedbackModal,
    Loader,
    UserBlockTitle,
    UserItem,
    UserBlock,
    UserButtonPureType,
    NewResultDisplay,
    FormTokenDescription
  },


  data() {
    return {
      justSendFeedback: false,
      isShowFeedback: false,
      isShowPositions: false,
      isShowSkils: false,
      info: null,
      levelNames: {
        1: "Выживание",
        2: "Защита",
        3: "Рационализм",
        4: "Альтруизм",
        5: "Вдохновение",
        6: "Творчество",
        7: "Просветление",
      }
    }
  },

mounted() {
    if(this.levelResult != null){
      console.log(this.levelResult.result);
      api.getLevelInfo().then(r => {
        let i = this.getIndexOfMax(this.levelResult.result);
        this.info = r.data[i];
      });
    }

  },
  methods:{
    getIndexOfMax(arr){
      let imax = 0;
      console.log(arr);
      for (let i = 1; i <arr.length; i++) {
        if(arr[i] >=   arr[imax]){
          imax = i;
        }
      }
      return imax;
    }
  },



  props: {

    haveFeedback: {
      type: Boolean,
      required: true
    },
    levelResult: {

    },
    isOwnPage:{
      type: Boolean,
      required: true
    }
  },

}
</script>

<style lang='scss' scoped>
.flip-list-move {
  transition: all 0.5s;
}

.flip-list-item {
  display: inline-block;
  margin-right: 10px;
}

.flip-list-enter-active,
.flip-list-leave-active {
  transition: all 1s;
}

.show-feedback-enter-active {
  animation: show 0.5s linear;
}

.show-feedback-leave-active {
  animation: show 0.5s linear reverse;
}

@keyframes show {
  0% {

    opacity: 0;
  }

  100% {

    opacity: 1;
  }
}

* {
  font-family: 'Ubuntu', sans-serif;
  color: #1E1E1E;
}

.menege__title {
  margin: 0;
	margin-bottom: 15px;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
}

.verify__button {
  font-weight: 500;
  font-size: 16px;
  padding: 5px 20px;
  border: none;
  border-radius: 11px;

  &:hover {
    transform: scale(102%);
  }

  &:active {
    transform: scale(105%);
  }

  &.verify {
    color: white;
    background: #2AB573;
    margin-right: 10px;
  }

  &.restart {
    color: #000000;
    background: #FFFFFF;
  }
}

.level {
  &__wrapper{
    padding:15px 30px;
    border-radius: 36.0384px;


  }
  &__title {
    h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 106%;
    }

    h4 {
      font-weight: 500;
      font-size: 35px;
      line-height: 106%;
    }
  }

  &__mainInfo {
    padding: 20px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 106%;
  }

  &__img {
    object-fit: cover;
    border-radius: 22px;
    overflow: hidden;
  }

}

.btn {
  color: white;
}

.user {
  &__item {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
    div {
      margin: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

.plug {

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    margin: 0 0 15px;

  }

  &__text {
    font-weight: 400;
    font-size: 13px;
    padding: 20px;
    margin: 0;
    border-radius: 20px;
    text-align: justify;
  }

  &__warn {
    font-weight: 400;
    font-size: 13px;
    border-radius: 13px;
    padding: 16px 22px;
    display: flex;
    margin-bottom: 12px;
    text-align: justify;

    p {
      margin: 0;
    }

    img {
      margin-right: 20px;
      align-self: start;
    }

  }

  &__button {
    background: #090D39;
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    padding: 15px 60px;
    width: 100%;
  }
}


@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .level__wrapper{
    padding:35px 60px;}

  .user__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .plug__warn {
    img {
      margin-right: 40px;
    }
  }
  .plug__text {
    padding: 31px 50px 50px;
  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .user__result {
    max-width: 35%;
  }
  .level{
    &__wrapper {
      margin-left: 40px;
    }
  }
}

/*// X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}

/*// XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {
}
</style>
