<template>
  <div class="cards">

    <div v-for="position in this.positions" :key="position.id" class="cards__card" >
      <div  class="cards__card__block" >
        <div :class="{'active': !settingTestUser.isAppoint[position.id]}"></div>
        <div v-if="settingTestUser.isPassedTest[position.id]" class="imgIsPassed"><img src="@/assets/user/test-competence/userIsPassedTest.svg" alt="userIsPassedTest"></div>
        <h2>{{position.name}}</h2>
        <div v-if="position.id === 1" class="icon-test">
          <img src="@/assets/user/test-competence/soft_skills_test.png" alt="icon_test">
        </div>

        <div v-else class="icon-test">
          <img src="@/assets/user/test-competence/manage_test.png" alt="icon_test">
        </div>
      </div>
      <div>
        <button class="cards__card__button" v-if="!settingTestUser.isAppoint[position.id]" @click="this.$emit('setIsAppoint', position.id)">Назначить тест</button>

        <button v-else-if="settingTestUser.isPassedTest[position.id]" class="cards__card__button" @click="this.$emit('openResult', position.id);">
          Посмотреть результат
        </button>

        <button v-else class="cards__card__button" @click="this.$router.push('/competence?id='+position.id)">Пройти тест</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    positions: {
      type: [],
      required: true
    },
    settingTestUser: {
      type: [],
      required: true
    }
  },

  data() {
    return {
    }
  },
  methods: {
    takeTest(id) {
      this.$router.push(`/test-competence?id=${id}`);
    },

  },
  mounted() {
  },
}
</script>

<style scoped lang="scss">

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;


  &__card {
    position: relative;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 30px;

    h2 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
      max-width: 150px;
      min-height: 40px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__block {
      padding: 20px 30px;
      background-color: #fff;
      border-radius: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 10px;
      position: relative;
    }

    &__button {
      padding: 5px;
      background-color: #fff;
      border: 1px solid #fff;
      border-radius: 8px;
      transition: all 0.2s linear;
      width: 100%;
    }
  }
}

.icon-test {
  display: flex;
  justify-content: center;
  & img {
    width: 150px;
  }
}

.imgIsPassed {
  position: absolute;
  top: 10px;
  left: 80%;

  & img {
    width: 25px;
    height: 25px;
  }
}

.active {
  background: rgba(234, 237, 246, 0.44);
  backdrop-filter: blur(5.5px);
  border-radius: 22px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

@media(min-width: 493px) {
  .cards__card {
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media (min-width: 992px) {
  .cards {
    justify-content: flex-start;

    &__card {
      margin-right: 15px;
    }
  }
}
</style>