<template>
	<div :class="{ 'active': !withoutPadding }"> <!--p-2 p-lg-2-->
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: "UserItem",
	props: {
		isGreen: {
			type: Boolean,
			require: false
		},
		isRed: {
			type: Boolean,
			require: false
		},
		withoutPadding: {
			type: Boolean,
			require: false
		}
	}
}
</script>

<style scoped>
div {
	border-radius: 13px;
	width: 100%;
	height: 100%;
	text-align: center;
	margin: 0;
	font-size: 1rem;
	background-color: #fff;
}

.active {
	padding: 4px 8px;
	border-radius: 8px;
	font-size: 16px;
}



@media (min-width: 576px) {}

@media (min-width: 756px) {
	.active {
		padding: 8px 16px;
		border-radius: 13px;
		font-size: 1rem;
	}
}

@media (min-width: 992px) {}
</style>
