<template>
  <div id="types" class="container-fluid types">
    <div class="types__title">
      <div class="TW">
        <p class="display-5" >Team Wisdom</p>
      </div>
      <div class="everyone">
        <p class="display-5">{{$t('corporate_and_individual.for_yourself_for_business')}}</p>
     </div>
    </div>
    <div class="description">
      <div class="row">
        <div class="col-md d-flex justify-content-start justify-content-lg-end my-3">
          <div class="description__block independent">
            <div class="typeName">
              <p class="" >{{$t('corporate_and_individual.title_corporate')}}</p>
            </div>
            <div class="typeDescription h5">
              {{$t('corporate_and_individual.text_corporate')}}
            </div>
          </div>
        </div>
        <div class="col-md d-flex justify-content-end justify-content-lg-start my-3">
          <div class="description__block corporate">
            <div class="typeName">
              <p>{{$t('corporate_and_individual.title_individual')}}</p>
            </div>
            <div class="typeDescription h5">
              <p>{{$t('corporate_and_individual.text_individual')}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

</script>

<style scoped lang="scss">
.types {
  font-weight: 700;
  margin-bottom: 200px;

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .TW {
    text-align: center;
    width: 100%;

    p {
      font-weight: 700;
      margin: 0;
    }
  }

  .everyone {
    text-align: center;
    width: 100%;

    p {
      font-weight: 700;
      margin: 0;
    }
  }
}

.description {
  margin-top: 50px;

  &__block {
    font-size: 38px;
    font-weight: 700;
    line-height: 40px;
    width: 430px;
    margin-left: 20px;
    padding: 40px;
    box-shadow: 0 0 20px 0.5px #0015FF;
    border: 6px #202FD4 solid;
    text-align: left;
    vertical-align: middle;
  }
}

.independent {
  border-radius: 30px 0 0 30px;
}

.corporate {
  border-radius: 0 30px 30px 0;
}

.typeName {
  p {
    display: inline-block;
    margin-bottom: 35px;
    border-radius: 15px;
    text-decoration: none;
    color: #FFFFFF;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
  }
}

.typeDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  margin: 0;
  text-align: left;
}

@media (min-width: 768px) {
.types {
  .TW {
    width: 600px;
    text-align: left;
  }

  .everyone {
    width: 600px;
  }
}

  .typeName > p {
    font-size: 38px;
  }
  //.types > div.TW {
  //
  //
  //  margin-left: 30%;
  //}
  //
  //.types > div.everyone {
  //
  //
  //  margin-left: 45%;
  //}

}

@media (min-width: 1200px) {
  .description__block {
    width: 560px;
  }
}
</style>