import api from "@/api";
import router from "@/router/router";
import utf from 'utf8'
import {getItem, setItem} from '@/store/LocalStorage';
const USER = 'user';
export const AuthModule = {
    namespaced: true,

    state() {
        return {
            credentials: {
                username: localStorage.getItem("username") || null,
                password: localStorage.getItem("password") || null,
                id: localStorage.getItem("id") || null, //TODO: Remove
                role: localStorage.getItem("role") || null,
                adminCompanies: localStorage.getItem("adminCompanies") || [],
                isAuthenticated: localStorage.getItem("isAuthenticated") || null,
                isAllowedForTests: ((localStorage.getItem("isAllowedForTests") == 'true') ? true : false) || null
            },
            user: getItem(USER) || null,
            loginError: false,
            passwordError: false,
            passwordErrorMessages: '',
            teams :  localStorage.getItem("teams") || [],
            companies : [],
            joinRequests : [],
        }
    },
    getters: {
        isLoginError(state) {
            return state.loginError
        },
        isPasswordError(state) {
            return state.passwordError
        },
        isAuthenticated(state) {
            return state.credentials.isAuthenticated
        },
        isAllowedForTests (state) {
            return state.credentials.isAllowedForTests;
        },
        getPasswordErrorMessages(state) {
            return state.passwordErrorMessages
        },
        getUsername: (state) => state.credentials.username,
        getPassword: (state) => state.credentials.password,
        getId: (state) => state.credentials.id,
        getTeams: (state) =>state.user.teams,
        getRole: (state) => state.credentials.role,
        getAdminCompanies: (state) => state.user.adminCompanies,
        getCompanies: (state) => state.user.companies,
        getJoinRequests: (state) => state.user.joinRequests,
        getUser: (state) => state.user,
        getLevelResult: (state) => state.user.levelResult,
    },

    mutations: {
        authSuccess(state, { email, password, loginResponse }) {
            state.user = loginResponse;
            try{

                var b = JSON.parse(loginResponse.levelResult.result) ;
                state.user.levelResult = {
                    result :b,
                    avg : loginResponse.levelResult.avg
                }
            } catch (e){
                state.user.levelResult = null;
            }
            setItem(USER, loginResponse);
            state.credentials.username = email
            localStorage.setItem("username", email);
            state.credentials.password = password
            localStorage.setItem("password", password);
            state.credentials.id = loginResponse.id
            localStorage.setItem("id", loginResponse.id);
            state.credentials.isAuthenticated = true
            localStorage.setItem("isAuthenticated", state.credentials.isAuthenticated);

            if( loginResponse.allowedForTests) {
                state.credentials.isAllowedForTests = true;
            } else {
                state.credentials.isAllowedForTests = false;
            }
            localStorage.setItem("isAllowedForTests", loginResponse.allowedForTests);
            state.credentials.role = loginResponse.role;
            localStorage.setItem("role", loginResponse.role);
            state.credentials.adminCompanies = loginResponse.adminCompanies.toString();
            localStorage.setItem("adminCompanies", loginResponse.adminCompanies);
            state.loginError = false
            state.passwordError = false;
        },

        authError(state) {
            state.passwordError = false
            state.loginError = true
            state.credentials.isAuthenticated = false
        },
        logout(state) {
            localStorage.clear();
            state.credentials.isAuthenticated = false
        },
        openAccessForTest(state) {
            state.credentials.isAllowedForTests = true
            localStorage.setItem("isAllowedForTests", state.credentials.isAllowedForTests);
        },
        resetLoginError(state) {
            state.loginError = false
            state.passwordError = false
        },
        passwordError(state, messages) {
            state.passwordError = true
            messages = messages.slice(2,-2);
            state.passwordErrorMessages = utf.decode(messages).split(',');
        },
        setCompanies(state,companies){
            state.companies = companies;
            state.user.companies = companies;
        },
        setTeams(state,teams){
            state.teams = teams;
            state.user.teams = teams;
            localStorage.setItem("teams", JSON.stringify( teams));
        },
        setJoinRequests(state,requests){
            state.joinRequests = requests
            state.user.joinRequests = requests;
        },
        setAdminCompanies(state,companies){
            state.credentials.adminCompanies = companies;
            localStorage.setItem("adminCompanies", companies);
        },
        updateProfile(state, data){
            state.user.firstName = data.firstName;
            state.user.lastName = data.lastName;
            state.user.position = data.position;
            state.user.hideTalent = data.hideTalent;
            state.user.phone = data.phone;
            setItem(USER, state.user);
        },
        updateLevelResult(state, data){
            try{
                console.log(data)
                var b = JSON.parse(data.result) ;
                state.user.levelResult = {
                    result :b,
                    avg : data.avg
                }
                setItem(USER, state.user);
            } catch (e){

                state.user.levelResult = null;
            }

        },
        updateUserTalentResult(state, result){
            state.user.talentResult = result;
            state.user.talentResult.verified = false;
            setItem(USER, state.user);
        },
        verifyResult(state){
            state.user.talentResult.verified = true;
            setItem(USER, state.user);
        },
        sendTalentFeedback(state){
            state.user.haveTalentFeedback = true;
            setItem(USER, state.user);
        },
        updateUserCompetenceResult(state, result){
            state.user.passingCompetenceTestResponse = result;
            setItem(USER, state.user);
        },

    },

    actions: {
        openAccessForTest({commit}) {
            commit('openAccessForTest');
        },
        updateSelfCompanies({commit}) {
            api.getSelfCompanies().then(r => commit('setCompanies', r.data))
        },
        updateSelfJoinRequests({commit}) {
            api.getSelfJoinRequests().then(r => commit('setJoinRequests', r.data))
        },
        updateSelfTeams({commit}) {
            api.getSelfTeams().then(r => commit('setTeams', r.data))
        },
        updateAdminCompanies({commit}) {
            api.getCompaniesWhereUserIsAdmin().then(r => commit('setAdminCompanies', r.data))
        },
        onRegister({commit}, {email, password, firstName, lastName, phone,token}) {
            api.createUser(email, password, firstName, lastName, phone, token)
                .then((r) => {
                    let loginResponse = r.data
                    commit('authSuccess', {email, password, loginResponse});
                    if (token) {
                        this.dispatch('AuthModule/updateSelfTeams');
                        this.dispatch('AuthModule/updateSelfCompanies');
                        this.dispatch('AuthModule/updateAdminCompanies');
                    }

                    if (!r.data.allowedForTests) {
                        router.push('/users');
                    } else {
                        router.push('/test')
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.status === 400) {
                        commit('passwordError', (JSON.stringify(err.response.data.errors)))
                    } else if (err.response.status === 409) {
                        commit('authError')
                    }
                })
        },
        onLogin({commit}, {email, password, redirect = true}, ) {
            api.login(email, password)
                .then((r) => {
                    let loginResponse = r.data
                    commit('authSuccess', {email, password, loginResponse});
                    if(redirect) {
                        router.push('/users')
                    }
                })
                .catch(err => {
                    commit('authError')
                    console.log(err)
                })

        },
        onLogout({commit}) {
            commit('logout');
            localStorage.clear();
            router.push('/login')
        },
        onSendLevelResult({commit},{data, avg}){
            console.log(data)
            api.addLevelResultToUser(data,avg).then(r => {
                commit('updateLevelResult',r.data)
            })
        },
        onUpdateProfile({commit}, {data}) {
            api.updateUser(data.firstName, data.lastName, data.position, data.hideTalent, data.phone).then(
                commit('updateProfile', data)
            )
        },
        onSendTalentResult({commit}, {data}) {
            console.log(data)
            api.addResultToUser(JSON.stringify(data)).then( r => {
                commit('updateUserTalentResult', data)
                setTimeout(() => router.push('/users'), 400)
            })

        },
        onSendTalentFeedback({commit}, {data}) {
           api.sendFeedbackForTalent(data).then(r => {
                commit('sendTalentFeedback')
            })
        },
        onVerifyResult({commit}) {
            commit('verifyResult')
            api.verifyTalentResult();
        },
        onSendCompetenceSoftSkills({commit}, {data}) {
            name = 'SoftSkills';
            api.sendResultTestCompetence(JSON.stringify(data), name).then( r => {
                commit('updateUserCompetenceResult', r.data.passingCompetenceTestResponse);
                setTimeout(() => router.push('/users?show=3'), 400)
            })
        },
        onSendCompetenceManageResult({commit}, {data}) {
            console.log(data)
            name = 'SoftSkills';
            api.sendResultTestCompetence(JSON.stringify(data), name).then( r => {
                commit('updateUserCompetenceResult', r.data.passingCompetenceTestResponse)
                setTimeout(() => router.push('/users'), 400)
            })
        },
    }
}
