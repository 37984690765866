<template>
  <div v-if="isShowPopUp">
    <div v-if="!sendForm" id="popup" class="popupShow container-fluid popup d-flex justify-content-center">
      <transition name="slide-fade">
      <div class="popup_body align-self-center">

          <div class="popup_content popup_contentShow col-sm-">
            <div class="row">
              <div class="col d-flex justify-content-end">
                <p class="popup_close" @click.stop="this.$emit('notShow', this.isShowPopUp)">X</p>
              </div>
            </div>
            <div class="row">
              <div class="col d-flex justify-content-center">
                <div class="popup_title h4">{{$t("pop_up.application_platform_demonstration")}}</div>
              </div>
            </div>
            <div class="row my-3">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <div class="mb-3 d-flex flex-column align-items-center justify-content-center" style="position: relative">
                  <div class="required-field p-0">
                    <input id="name" v-model.trim="name" class="getDemoForm " type="text" :placeholder="$t('pop_up.name')">
                  </div>
                  <div :class="{ messageShow: isNameError }" class="message">
                    <p id="firstName" class="error">{{ $t('pop_up.error_fill_field') }}</p>
                  </div>
                </div>

                <div class="mb-3 d-flex flex-column align-items-center justify-content-center" style="position: relative">
                  <div class="required-field p-0">
                  <input v-if="!isLocaleEn" v-imask="mask" :value="phone" class="getDemoForm" :placeholder="$t('pop_up.phone')"
                         type="text" @accept="phoneAccept" @complete="phoneComplete" @keydown.space.prevent>
                    <input v-else v-imask="maskEn" :value="phone" class="getDemoForm" :placeholder="$t('pop_up.phone')"
                           type="text" @accept="phoneAccept" @complete="phoneComplete" @keydown.space.prevent>
                  </div>

                  <div :class="{ messageShow: isPhoneError }" class="message">
                    <p id="firstName" class="error">{{ $t('pop_up.error_fill_field') }}</p>
                  </div>
                </div>

                <div class="mb-3 d-flex flex-column align-items-center justify-content-center" style="margin-top: 25px">

                  <input id="promo" v-model.trim="promo" class="getDemoForm" type="text"
                         :placeholder="$t('pop_up.promo')" @keydown.space.prevent  @input="promocodeError.isError = false">
                  <FormError v-show="promocodeError.isError" :msg="$t('popUp_registration.promo_code_error')"></FormError>
                </div>

                <div class="d-flex justify-content-center">
                  <div class="col d-flex justify-content-center">
                    <ButtonDemo :title="$t('pop_up.btn_send')" class="form__button" @click="sendFormDemo"  :is-sending="isSending"></ButtonDemo>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </transition>
    </div>
    <div v-else id="popupNotification" class="popupShow container-fluid popupNotification d-flex justify-content-center" @click.stop="this.$emit('notShow', this.isShowPopUp)">
      <transition name="slide-fade">
        <div class="popup_body align-self-center">
          <div class="popup_contentNotification popup_contentShow col-sm-">
            <div class="row">
              <div class="col d-flex justify-content-center">
                <div class="popup_title h5" >{{$t('pop_up.thanks')}}</div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/UI/Modal/ModalInfo.vue";
import ButtonDemo from "@/components/Landings/component/ButtonDemo.vue";
import api from "@/api";
import FormItem from "@/components/Form/FormItem.vue";
import useVuelidate from "@vuelidate/core";
import {maxLength, minLength, required} from "@vuelidate/validators";
import {IMaskDirective} from "vue-imask";
import FormError from "@/components/Form/FormError.vue";


const mustBePhone = (value) => new RegExp("^((8|\\+7|\\+1|\\+3)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$").test(value);

export default {
  components: {
    FormError,
    FormItem,
    Modal,
    ButtonDemo,
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  validations() {
    return {
      phone: { mustBePhone }
    }
  },
  props: {
    isShowPopUp: {
      type: Boolean,
      reqiured: true
    },
    parentBlock: {
      type: String
    }
  },
  data() {
    return {
      mask: {
        mask: '+{7}(000)000-00-00',
        lazy: false,  // make placeholder always visible
      },
      maskEn: {
        mask: '+{0}(000)000-00-00',
        lazy: false,
      },
      phone: '',
      name: '',
      promo: '',
      sendForm: false,
      nameError: false,
      phoneError: false,
      promocodeError: {
        isError: false,
        PromocodeErrorMsg: ''
      },
      isLocaleEn: false,
      isSending: false
    }
  },
  computed: {
    isNameError() {
      return this.nameError;
    },
    isPhoneError() {
      return this.phoneError;
    },
    isFormDisabled() {
      return  this.isNameError || this.isPhoneError;
    },
  },
  created() {
    this.getLocalLanguage();
  },
  mounted() {

  },
  directives: {
    imask: IMaskDirective
  },
  methods: {
    phoneComplete(e) {
      const maskRef = e.detail;
      this.phone = maskRef.value
    },
    phoneAccept(e) {
      const maskRef = e.detail;
      this.phone = maskRef.value;
    },
    getLocalLanguage() {
      const currentLocale = this.$i18n.locale;
      //const currentLocale = 'en'
      if (currentLocale === 'en') {
        this.isLocaleEn = true;
      }
    },
    checkName() {
      this.nameError = (this.name.length === 0);
    },

    checkPhone() {
      this.phoneError = (this.phone.length === 0);
    },
    checkAll() {
      this.checkName();
      this.checkPhone();
    },
    sendFormDemo() {
      this.checkAll();
      if(!this.isFormDisabled) {
        api.sendFormPromo(this.name, this.phone, this.promo, this.parentBlock)
            .then(this.isSending = true)
            .then(r => {
              this.sendForm = true
            })
            .catch(error => {this.promocodeError.PromocodeErrorMsg = error.response.data.message; this.promocodeError.isError = true})
            .finally(() => {this.isSending = false});
      } else {
        return
      }
    },
  }
}

</script>

<style>
.slide-fade-enter-active {
  transition: all .4s ease;
}

.slide-fade-leave-active {
  transition: all .4s ease
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}

.modal-mask {
overflow-y: auto;
position: fixed;
display: flex;
align-items: center;
justify-content: center;
z-index: 9998;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
transition: all 0.3s ease;
}

.popup {
position: fixed;
height: 100%;
background-color: rgba(0, 0, 0, 0.8);
top: 0;
left: 0;
z-index: 1100;
opacity: 0;
visibility: hidden;
overflow-y: auto;
overflow-x: hidden;
transition: 0.5s;
}

.popupShow {
  visibility: visible;
  transform: perspective(600px) translate(0px, 0px) rotateX(0deg);
  opacity: 1;
  transition: all 0.3s ease;
}

.popup_body {
padding: 15px 15px;
}

.popup_title {
text-align: center;
}

.popup_content {
max-width: 500px;
min-width: 320px;
padding: 30px;
background: black;
color: white;
border-radius: 15px;
border: 2px #202FD4 solid;
box-shadow: 0px 0px 20px 0.5px #0015FF;
transition: 0.8s;
opacity: 0;
transform: perspective(600px) translate(0px, -100%) rotateX(45deg);
}

.popup_contentShow {
transition: 0.8s;
opacity: 1;
transform: perspective(600px) translate(0px, 0px) rotateX(0deg);
}

.popup_close {
width: 30px;
height: 30px;
text-align: center;
align-content: center;
text-decoration: none;
color: #202FD4;
border-radius: 50%;
border: 3px #202FD4 solid;
cursor: pointer;
}

.getDemoForm {
width: 250px;
color: white;
padding: 15px;
background: transparent;
border: 3px #202FD4 solid;
border-radius: 8px;
}

.getDemoForm:focus {
outline: none;
transition: 0.2s;
}


.form__button {
width: 250px;
margin: 0;
}

.popupNotification {
position: fixed;
height: 100%;
background-color: rgba(0, 0, 0, 0.8);
top: 0;
left: 0;
z-index: 1100;
overflow-y: auto;
overflow-x: hidden;
transition: 0.5s;
}

.popup_contentNotification {
max-width: 500px;
padding: 30px;
background: black;
color: white;
border-radius: 15px;
border: 2px #202FD4 solid;
box-shadow: 0px 0px 20px 0.5px #0015FF;
transition: 0.8s;

}

.message {
width: 250px;
transition: 0.2s;
font-weight: 400;
height: 0;
overflow: hidden;
padding: 0;
}

.messageShow {
transition: 0.2s;
font-weight: 500;
height: auto;
margin-top: 5px;
}

.message p {
margin-bottom: 0;
font-size: 14px;
}

.error {
display: block;
color: red;
}

.required-field::before {
  content: "*";
  color: #fff;
  position: absolute;
  top: 3px;
  right: 7px;
  padding: 0;
}
</style>