<template>
  <div v-show="isShowForm">
    <transition name="modal">
      <Modal :isTransparentBackground="true" @close="this.$emit('notShow', this.isShowForm)">
        <template v-if="!sended">
          <div class="modal__title bg-white w-80">
            <h3 class="p-0 m-0">{{$t("request_consult.title")}}</h3>
          </div>

          <div class="modal__form bg-white w-100">
            <p style="font-size: 16px; line-height: 20px">{{$t("request_consult.subtitle")}}</p>

            <FormItem :isError="v1$.phone.$errors.length != 0" :label="$t('request_consult.contact_number')">
              <input
                  v-imask="mask"
                  :value="phone"
                  class="modal__input"
                  placeholder="$t('request_consult.placeholder')"
                  type="text"
                  @accept="phoneAccept"
                  @complete="phoneComplete"
                  @keydown.space.prevent>
            </FormItem>

              <FormItem :show-error-sign="false" :is-error="v1$.message.$errors.length !== 0"
                        :label="$t('request_consult.message')">
                <div class="feedback__textarea mt-2">
                  <textarea v-model.trim="v1$.message.$model" class="form__input" required type="textarea"></textarea>
                  <div class="form-text float-end">
                    {{messageLeft}}
                  </div>
                </div>
              </FormItem>

          </div>


          <div class="d-flex justify-content-center">
            <button :class="{ disabled: v1$.$errors.length != 0 }" class="modal__button text-center" @click="send"
            >{{$t("request_consult.btn_send")}}
            </button>
          </div>
        </template>
        <template v-else>
          <div class="modal__title bg-white w-100">
            <h3 class="p-0 m-0" v-html="$t('request_consult.info_after_filling')"></h3>
          </div>
        </template>
      </Modal>
    </transition>
  </div>
</template>

<script>
import Modal from '@/components/UI/Modal/Modal.vue';
import FormItem from '@/components/Form/FormItem.vue';
import FormError from '@/components/Form/FormError.vue';
import {IMaskDirective} from "vue-imask";
import useVuelidate from "@vuelidate/core";
import {maxLength, minLength, required} from "@vuelidate/validators";
import api from "@/api";

const mustBePhone = (value) => new RegExp("^((8|\\+7|\\+1|\\+3)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$").test(value);
export default {
  components: {
    Modal, FormItem, FormError
  },
  setup() {
    return {
      v1$: useVuelidate()
    }
  },
  validations() {
    return {

      message: {maxLength: maxLength(250)},
      phone: {mustBePhone}
    }
  },

  data() {
    return {
      mask: {
        mask: '+{0}(000)000-00-00',
        lazy: false,  // make placeholder always visible
      },
      phone: '',
      message: '',

      sended: false,
    }
  },
  computed: {

    messageLeft() {
      return (250- this.message.length)
    },

  },
  watch:{
    messageLeft(val) {
      if (val <= 0) this.message = val.slice(0,250)
    },
  },
  props: {
    isShowForm: {
      type: Boolean,
      default: false
    },
    teamId:{
      type: Number,
      required: true,
    },
  },
  directives: {
    imask: IMaskDirective
  },


  methods: {
    phoneComplete(e) {
      const maskRef = e.detail;
      this.phone = maskRef.value
    },
    phoneAccept(e) {
      const maskRef = e.detail;
      this.phone = maskRef.value;
    },
    async send() {
      const result = await this.v1$.$validate()
      if (!result) {
        // notify user form is invalid
        return
      }
      await  api.sendTeamConsultationRequest(this.teamId, this.phone, this.message).then(() => this.sended = true)
    }
  },

}

</script>

<style lang="scss" scoped>

.form {


  &__input {
    margin-top: 7px;
    padding: 10px 5px 10px 20px;
    border: 0.8px solid black;
    border-radius: 5.84534px;
    height: 50px;
    transition: .2s all linear;
    background-color: #fff;

    &:focus {

      outline: none !important;
      border: 0.8px solid black
    }
  }


}

textarea {
  width: 100%;
  min-height: 80px;
  resize: none;
}
.notvalid {
  border: 2px solid red;
}

.modal {

  &__title {
    padding: 20px 20px;
    margin: auto;
    text-align: center;
    border-radius: 15px;
    margin-bottom: 15px;

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
    }
  }

  &__form {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;

    border-radius: 20px;
    margin-bottom: 10px;
    padding: 40px;
  }

  &__input {
    border: 0.922953px solid rgba(28, 29, 34, 0.5);
    border-radius: 8.30658px;
    margin: 15px 0 0;
    padding-left: 10px;

    width: 100%;
    height: 43.38px;


    &:hover,
    &:focus {
      outline: none !important;
      transform: scale(102%);
      border: 0.8px solid black
    }
  }


  &__select {
    background-color: #fff;
    margin-top: 15px;
    width: 100%;
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    height: 43.38px;
    border: 0.922953px solid rgba(28, 29, 34, 0.5);
    border-radius: 8.30658px;

    &:hover,
    &:focus {
      outline: none !important;
      transform: scale(102%);
      border: 0.8px solid black
    }
  }

  &__button {
    background-color: #090D39;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    padding: 15px 60px;
    border-radius: 15px;
    border: none;

    &.disabled {
      background: #8B8B8B;
      cursor: not-allowed;
      filter: drop-shadow(0px 4px 4px rgba(139, 139, 139, 0.7));
    }
  }
}

.error {
  color: #D12937;
  border-color: #D12937;
}
</style>