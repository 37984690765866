<template>
	<div class="drop">
		<div class="tabs">
			<div class="tab">
				<input type="checkbox" id="chck1">
				<label class="tab-label" for="chck1">{{$t("menu_side_bar.companies")}} </label>
				<div class="tab-content">
					<ul>
						<li v-if="isSuperAdmin()" @click="$emit('hideMenu');">
							<div class="before"></div><router-link :class="{ active: isChoosen('/company') }"
								:to="'/company'"> {{$t("menu_side_bar.all_companies")}}</router-link>
						</li>
						<li @click="$emit('hideMenu');" v-for="company in companies">
							<div class="before"></div>
							<router-link :class="{ active: isChoosen('/company/' + company.id) }"
								:to="'/company/' + company.id">{{
									company.name
								}}</router-link>
						</li>

					</ul>
				</div>
			</div>
			<div class="tab">
				<input type="checkbox" id="chck2">
				<label class="tab-label" for="chck2"> {{ $t("menu_side_bar.teams") }} </label>
				<div class="tab-content">
					<ul>
						<li @click="$emit('hideMenu');" v-for="team in teams">
							<div class="before"></div>
							<router-link :class="{ active: isChoosen('/teams/' + team.id) }"
								:to="'/teams/' + team.id">{{
	team.name
								}}</router-link>
						</li>

					</ul>
				</div>
			</div>
<router-link @click="$emit('hideMenu');" v-if="isSuperAdmin()" :class="{ active: isChoosen('/no-teams') }"
                                               :to="'/admin-panel'"> {{ $t("menu_side_bar.admin_access") }}</router-link>

		</div>
	</div>

</template>

<script>
import autoritiesMixin from '@/mixins/autoritiesMixin';
import { mapGetters } from 'vuex';
export default {
	emits: ['hideMenu'],
	mixins: [autoritiesMixin],
	computed: {
		...mapGetters({
			companies: 'AuthModule/getCompanies',
			teams: 'AuthModule/getTeams'
		}),
	},
	methods: {
		isChoosen(route) {
			return this.$route.path == route
		}
	},




}
</script>

<style lang="scss" scoped>
h1 {
	margin: 0;
	line-height: 2;
	text-align: center;
}

h2 {
	margin: 0 0 .5em;
	font-weight: normal;
}

input {
	position: absolute;
	opacity: 0;
	z-index: -1;
}

li {
	cursor: pointer;
}

.drop {
	position: sticky;
	left: 0;
	top: 0;

	padding: 15px;
}

.title {
	display: flex;
	justify-content: space-between;

	font-weight: 700;
	font-size: 30px;
	line-height: 100%;
}

/* Accordion styles */
.tabs {
	border-radius: 8px;
	overflow: hidden;

}

.before {
	width: 15px;
	height: 2px;
	background-color: rgba(28, 29, 34, 0.1);
	display: inline-block;
}

.active {
	background: rgba(28, 29, 34, 0.04);
	border-radius: 11px;
}

.tab {
	width: 100%;
	overflow: hidden;
	color: rgba(28, 29, 34, 0.5);
	margin-bottom: 30px;

	&-label {
		display: flex;
		justify-content: space-between;
		font-weight: bold;

		cursor: pointer;

		/* Icon */
		&:hover {
			color: #1C1D22;
		}
/*content:"\276F"*/
		&::after {
			content: "❯";
			width: 1em;
			height: 1em;
			text-align: center;
			transition: all .35s;
		}
	}

	&-content {
		margin-top: 5px;
		max-height: 0;
		transition: all .35s;
		border-left: 2.06897px solid rgba(28, 29, 34, 0.1);

		ul {
			list-style: none;
			margin: 0;
			padding: 5px 0;
		}

		li {
			margin: 10px 0;
			display: flex;
			align-items: center;
			max-height: 40px;
		}

		a {
			text-decoration: none;
			color: rgba(28, 29, 34, 0.5);
			font-weight: 700;
			font-size: 16.5517px;
			line-height: 100%;
			padding: 10px;
			margin: 15px;

			&:hover,
			&:active {
				color: #1C1D22;
			}

		}
	}

	&-close {
		display: flex;
		justify-content: flex-end;

		font-size: 0.75em;

		cursor: pointer;

		&:hover {
			background-color: #adb5bd;
		}
	}
}

// :checked
input:checked {
	+.tab-label {

		&::after {
			transform: rotate(90deg);
		}
	}

	~.tab-content {
		max-height: 100vh;
	}
}
</style>
