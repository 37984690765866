<template>
  <div class="container">
    <div class="title"><h2 class="h2">С «Профилем Гения»<br class="d-md-none"> вы сможете:</h2></div>

    <div v-for="elem in blockFunctionalInfo">
      <BlockFunctional
          :title="elem.title"
          :sub-title="elem.subtitle"
          :path="elem.path"
          :is-swap-place="elem.isSwapPlace"
          @sendForm="showPopup"
      >
      </BlockFunctional>
    </div>
  </div>

  <v-popup-demo :is-show-pop-up="isShowPopUp" @notShow="showPopup()" :parent-block="nameParentBlock"></v-popup-demo>
</template>

<script>
import BlockFunctional from "@/components/Landings/LandingProfileGenius/LandingWeHelp/BlockFunctional.vue";
import PopupDemo from "@/components/Landings/component/PopupDemo.vue";

export default {
  components: {
    "vPopupDemo":PopupDemo,
    BlockFunctional,
  },
  data() {
    return{
      blockFunctionalInfo: {
        distributeTasksTeam: {
          title: 'Эффективнее распределять задачи в команде',
          subtitle: '«Профиль Гения» покажет типаж каждого участника команды, свойственную ему деятельность, его сильные и слабые стороны. Руководителю на основе результатов тестирования будет проще грамотно распределять задачи. Это уменьшит риск выгорания сотрудников и увеличит общую эффективность работы команды.',
          path: 'distribute-tasks-team',
          isSwapPlace: false
        },
        communicationTeam: {
          title: 'Наладить общение в коллективе',
          subtitle: 'Зная «Профиль Гения» каждого из членов команды, руководитель формирует реальные ожидания от сотрудников, быстро разрешает конфликты внутри коллектива, выстраивает эффективные коммуникации между членами команды.',
          path: 'communication-team',
        isSwapPlace: true
        },
        weedingOutCandidates: {
          title: 'Быстро отсеять неподходящих кандидатов',
          subtitle: 'Если на выложенную вакансию пришло слишком много откликов, «Профиль Гения» поможет отсеять тех, кто точно не подойдет. Кандидат удаленно проходит тестирование, вы видите его профиль и понимаете, стоит ли дальше тратить на него свои ресурсы. Процесс поиска нужного человека становится гораздо быстрее и проще.',
          path: 'weeding-out-candidates',
          isSwapPlace: false
        },
        сompleteTeamIdeal: {
          title: 'Дополнить команду до идеальной',
          subtitle: 'Тестирование «Профиль Гения» покажет командный портрет. Вы увидите кадровые пробелы и поймете, какого типажа не хватает команде для большей эффективности. Вы точно будете знать, кто вам нужен, когда будете искать нового сотрудника.',
          path: 'сomplete-team-ideal',
          isSwapPlace: true
        },
        formNewTeam: {
          title: 'Сформировать новую команду',
          subtitle: 'С помощью предварительного тестирования кандидатов по «Профилю Гения» вы выберете максимально подходящих друг другу людей. Это сократит период адаптации, уменьшит количество конфликтов в коллективе, увеличит результативность будущей команды.',
          path: 'form-new-team',
          isSwapPlace: false
        },


      },
      isShowPopUp: false,
      titleBlock: ''
    }
  },
  methods: {
    showPopup(title) {
      this.titleBlock = title
      this.isShowPopUp = !this.isShowPopUp;
    }
  },
  computed: {
    nameParentBlock() {
      let text = 'Интересующий блок: С «Профилем гения» Вы сможете, Блок: ' + this.titleBlock;
      return text;
    }
  }
}

</script>

<style scoped lang="scss">

.title {
  margin: 0 auto;
  color: #F6F6F5;
  text-align: center;
  padding: 0 20px;
}

@media (min-width: 768px) {
  .title {
    padding: 0 40px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

</style>