<template>
	<CardWrapper @click="isAddingTeam = true" :bg-color="bgColor" :hover-bg-color="hoverBgColor" :text-color="textColor">
		<div>
			<input v-if="isAddingTeam" :class="{ err: isNameError }" v-model.trim="v$.name.$model" type="text" id="name"
				required :placeholder="$t('setting_company.name')">
			<span v-else>{{ $t(addComponent) }}</span>

		</div>
		<div class="position-relative">
			<div v-if="isAnyLoading">
				<div class="spinner-border" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</div>
			<div v-else>
				<svg v-if="isAddingTeam" @click="confirm()"
					:style="{ fill: isNameError ? '#D3D3D3' : '#0A0F3F', cursor: isNameError ? ' default' : 'pointer' }"
					class="add-team-img" width="42" height="42" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
					<circle cx="20.6818" cy="20.6819" r="19.303" stroke-width="2.75758" />
					<path d="M12.8334 20.4999L18.5834 26.2499L28.1667 14.7499" stroke="white" stroke-width="4"
						stroke-linecap="round" stroke-linejoin="round" />
				</svg>
				<img v-else-if="!isAddingTeam" class="add-team-img" src="@/assets/add-team.svg" alt="+">
			</div>

		</div>
	</CardWrapper>
	<FormError v-show="isExists" :msg="$t('setting_company.already_exists')"></FormError>
</template>
<script>
import Loader from '@/components/UI/Loader.vue';
import CardWrapper from '@/components/UI/CardWrapper.vue';
import FormItem from "@/components/Form/FormItem";
import FormError from "@/components/Form/FormError";
import useVuelidate from "@vuelidate/core";
import { helpers, required, maxLength, minLength } from "@vuelidate/validators";
import { mapGetters } from 'vuex';
export default {
	components: { Loader, CardWrapper, FormItem, FormError },
	emits: ['add'],
	data() {
		return {
			isAddingTeam: false,
			name: "",
			bgColor: " #E9E9E9",
			textColor: "#838386",
			hoverBgColor: " white",
		}
	},
	watch: {
		isAddingTeam(val) {
			if (val) {
				this.bgColor = "white";
				this.textColor = "#1C1D22";
				this.hoverBgColor = "white";
			}
		}
	},
	props: {
		isExists: {
			type: Boolean,
			required: true
		},
		addComponent: {
			type: String,
			default: function () {
				return "setting_company.add_team"
			}
		}
	},
	setup() {
		return { v$: useVuelidate() }
	},
	validations() {
		return {
			name: {
				required: helpers.withMessage(this.$t('setting_company.name_required'), required),
				maxLength: helpers.withMessage(this.$t('setting_company.name_less_30'), maxLength(30)),
				minLength: helpers.withMessage(this.$t('setting_company.name_more_2'), minLength(2))
			}
		}
	},
	methods: {
		async confirm() {
			const result = await this.v$.$validate()
			if (!result) {
				return
			}
			if (!this.v$.$silentErrors.length) {
				this.$emit('add', this.name)
				this.isAddingTeam = false;
				this.name = '';
			}
		}
	},
	computed: {
		isNameError() {
			return this.v$.name.$errors.length !== 0
		},
		...mapGetters([
			'isAnyLoading',
		]),
	}
}
</script>

<style scoped>
input {

	border: none;
	max-width: 80%;
	padding-left: 10px;
	font-weight: 700;
	font-size: 20px;
	height: 100%;
}

input:focus {
	outline: none;
}

.add-team {
	display: flex;
	border-radius: 24px;
	font-weight: bold;
	font-size: 22px;
	padding: 20px 30px;
	justify-content: space-between;
	transition: 0.1s all linear;
	background-color: rgba(255, 255, 255, 0.5);
}

.err {
	border-radius: 10px;
	border: 1px solid red;
}

.add-team-img {
	width: 36px;
	height: 36px;
	position: absolute;
	left: -35px;

}

@media (min-width: 576px) {}
</style>
