<template>
  <div v-show="!show" id="loader" class="loader">
    <loader></loader>
  </div>
  <div v-show="show" id="result">
    <div :id="id + 'canvas_bg'" class="result__display">
			<img alt="" :src="imageUrl">
      <canvas :id="id"></canvas>
    </div>
  </div>

</template>

<script>
import typesUtils from '@/mixins/typesUtils'
import Loader from "@/components/UI/Loader.vue";

export default {
  components: {Loader},
  mixins: [typesUtils],
  name: "NewResultDisplay",
  props: {
    resultsProps: {
      type: Object,
      require: true
    },
    id: {
      type: Number,
      require: false,
      default: 0
    }
  },
	computed: {
    imageUrl() {
			const currentLocale = this.$i18n.locale;
			let addres = require(`@/assets/locales/${currentLocale}/results_bg.svg`);
      return addres;
    }
  },

  data() {
    return {
      show: false,
      results: {
        improver: {
          value: this.resultsProps.improver,
          color: this.colorOfType('improver'),
          odds: {
            x1: 0,
            y1: -1,
            x2: -1,
            y2: -1
          }
        },
        creator: {
          value: this.resultsProps.creator,
          color: this.colorOfType('creator'),
          odds: {
            x1: 0,
            y1: -1,
            x2: 1,
            y2: -1
          }
        },
        driver: {
          value: this.resultsProps.driver,
          color: this.colorOfType('driver'),
          odds: {
            x1: 1,
            y1: 0,
            x2: 1,
            y2: -1
          }
        },
        teambuilder: {
          value: this.resultsProps.teambuilder,
          color: this.colorOfType('teambuilder'),
          odds: {
            x1: 1,
            y1: 0,
            x2: 1,
            y2: 1
          }
        },
        networker: {
          value: this.resultsProps.networker,
          color: this.colorOfType('networker'),
          odds: {
            x1: 0,
            y1: 1,
            x2: 1,
            y2: 1
          }
        },
        realizator: {
          value: this.resultsProps.realizator,
          color: this.colorOfType('realizator'),
          odds: {
            x1: 0,
            y1: 1,
            x2: -1,
            y2: 1
          }
        },
        checkman: {
          value: this.resultsProps.checkman,
          color: this.colorOfType('checkman'),
          odds: {
            x1: -1,
            y1: 0,
            x2: -1,
            y2: 1
          }
        },
        analyst: {
          value: this.resultsProps.analyst,
          color: this.colorOfType('analyst'),
          odds: {
            x1: -1,
            y1: 0,
            x2: -1,
            y2: -1
          }
        },
      },
      canvas: null,
      canvasBg: null,
    }
  },


  mounted() {
    this.canvas = document.getElementById(this.id.toString());
    this.canvasBg = document.getElementById(this.id + "canvas_bg");
    const loader = document.getElementById("loader");
    loader.height = loader.width;

    window.addEventListener("resize", this.drawCanvas);
    setTimeout(() => this.show = true, 290)

    setTimeout(() => this.drawCanvas(), 300)

  },


  methods: {
    map(input, max) {
      let maxOutput = 200;
      return Math.round((maxOutput / max) * input)
    }
    ,
    getMaxFromResults() {
      let max = this.results.creator.value;
      for (let resultsKey in this.results) {
        let currentValue = this.results[resultsKey].value
        if (currentValue > max) {
          max = currentValue
        }
      }
      return max
    }
    ,
    getResults() {
      for (let resultsKey in this.results) {
        this.results[resultsKey].value = this.resultsProps[resultsKey]
      }
    },
    drawCanvas() {
      this.getResults();
      let ctx = this.canvas.getContext("2d");
      this.canvas.width = this.canvasBg.clientWidth
      this.canvas.height = this.canvas.width
      ctx.translate(this.canvas.width / 2, this.canvas.height / 2)
      ctx.scale(this.canvas.width * 0.8 / 400, this.canvas.height * 0.8 / 400);


      let max = this.getMaxFromResults()
      for (let resultsKey in this.results) {
        let size = this.map(this.results[resultsKey].value, max)
        ctx.beginPath();
        ctx.fillStyle = this.results[resultsKey].color
        ctx.moveTo(0, 0);
        ctx.lineTo(this.results[resultsKey].odds.x1 * size, this.results[resultsKey].odds.y1 * size);
        ctx.lineTo((this.results[resultsKey].odds.x2 * size * Math.cos(Math.PI / 4)), this.results[resultsKey].odds.y2 * size * Math.sin(Math.PI / 4));
        ctx.fill();
        ctx.closePath();
      }

      for (let resultsKey in this.results) {
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(this.results[resultsKey].odds.x1 * 200, this.results[resultsKey].odds.y1 * 200);
        ctx.moveTo(0, 0);
        ctx.lineTo((this.results[resultsKey].odds.x2 * 200 * Math.cos(Math.PI / 4)), this.results[resultsKey].odds.y2 * 200 * Math.sin(Math.PI / 4));
        ctx.stroke();
      }



      for (let i = 1; i <= 7; i++) {
        for (let resultsKey in this.results) {
          ctx.beginPath();
          ctx.moveTo(this.results[resultsKey].odds.x1 * this.mapGrid(i), this.results[resultsKey].odds.y1 * this.mapGrid(i));
          ctx.lineTo((this.results[resultsKey].odds.x2 * this.mapGrid(i) * Math.cos(Math.PI / 4)), this.results[resultsKey].odds.y2 * this.mapGrid(i) * Math.sin(Math.PI / 4));
          ctx.stroke();
        }
      }
    },

    mapGrid(i) {
      return 200 * i / 8;
    }
  }
  ,
}
</script>

<style scoped>
#loader {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 50px 25px;
  justify-content: center;
  align-items: center;
}

#result {
  width: 100%;
}


.result__display {
  position: relative;
}

.result__display canvas {
  position: absolute;
  left: 0;
  z-index: 1;
}

.result__display img {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 4;
}
</style>
