<template>
  <div id="name" class="d-block">
    <div v-if="!isEditing && !isExists" id="name">
      <h4>{{name}}</h4>
      <img @click="$emit('editing')" v-if="isCanRename" src="@/assets/renameTeam.svg" alt="rename">
    </div>
    <div v-else  id="form">
      <input  v-model.trim="name" @input="v$.name.touch()" type="text" id="name"
              required>
      <button @click="rename">Ок</button>
    </div>

    <p class="error" v-show="isExists" >Уже существует</p>
    <p v-for="err in v$.name.$silentErrors" class="error">{{ err.$message}}</p>
  </div>

</template>

<script>
import useVuelidate from "@vuelidate/core";
import {helpers, maxLength, minLength, required} from "@vuelidate/validators";

export default {
  name: "TeamName",
  setup() {
    return {v$: useVuelidate()}
  },
  validations() {
    return {
      name: {
        required: helpers.withMessage(this.$t('team.name_required'), required),
        maxLength: helpers.withMessage(this.$t('setting_company.name_less_30'), maxLength(30)),
        minLength: helpers.withMessage(this.$t('setting_company.name_more_2'), minLength(2))
     }
    }
  },
  data(){
    return {
    }
  },
  props:{
    name:{
      type:String,
      required:true
    },
    isCanRename:{
      type:Boolean,
      required:true
    },
    isExists:{
      type:Boolean,
      required:true
    },
    isEditing: {
      type:Boolean,
      required:true
    }
  },
  methods:{
    async rename(){

      await this.v$.name.$validate();
      if(this.v$.name.$invalid){
        return;
      }
      this.$emit('rename', this.name);
      this.isRenaming = false;
    }
  },
  computed:{
    isNameError() {
      return this.v$.name.$errors.length !== 0
    },
  }
}
</script>

<style lang="scss" scoped>
.error{
  position: absolute;
  color: red;
  font-size: 12px;
}
#form{
  display:flex;

  align-items:center;
  & button{
    background-color: #0A0F3F;
    color: #fff;
    padding: 3px 10px;
    border-radius: 5px;
    border: none;
    &:active{
      transform: scale(1.05);
    }
  }

}
input {
  background: #E6E6E6;
  border-radius: 9.47142px;
  padding: 3px 10px;
  border:none;
  font-size: 20px;
  font-weight: 700;
  max-width: 200px;
  margin-right: 10px;
}

input:focus {
  outline: none;
}
#name{
  display:flex;

  & h4{
    padding:0;
    margin: 0;
    margin-right: 10px;
    color: #1C1D22;
    font-weight: 700;
    font-size: 26.0535px;
  }
  & img{
    cursor: pointer;
    padding: 0 10px;
  }
}
</style>