<template>
	<div class="position-relative d-flex align-items-center">
		<slot></slot>
		<div class="tooltipContent" :class="{ 'd-block': show , 'largeButton': isLargeButton}" >
			{{ this.content }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		content: {
			type: String,
			required: true
		},
		id: {
			type: String,
			required: true
		},
    isLargeButton: {
      type: Boolean,
      default: false
    }
	},
	data() {
		return {
			show: false,
		}
	},

	mounted() {
		this.createListeners()
	},
	methods: {
		createListeners() {
			let button = document.getElementById(this.id);

			button.addEventListener('pointerover', (e) => { this.show = true; });
			button.addEventListener('pointerout', e => { this.show = false });
		},
		
	}
}
</script>

<style scoped lang="scss">
.tooltipContent {

	display: none;
	padding: 6px 12px;
	text-align: center;
	transition: all .6s ease-in-out;
	width: 100px;
	font-size: 12px;
	position: absolute;
	top: 40px;
	z-index: 9999;
	background: #090D39;
	color: #FFFFFF;
	border-radius: 5px;
}

.largeButton {
  top: 70px;
  left: 20px;
}
</style>