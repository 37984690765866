<template>
	<div class="container">
		<div class="my-3 text-center">
			<h1 class="mb-5">Всем привет!</h1>
			<h3>
				Найди в списке свою команду, кликай по ссылке, регистрируйся и проходи тестирование. </h3>
      <h3>Ждём тебя в четверг, <b>15.11</b> в <b>9:00</b> на вебинаре (<a
          href="https://meet.google.com/kha-hzut-sib">meet.google.com/kha-hzut-sib</a>)</h3>
		</div>
		<div class="w-100 row">
			<div class="col-12 col-md">
				<ul class="main-list ">
					<li>
						Поток 1
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/RXffEnUR">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/AarNZWZZ">Команда 2</a></li>
						</ul>
					</li>
					<li>
						Поток 2
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/ZiaepIyb">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/ZhvuCLJi">Команда 2</a></li>
							<li><a href="http://teamwisdom.ru/t/lCkXwMsf">Команда 3</a></li>
						</ul>
					</li>
					<li>
						Поток 3
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/kEtbnWcS">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/xWJHhmGZ">Команда 2</a></li>
						</ul>
					</li>
					<li>
						Поток 4
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/UgopUCwo">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/jfeMGnJS">Команда 2</a></li>
							<li><a href="http://teamwisdom.ru/t/bxuulqjW">Команда 3</a></li>
						</ul>
					</li>
					<li>
						Поток 5
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/IeglRgPR">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/aATEZAbM">Команда 2</a></li>
						</ul>
					</li>
					<li>
						Поток 6
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/UNtvjiUc">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/AgmxQkEV">Команда 2</a></li>
						</ul>
					</li>
					<li>
						Поток 7
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/FOtZRmhV">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/VaomWEpf">Команда 2</a></li>
							<li><a href="http://teamwisdom.ru/t/pqZaNQxw">Команда 3</a></li>
							<li><a href="http://teamwisdom.ru/t/UCmQNwAW">Команда 4</a></li>
							<li><a href="http://teamwisdom.ru/t/zuMSkNdC">Команда 5</a></li>
						</ul>
					</li>
					<li>
						Поток 8
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/BlFOsaph">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/gghQfJVa">Команда 2</a></li>
							<li><a href="http://teamwisdom.ru/t/mpxBEDdS">Команда 3</a></li>
						</ul>
					</li>
					<li>
						Поток 9
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/rwPVQMxc">Команда 1</a></li>
						</ul>
					</li>
					<li>
						Поток 10
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/YZAsgBcF">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/DAdJgZkb">Команда 2</a></li>
							<li><a href="http://teamwisdom.ru/t/KArcsLLE">Команда 3</a></li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="col-12 col-md">
				<ul class="main-list ">
					<li>
						Поток 11
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/CWNWoeCB">Команда 1</a></li>
						</ul>
					</li>
					<li>
						Поток 12
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/swVQcAeU">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/hNsrEoRU">Команда 2</a></li>
							<li><a href="http://teamwisdom.ru/t/eLZMkFqu">Команда 3</a></li>
						</ul>
					</li>
					<li>
						Поток 13
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/eEbQppVx">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/Aybmbhsy">Команда 2</a></li>
						</ul>
					</li>
					<li>
						Поток 14
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/oouiDYZI">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/MBwoyDkz">Команда 2</a></li>
						</ul>
					</li>
					<li>
						Поток 15
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/DKvoRLtx">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/gOELqGai">Команда 2</a></li>
						</ul>
					</li>
					<li>
						Поток 16
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/MFKSFPSm">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/AAZlJhzh">Команда 2</a></li>
						</ul>
					</li>
					<li>
						Поток 17
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/VDintpgM">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/yJqsBaAA">Команда 2</a></li>
							<li><a href="http://teamwisdom.ru/t/pFutRPSw">Команда 3</a></li>
						</ul>
					</li>
					<li>
						Поток 18
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/kGditkJe">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/prdBWEVc">Команда 2</a></li>
							<li><a href="http://teamwisdom.ru/t/YTISHDap">Команда 3</a></li>
						</ul>
					</li>
					<li>
						Поток 19
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/RVFxiYne">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/RVFxiYne">Команда 2</a></li>
						</ul>
					</li>
					<li>
						Поток 20
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/YICmjgMU">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/rljxHLjs">Команда 2</a></li>
							<li><a href="http://teamwisdom.ru/t/EfQMTeHG">Команда 3</a></li>
							<li><a href="http://teamwisdom.ru/t/RCpkSpuJ">Команда 4</a></li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="col-12 col-md">
				<ul class="main-list">
					<li>
						Поток 21
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/DbHpfjoh">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/cpCvQeiS">Команда 2</a></li>
							<li><a href="http://teamwisdom.ru/t/WCnpHXrZ">Команда 3</a></li>
						</ul>
					</li>
					<li>
						Поток 22
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/EvweYaRR">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/jcTpSTyr">Команда 2</a></li>
							<li><a href="http://teamwisdom.ru/t/gVtYTSMj">Команда 3</a></li>
							<li><a href="http://teamwisdom.ru/t/qFmqusWj">Команда 4</a></li>
						</ul>
					</li>
					<li>
						Поток 23
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/TLUDEUGl">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/SjaTAheH">Команда 2</a></li>
							<li><a href="http://teamwisdom.ru/t/hfvCyFll">Команда 3</a></li>
						</ul>
					</li>
					<li>
						Поток 24
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/LnfEjsQR">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/njCBaUhT">Команда 2</a></li>
							<li><a href="http://teamwisdom.ru/t/JLSocmxO">Команда 3</a></li>
						</ul>
					</li>
					<li>
						Поток 25
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/fMoRbjMP">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/kAvXOfzO">Команда 2</a></li>
						</ul>
					</li>
					<li>
						Поток 26
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/YyJgFpFI">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/TdNBQXfu">Команда 2</a></li>
							<li><a href="http://teamwisdom.ru/t/qPGNNTmL">Команда 3</a></li>
						</ul>
					</li>
					<li>
						Поток 27
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/ihMCMtEG">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/cDGRDpIs">Команда 2</a></li>
							<li><a href="http://teamwisdom.ru/t/yWjMqIup">Команда 3</a></li>
						</ul>
					</li>
					<li>
						Поток 28
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/IjQUtzPl">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/XECdNnOz">Команда 2</a></li>
							<li><a href="http://teamwisdom.ru/t/RLrOqCeN">Команда 3</a></li>
						</ul>
					</li>
					<li>
						Поток 29
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/bosvWTGS">Команда 1</a></li>
							<li><a href="http://teamwisdom.ru/t/nYimyseJ">Команда 2</a></li>
						</ul>
					</li>
					<li>
						Поток 30
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/GhqkmTiD">Команда 1</a></li>
						</ul>
					</li>
					<li>
						Поток 31
						<ul class="sub-list">
							<li><a href="http://teamwisdom.ru/t/EOkrVmem">Команда 1</a></li>
						</ul>
					</li>
				</ul>
			</div>

		</div>
	</div>
</template>

<script>

import Error from '@/components/UI/Error.vue'

export default {
	components: { Error }


}
</script>

<style scoped>
.main-list {
	list-style-type: none;
	font-size: 18px;
	
	padding-bottom: 20px;
}

.sub-list {
	list-style-type: none;
	padding-left: 20px;
	margin-bottom: 15px;
	font-size: 16px;
}
</style>
