<template>
  <div >
    <div class="modal__title bg-white w-100">
      <h4 class="p-0 m-0">Отзыв на опрос "Профиль Гения"</h4>
    </div>
    <div class="modal__subtitle bg-white w-100">
      <div class="form-text">
        * - поля обязательные для заполнения
      </div>
      <form>
        <div class="feedback__item">
          <FormItem :show-error-sign="false" :is-error="v$.form.estimation.$errors.length !== 0" :label="'1. Как вы оцениваете опрос &quot;Профиль Гения&quot; в целом?*'">
            <div class="feedback__radio">
              <div v-for="index in 10" class="feedback__radio__item">
                <input :id="index" v-model="v$.form.estimation.$model" :value="index" class="form-check-input" type="radio">
                <br>
                <label :for="index">{{ index }}</label>
              </div>
            </div>
          </FormItem>
        </div>
        <div class="feedback__item">
          <FormItem :show-error-sign="false" :is-error="v$.form.difficulties.$errors.length !== 0" :label="'2. Были ли у вас какие-либо трудности в процессе прохождения опроса?*'">
            <div  class="feedback__radio true-false">
              <div class="feedback__radio__item">
                <input id="Да" v-model="form.difficulties" class="form-check-input" type="radio" value="true">
                <label for="Да">Да</label>
              </div>
              <div class="feedback__radio__item">
                <input id="Нет" v-model="v$.form.difficulties.$model" class="form-check-input" type="radio" value="false">
                <label for="Нет">Нет</label>
              </div>

            </div>
          </FormItem>
        </div>
        <div class="feedback__item">
          <FormItem :show-error-sign="false" :is-error="v$.form.improvements.$errors.length !== 0"
                    :label="'3. Какие изменения вы бы предложили, чтобы улучшить качество опроса в будущем?*'">
            <div class="feedback__textarea mt-2">
              <textarea v-model.trim="v$.form.improvements.$model" class="form__input" required type="textarea"></textarea>
              <div class="form-text float-end">
                {{improvmentsLeft}}
              </div>
            </div>
          </FormItem>
        </div>
        <div class="feedback__item">
          <FormItem :show-error-sign="false" :is-error="v$.form.agreement.$errors.length !== 0" :label="'4. Насколько вы согласны с полученным результатом опроса?*'">
            <div class="feedback__radio">
              <div v-for="index in 10" class="feedback__radio__item">
                <input :id="index" v-model="v$.form.agreement.$model" :value="index" class="form-check-input" type="radio">
                <br>
                <label :for="index">{{ index }}</label>
              </div>
            </div>
          </FormItem>
        </div>
        <div class="feedback__item">
          <FormItem :show-error-sign="false" :is-error="v$.form.useful.$errors.length !== 0" :label="'5. Оцените, на сколько информация была полезна для вас?*'">
            <div class="feedback__radio">
              <div v-for="index in 10" class="feedback__radio__item">
                <input :id="index" v-model="v$.form.useful.$model" :value="index" class="form-check-input" type="radio">
                <br>
                <label :for="index">{{ index }}</label>
              </div>
            </div>
          </FormItem>
        </div>
        <div class="feedback__item">
          <FormItem :show-error-sign="false" :is-error="v$.form.likeAndMissing.$errors.length !== 0"
                    :label="'6. Расскажите, что вам понравилось, а чего не хватило в результате?*'">
            <div class="feedback__textarea mt-2">
              <textarea v-model.trim="v$.form.likeAndMissing.$model" class="form__input" required type="textarea"></textarea>
              <div class="form-text float-end">
                {{likeAndMissingLeft}}
              </div>
            </div>
          </FormItem>
        </div>
        <div class="feedback__item">
          <FormItem :show-error-sign="false" :is-error="v$.form.goals.values.$errors.length !== 0"
                    :label="'7. Отметьте, для каких целей вы использовали бы данный опрос (можно выбрать несколько вариантов)*:  '">
            <div class="mt-2"></div>
            <div v-for="goal in goals.split('.')" class="form-check mt-1">
              <input :id="goal" v-model="v$.form.goals.values.$model" :value="goal" class="form-check-input" type="checkbox">
              <label :for="goal" class="form-check-label">{{ goal }}</label>
            </div>
            <div class="form-check">
              <input @input="focusOnOtherInput" ref="check" id="Other" v-model="v$.form.goals.values.$model" class="form-check-input mt-1" type="checkbox" value="Other">
              <label class="form-check-label" for="Other">Другое</label>
              <input ref="checkOtherInput" @focusin="focusOtherInput" @focusout="focusOutOtherInput" class="other-input" type="text" v-model.trim="form.goals.otherValue">
            </div>

          </FormItem>
        </div>
        <div class="feedback__item">
          <FormItem :show-error-sign="false" :is-error="v$.form.source.$errors.length !== 0" :label="'8. Откуда вы узнали о нас?*'">
            <select v-model="v$.form.source.$model" class="form__input">
              <option disabled value="">-- выберите --</option>
              <option v-for="source in sources.split('.')" :value="source">{{ source }}</option>
            </select>
          </FormItem>
        </div>
        <div class="feedback__item">
          <FormItem :show-error-sign="false" :is-error="v$.form.recommendation.$errors.length !== 0" :label="'9.  Насколько вероятно, что вы порекомендуете нас друзьям или коллегам?*'">
            <div class="feedback__radio">
              <div v-for="index in 10" class="feedback__radio__item">
                <input :id="index" v-model="v$.form.recommendation.$model" :value="index" class="form-check-input" type="radio">
                <br>
                <label :for="index">{{ index }}</label>
              </div>
            </div>
          </FormItem>
        </div>
      </form>
    </div>
  </div>

  <button @click="send" class="button mt-3">Отправить</button>

</template>

<script>
import Modal from "@/components/UI/Modal/Modal.vue";
import FormItem from "@/components/Form/FormItem.vue";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import api from "@/api";
import { toRaw } from 'vue';
import {mapActions} from "vuex";

export default {
  name: "TalentFeedbackModal",
  emits:['sended'],
  components: {FormItem, Modal},
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      goals: 'Выстраивание работы в текущей команде. Развитие сотрудников. Формирование эффективных команд с нуля. Принятие кадровых решений. Автоматизация процесса подбора. Понимание и повышение личных навыков. Профориентация',
      sources: 'На конференции. Реклама в интернете. От знакомых. Направили на работе. Другое',
      form: {
        estimation: null,
        improvements: '',
        difficulties: null,
        agreement: null,
        useful: null,
        likeAndMissing: '',
        recommendation: null,
        goals: {
          values: [],
          otherValue: '',
        },
        source: '',
      }
    }
  },
  computed: {
    otherValue(){
      return this.form.goals.otherValue
    },
    improvmentsLeft() {
      return (500 - this.form.improvements.length)
    },
    likeAndMissingLeft() {
      return (500 - this.form.likeAndMissing.length)
    },
  },
  watch:{
    otherValue(val){
      if(val.length > 30){
        this.form.goals.otherValue =  val.slice(0,30);
      }
    },
    improvmentsLeft(val) {
      if (val <= 0) this.form.improvements = this.form.improvements.slice(0,500)
    },
    likeAndMissingLeft(val) {
      if (val <= 0) this.form.likeAndMissing = this.form.likeAndMissing.slice(0,500)
    }
  },
  methods:{
    async send(){
      this.form.goals.values =  this.form.goals.values.filter(value => value !== 'Other');
      const result = await this.v$.$validate()
      if (!result) {
        // notify user form is invalid
        return
      }
      this.form.goals = this.form.goals.values;
      this.$emit("sended");
      this.sendTalentFeedback({data:this.form})
    },
    ...mapActions({
      sendTalentFeedback: 'AuthModule/onSendTalentFeedback'
    }),
    focusOnOtherInput(e){
      if(e.target.checked){
        this.$refs.check.click();
        this.$refs.checkOtherInput.focus()
      } else {

        this.form.goals.values =  this.form.goals.values.filter(value => value !== 'Other').filter(v => v!==this.form.goals.otherValue);
        this.form.goals.otherValue = '';
      }
    },
    focusOutOtherInput(){
      if(this.form.goals.otherValue){
        this.form.goals.values.push(this.form.goals.otherValue);
      } else {
        this.form.goals.values =  this.form.goals.values.filter(value => value !== 'Other');
      }

    },
    focusOtherInput(){
      if(!this.$refs.check.checked)
         this.$refs.check.click()
      let current = this.form.goals.otherValue
      this.form.goals.values =  this.form.goals.values.filter(value => value !== current );

    }
  },
  validations () {
    return {
     form:{
       improvements: {required},
       estimation: {required},
       difficulties: {required},
       likeAndMissing: {required},
       agreement: {required},
       useful: {required},
       recommendation: {required},
       goals: {
         values: {required}
       },
       source:  {required}
     }
    }
  }

}
</script>

<style lang="scss" scoped>
.button {
  background: #090D39;
  border-radius: 10px;
  border: none;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  padding: 15px 60px;
  margin: 15 auto 0;

}
.other-input{
  margin-left: 10px;
  border:none;
  border-bottom: 1px solid rgba(44, 41, 41, 0.45);

  transition: all 0.2s ease-in-out;
  padding-left: 5px;
  &:focus{
    outline: none;
    border-bottom: 1px solid black;

  }

}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
  opacity: 0;
}

.form-check-input:checked {
  background-color: #0A0F3F;
  border-color: #0A0F3F;;
}

.feedback {
  &__textarea {
    & textarea {
      width: 100%;
      min-height: 80px;
      resize: none;
    }
  }

  &__radio {
    display: flex;
    justify-content: space-evenly;
    margin: 5px 10px;
    &.true-false{
      display: block;
      & .feedback__radio__item{
        text-align: left;
        margin-top: 5px ;
        & label{
          margin-left: 10px;
        }
      }
    }
    &__item {
      text-align: center;

      & label {
        margin-top: 2px;
      }
    }
  }

  &__item {
    margin-top: 10px;
  }

  &__checkbox {

    & label {
      margin-left: 10px;
      margin-top: 5px;
    }
  }
}


.form {
  width: 100%;
  margin: 35px;

  &__input {
    margin-top: 7px;
    padding: 10px 5px 10px 20px;
    border: 0.8px solid black;
    border-radius: 5.84534px;
    height: 50px;
    transition: .2s all linear;
    background-color: #fff;

    &:focus {

      outline: none !important;
      border: 0.8px solid black
    }
  }


}

.modal {
  &__title {
    padding: 7px 0;
    margin: auto;
    font-weight: 700;
    font-size: 13px;
    line-height: 39px;
    text-align: center;
    border-radius: 20px;
    margin-bottom: 10px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    padding: 10px 20px;
    border-radius: 20px;
    text-align: justify;
    margin-bottom: 10px;
  }

  &__button {
    background-color: #090D39;
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    line-height: 21px;
    padding: 7px 60px;
    border-radius: 15px;
    border: none;
  }
}

@media (min-width: 445px) {
  .modal__subtitle {
    font-size: 12px;
    line-height: 17px;
    padding: 20px 40px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 768px) {

  .modal {
    &__title {
      font-size: 24px;
      padding: 15px 0;
      margin-bottom: 15px;
    }

    &__subtitle {
      font-size: 14px;
      line-height: 21px;
      padding: 20px 50px;
      margin-bottom: 15px;
    }

    &__button {
      font-size: 18px;
      padding: 15px 80px;
    }
  }
}


</style>
