<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container" :class="{ active: isTransparentBackground }">

        <div class="header">
          <h3 v-show="!isTransparentBackground && !isError" v-html="title"></h3>
          <h3 v-show="isError" style="color: red">Ошибка!</h3>
          <img v-if="!isTransparentBackground" src="../../../assets/closeModal.svg" @click="$emit('close')">
          <img v-else src="../../../assets/closeModalWhite.svg" @click="$emit('close')">
        </div>


        <div class="modal-body">
          <slot>

          </slot>
        </div>

        <!-- <div class="modal-footer">
          <slot name="footer">
            default foote
          </slot>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
	props: {
		title: {
			type: String,
			required: false
		},
		isTransparentBackground: {
			type: Boolean,
			default: false
		},
    isError: {
      type: Boolean,
      default: false
    }
	},
}
</script>
<style lang="scss" scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header h3 {
	margin: 0;

	font-weight: 700;
	font-size: 16px;
	line-height: 106%;
	color: #000000;
}

.header img {
  margin-left: 15px;

	width: 16px;
	height: 16px;
	cursor: pointer;

	&:hover {
		transform: scale(1.08);
	}
}

.modal-body {}

.modal-mask {
  overflow-y: auto;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	transition: opacity 0.3s ease;
}

.modal-container {
	margin: 0px auto;
  min-width: 360px;
	width: 100%;
	padding: 30px 20px;
	background: #FFFFFF;
	border-radius: 30.6266px;
	transition: all 0.3s ease;
  //overflow-y: auto;
}

.active {
	background: rgba(0, 0, 0, 0);
	padding: 20px 5px;

	.header {
		justify-content: flex-end;
		margin-bottom: 5px;
	}
}

.modal-wrapper {

	display: table-cell;
	vertical-align: middle;
}

.modal-enter-from,
.modal-leave-to {
	opacity: 0;
}



@media (min-width: 576px) {
	.modal-header h3 {
		font-size: 20px;
	}

	.active {
		align-items: center;
		padding: 40px 33px;
	}
}

@media (min-width: 768px) {

	.modal-container {
		margin: 0px auto;
    padding: 40px 30px;
		width: 600px;
	}

	.active {
		min-width: 750px;
		.header {
			img {
				margin-right: -15px;
			}

		}
	}
}

@media (min-width: 992px){
  .modal-container {
    //padding: 40px 100px;
  }
}
</style>
