<template>

    <div v-if="isTokenLoading" class="loading">
        <span aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></span>
        <span aria-hidden="true" class="spinner-grow spinner-grow-sm mx-2 " role="status"></span>
        <span aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></span>
    </div>
    <div v-else>
        <div v-if="isTokenExists" class="alert  exists" role="alert">
					<span v-html="$t('popup_invitation.inivition', {name_company:tokenData.company})"></span>
            <!--Вы приглашены в компанию <b>{{ tokenData.company }}</b> -->
            <span v-if="tokenData.admin">{{ $t("popup_invitation.invitations_for_admin") }}</span>
            <span v-else v-html="$t('popup_invitation.invitation_for_user', {name_team: tokenData.team})"></span>
        </div>
        <div v-else class="alert alert-danger text-center" role="alert">
            {{ $t("popup_invitation.invitation_not_exist") }}
        </div>
    </div>
</template>

<script>
import api from "@/api";
export default {
    name: "FormTokenDescription",
    props: {
        token: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            tokenData: "",
            isTokenExists: true,
            isTokenLoading: true
        }
    },

    mounted() {
        api.getTokenDesctiption(this.token)
            .then(
                r => {
                    this.tokenData = r.data;
                    this.isTokenLoading = false;
                }
            ).catch(err => {
                this.isTokenExists = false;
                this.tokenData = err.response.data.message
                this.isTokenLoading = false;
            })
    },

}

</script>

<style lang="scss" scoped>
.exists{
    background-color: #E6E6E6;
    color: #2C2C2C;
    text-align: center;
}
.alert{
    border-radius: 20px !important;
}
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
