<template>
  <div class="card">
    <div class="card__img">
      <img :src="imageUrl" alt="iconKPI">
    </div>
    <div class="card__title" v-html="title"></div>
  </div>

</template>

<script>
export default {
  props: {
    path: {
      type: String,
      reqiured: true
    },
    title: {
      type: String,
      reqiured: true
    }
  },
  computed: {
    imageUrl() {
      let addres = this.path;
      return require(`@/assets/landing-profile-genius/KPI/${addres}.svg`);
    }
  },
}

</script>

<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0);
  width: 200px;
  margin: 30px 20px;

  &img {
    img{
      max-width: 70px;
    }
  }

  &__title {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 400;
  }
}

@media (min-width: 768px){
  .card {
    width: 235px;
    margin: 30px 60px;


  }
}
</style>