<template>
  <div class="block" :class="{'swapPlaces': isSwapPlace}">
    <div class="block__info">
        <h4>{{ title }}</h4>


        <p>{{ subTitle }}</p>

        <ButtonDemo :title="'Заказать демо'" class="block__button" @click="sendForm"></ButtonDemo>

    </div>

    <div class="block__img">
      <img :src="imageUrl" alt="img_functional">
    </div>

  </div>


</template>

<script>
import ButtonDemo from "@/components/Landings/component/ButtonDemo.vue";
export default {
  components: {
    ButtonDemo
  },

  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    isSwapPlace: {
      type:Boolean,
      default: false
    }
  },
  computed: {
    imageUrl() {
      let addres = this.path;
      return require(`@/assets/landing-profile-genius/functional/${addres}.png`);
    }
  },
  methods: {
    sendForm() {
      this.$emit('sendForm', this.title)
    }
  }
}

</script>

<style scoped lang="scss">


.block {
  margin-top: 100px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  color: #F6F6F5;
  padding: 0;

  &__info {
    max-width: 500px;

    h4 {
      font-size: 28px;
      font-weight: 700;
      line-height: 30px;
      text-align: left;
      margin-bottom: 30px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      text-align: left;
      margin-bottom: 30px;
    }

  }

  &__img {
    margin-right: 50px;
    margin-left: 50px;
    margin-bottom: 30px;

    img {
      max-height: 250px;
    }
  }

  &__button {
    width: 100%;
  }
}

.swapPlaces{
  .block{
    flex-direction: column-reverse;

    &__info {
      max-width: 500px;
      order: 1;
    }

    &__img {
      order: 2;
      margin-right: 50px;
      margin-left: 50px;
      margin-bottom: 30px;
    }
  }
}

@media (min-width: 768px) {

  .block {
    flex-direction: row;
    align-items: flex-start;

    &__info {
      //max-width: 450px;
    }

    &__img {
      img {
        max-height: 200px;
      }
    }

    &__button {
      width: auto;
    }
  }

  .swapPlaces{
    .block{
      flex-direction: row-reverse;

      &__info {

        order: 2;
      }

      &__img {
        order: 1;
      }
    }
  }
}


@media (min-width: 992px) {
  .block {
    align-items: center;
    &__info {

    }

    &__img {
      margin-right: 150px;
      margin-left: 50px;

      img {
        max-height: 250px;
      }
    }
  }

  .swapPlaces{
    .block{
      &__info {

      }

      &__img {
        margin-right: 100px;
        margin-left: 100px;
      }
    }
  }
}


@media (min-width: 1200px) {
  .block {
    justify-content: space-around;
  }

  .swapPlaces{
    .block{
      justify-content: space-around;
    }
  }
}

@media (min-width: 1400px) {

}

</style>