<template>
  <div class="container blockPlans" id="#plans">
    <div class="blockPlans__title">
      <h2>{{ $t('plans.title') }}</h2>
    </div>
    <div class="blockPlans__plans">
      <div v-for="elem in plansInfo" class="row">
        <PlansContainer
            :basic-info="elem.basicInfo"
            :title="elem.title"
            :discount-price="elem.discountPrice"
            :basic-price="elem.basicPrice"
            :sale-info="elem.saleInfo"
            :is-basic-plans="elem.isBasicPlans"

            @sendForm="showPopup"
        ></PlansContainer>
      </div>
    </div>
  </div>

  <PopupPlans :is-show-pop-up="isShowPopUp" @notShow="showPopup()" :parent-block="nameParentBlock"></PopupPlans>
</template>

<script>
import ButtonDemo from "@/components/Landings/component/ButtonDemo.vue";
import List from "@/components/Landings/component/List.vue";
import PlansContainer from "@/components/Landings/LandingPlans/PlansContainer.vue";
import PopupPlans from "@/components/Landings/component/PopupPlans.vue";
import {useI18n} from "vue-i18n";

export default {
  components: {
    ButtonDemo,
    List,
    PlansContainer,
    PopupPlans
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      plansInfo: {
        basicPlan: {
          title: this.$t('plans.basic_title'),
          basicPrice: this.$t('plans.basic_price'),
          discountPrice:this.$t('plans.price_0'),
          saleInfo: this.$t('plans.basic_promocode'),
          isBasicPlans: true,
          basicInfo: [
            // 'plans.basic_number_accesses',
            'plans.basci_testing',
            'plans.basic_automatic_tips',
            'plans.basic_knowledge_base'
          ]
        },
        standartPlan: {
          title: this.$t('plans.standard_title'),
          basicPrice: this.$t('plans.standard_price_without_discount'),
          discountPrice: this.$t('plans.standard_price'),
          saleInfo: this.$t('plans.standard_price_employee'),
          isBasicPlans: false,
          basicInfo: [
            'plans.standard_number_teams',
            'plans.standard_testing',
            'plans.standard_automatic_tips',
            'plans.standard_knowledge_base',
            'plans.standard_add_testing',
            'plans.standard_2_consultations'
          ]
        },
        premiumPlan: {
          title: this.$t('plans.individual_title'),
          basicPrice: '',
          discountPrice:'',
          saleInfo: this.$t('plans.individual_price'),
          isBasicPlans: false,
          basicInfo: [
            'plans.individual_number_teams',
            'plans.individual_testing',
            'plans.individual_automatic_tips',
            'plans.individual_knowledge_base',
            'plans.individual_add_testing',
            'plans.individual_personal_consultations',
            'plans.individual_executive_training',
            'plans.individual_access_candidate_database',
            'plans.individual_company_introduction'
          ]
        }
      },
      isShowPopUp: false,
      titleBlock: ''
    }
  },
  methods: {
    showPopup(title) {
      this.titleBlock = title
      this.isShowPopUp = !this.isShowPopUp;
    }
  },
  computed: {
    nameParentBlock() {
      let text = 'Тариф:  ' + this.titleBlock;
      return text;
    }
  }
}

</script>

<style scoped lang="scss">

.blockPlans {
  display: flex;
  flex-direction: column;


  &__title {
    align-self: center;
    margin-bottom: 60px;
  }

  &__plans {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

@media (min-width: 992px) {
  .blockPlans {
    &__plans {
      flex-direction: row;
    }
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}
</style>