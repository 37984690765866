<template>
  <div class="user">
    <div>
      <div class="user-name">
        <h5>{{ firstName }} <br> {{ lastName }}</h5>
      </div>
      <span class="user-position">{{ position || $t('team.role_undefined') }}</span>
    </div>
    <div class="user-photo position-relative" >
      <div class="user-control lead"  v-if="canManageRole && isEditing || isTeamLead">
        <img class="user-team-lead" @click="$emit('changeRole')"  v-if="isTeamLead || isEditing " :class="{editing : isEditing && !isTeamLead}" alt="star" src="@/assets/userIsTeamLeader.svg">
      </div>
      <div class="user-control delete  " v-if="isEditing">
        <img @click="$emit('remove')" class="user-delete "  alt="star" src="@/assets/team/removeUser.svg">
      </div>
      <div class="bg-white p-2 rounded-4" @click="$emit('open')" v-if="talent">
      <NewResultDisplay :id="id" :results-props="talent" />

      </div>
      <img v-else @click="$emit('open')" alt="Photo" class="user-photo" src="@/assets/user.png">
    </div>
    <div class="user-result">
      <h6>{{ result }}</h6>
      <img v-if="isVerified" alt="confirmation" draggable="false" src="@/assets/userIsConfirmResult.svg">
    </div>
  </div>
</template>

<script>


import NewResultDisplay from "@/components/User/TalentResultDisplay.vue";
import {required} from "@vuelidate/validators";

export default {
  components: {NewResultDisplay},
  emits: ['open','remove', 'changeRole'],
  props: {
    id:{
      type: Number,
      required: false
    },
    canManageRole:{
      type: Boolean,
      required: false,
    },
    firstName: {
      type: String,
      require: true
    },
    lastName: {
      type: String,
      require: true
    },
    result: {
      type: String,
      require: true
    },
    talent: {
      type: Object,
      require: false
    },
    role: {
      type: String,
      require: false
    },
    position: {
      type: String,
      require: false
    },
    isVerified: {
      type: Boolean,
      require: true
    },
    isEditing: {
      type: Boolean,
      require: true
    }
  },
  computed:{
    isTeamLead(){
      return this.role === 'ROLE_TEAM_LEAD';
    }
  }

}
</script>

<style lang="scss" scoped>
.user {

  max-width: 100px;

  display: flex;
  flex-direction: column;
  text-align: center;
  &-control{
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fffefe;
    border: 1px solid rgba(192, 188, 188, 0.95);
    top: -2%;

    &.lead{
      left: -5%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.delete{
      right: -5%;
    }
  }
  &-delete{
    cursor: pointer;
    width:20px;
  }
  &-team-lead{
    width:20px;
    &.editing{
      opacity:30%;
      &:hover{
        opacity:75%;
      }
    }
  }
  &-name {
    display: flex;
    align-items: center;
    position: relative;
    & h5 {
      flex-grow: 1;
      margin: 0 auto;
    }

    & img {
      width: 20px;
      height: 20px;
      align-self: center;
    }
  }

  &-position {
    font-size: 10px;
    text-transform: uppercase;
  }

  &-name h5 {
    font-weight: 700;
    font-size: 13.7732px;
    line-height: 15px;
    margin: 0;
  }

  &-role {
    font-weight: 400;
    font-size: 9.74118px;
    line-height: 9px;
    text-transform: uppercase;
  }

  &-photo {
    max-width:100%;
    cursor: pointer;
    margin: 6px 0px 9px;
    border-radius: 15px;
  }

  &-result {

    background-color: #fff;
    padding: 5px 0;
    border-radius: 10.9074px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-result h6 {
    font-weight: 700;
    font-size: 8px;
    line-height: 16px;
    vertical-align: middle;
    margin: 0;
    margin-right: 10px;
    padding: 0;
  }

  &-result img {
    margin: 0;
    padding: 3px 3px;
    background-color: #F4F4F4;
    border-radius: 50%;
    pointer-events: none
  }
}

@media (min-width: 576px) {
  .user {
    max-width: 140px;

    &-result h6 {

      font-size: 11.7732px;

    }

    &-result {
      padding: 10px 0;
      border-radius: 14.9074px;

    }

  }
}
</style>

