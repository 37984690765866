<template>
  <div class="planDescription">
    <div class="paragraph" v-for="elem in listInfo">
      <img alt="white-checkmark" :src="imageUrl">
      <p v-html="$t(elem)"></p>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    listInfo: {
      type: Array,
      requires: true
    },
    path: {
      type: String,
      required:true
    },
  },
  computed: {
    imageUrl() {
      let addres = this.path;
      return require(`@/assets/landing-profile-genius/${addres}.svg`);
    }
  },
}

</script>

<style scoped lang="scss">

.planDescription {
  max-width: 600px;
}

.paragraph {
  margin-bottom: 20px;

  p {
    text-align: left;
    margin-left: 30px;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
  }

  img {
    float: left;
  }
}



</style>