<template>
<a href="/" class="logo">
    <img src="@/assets/brains.svg" alt="">
			Team Wisdom
		</a>
</template>
<script>
export default{
    name : 'AppLogo',
  computed:{
    mainLink(){
      return window.host
    }
  }
}
</script>
<style lang="scss" scoped>
.logo {
	cursor: pointer;
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 24.0642px;
	line-height: 32px;
  color: white;
  text-decoration: none;
}
.logo img{
	margin-right:  10px;
	max-height: 25px;
}
</style>
