<template>
  <div v-if="isAnyLoading" class="block__loader">
    <loader></loader>
  </div>
  <div class="w-100" v-else>
    <div v-if="teams.length || getAdminCompanies.length">
      <CompaniesAndTeamsWrapper :title="$t('setting_company.list_team')">
        <div v-for="team in teams" class="item">
          <TeamCard :name="team.name" :count="team.count" @click="this.$router.push('/teams/' + team.id)">
          </TeamCard>
        </div>
      </CompaniesAndTeamsWrapper>
    </div>
    <div v-else class="registration container">
      <div class="registration__header d-flex flex-column flex-md-row w-100">
        <div class="registration__title" v-html="$t('company_registration_page.top_header')">
				</div>
        <button @click="isShowPopUp = true" class="registration__button">
          {{$t("company_registration_page.btn_register")}}
        </button>
      </div>

      <hr>
      <div class="quote bg-white d-flex flex-column">
        <div class="w-60 justify-content-end">
          <h5 class="quote__text">{{$t("company_registration_page.quote_Jackson")}}</h5>
          <h4 class="quote__title">{{$t("company_registration_page.name_Jackson")}}</h4>
        </div>

        <div class="quote__main w-80">
					{{ $t("company_registration_page.description_team_wisdom") }}
        </div>


      </div>

      <div class="main">
        <h4 class="registration__title text-center">{{ $t("company_registration_page.title_find_profile") }}</h4>

        <div class="d-lg-flex pt-0 mt-0 pb-0 justify-content-center">
          <div class="d-flex px-0 pb-0 justify-content-center mx-lg-4">
            <LandingHelpCard :img-u-r-l="require('@/assets/help/discover.png')"
                             :text="$t('company_registration_page.card_open_strong_strings')"
                             :isSmallVersion="true"></LandingHelpCard>
          </div>
          <div class="d-flex px-0 pb-0 my-0 my-lg-0  justify-content-center mx-lg-4">
            <LandingHelpCard :img-u-r-l="require('@/assets/help/create.png')"
                             :text="$t('company_registration_page.card_build_team')" :isSmallVersion="true">
            </LandingHelpCard>
          </div>
        </div>

        <div class="d-lg-flex pb-0 my-lg-4 pt-0 justify-content-center">
          <div class="d-flex px-0 pb-0 justify-content-center mx-lg-4">
            <LandingHelpCard :img-u-r-l="require('@/assets/help/build.png')"
                             :text="$t('company_registration_page.card_personnel_decisions')"
                             :isSmallVersion="true"></LandingHelpCard>
          </div>
          <div class="d-flex px-0 pb-0 my-0 my-lg-0  justify-content-center mx-lg-4">
            <LandingHelpCard :img-u-r-l="require('@/assets/help/inspire.png')"
                             :text="$t('company_registration_page.card_effectiveness')"
                             :isSmallVersion="true"></LandingHelpCard>
          </div>
        </div>
      </div>

      <hr>

      <div class="registration__header d-flex flex-column flex-md-row">
        <div class="registration__title" v-html="$t('company_registration_page.bottom_header')">
        </div>
        <button @click="isShowPopUp = true" class="registration__button">
          {{$t("company_registration_page.btn_join")}}
        </button>
      </div>
    </div>
  </div>

  <PopUpRegistration :is-show-form="isShowPopUp" @notShow="isShowPopUp = false"/>

</template>

<script>
import api from '@/api'
import CompaniesAndTeamsWrapper from '@/components/UI/CardsBlock.vue';
import Loader from '@/components/UI/Loader.vue';
import TeamCard from '@/components/Team/TeamCard.vue';
import LandingHelpCard from '@/components/Landing old/LandingHelpCard.vue';
import {mapGetters} from 'vuex';
import PopUpRegistration from '@/components/Company/Pop-upRegistration.vue';
import PopUpAddRegistration from '@/components/Company/Pop-upAddRegistrations.vue'


export default {
  data() {
    return {
      teams: [],
      isShowPopUp: false,
      isShowAddReg: false
    }
  },
  computed: {
    ...mapGetters({
      isAnyLoading: 'isAnyLoading',
      getTeams: 'AuthModule/getTeams',
      getAdminCompanies: 'AuthModule/getAdminCompanies'
    }),

  },
  components: {LandingHelpCard, Loader, CompaniesAndTeamsWrapper, TeamCard, PopUpRegistration, PopUpAddRegistration},


  beforeRouteUpdate(to, from) {
    this.isShowPopUp = to.query.register || false;
  },


  beforeMount() {
    if (this.getAdminCompanies.length !== 0) {
      if (this.getAdminCompanies.length === 1) {
        this.$router.push('/company/' + this.getAdminCompanies[0])
      } else {
        this.$router.push('/company')
      }
    } else {
      if (this.getTeams.length === 1) {
        this.$router.push('/teams/' + this.getTeams[0].id)
      } else {
        this.teams = this.getTeams;
      }
    }


  },
  mounted() {
    this.isShowPopUp = this.$route.query.register || false;
    console.log(this.isShowPopUp)
  }

}
</script>

<style lang="scss" scoped>
.item {
  margin: 10px;
}

.block__loader {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.registration {

  &__header {
    background: #090D39;
    border-radius: 25px;
    padding: 25px;
    margin: 20px 0;
    color: #FFFFFF;
    text-align: center;
    justify-content: space-around;
    align-items: center;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    padding: 0;
    margin: 0 0 15px;
  }

  &__button {
    background: #FFFFFF;
    color: #000000;
    border-radius: 12px;
    font-weight: 700;
    font-size: 16px;
    padding: 10px 70px;
    border: none;

    &:hover {
      transform: scale(102%);
    }
  }
}

.quote {
  padding: 25px;
  color: #090D39;
  margin-top: 20px;
  border-radius: 25px;
  text-align: right;
  font-style: italic;

  &__text {
    font-weight: 100;
    font-size: 14px;
    margin: 0;
  }

  &__title {
    font-weight: 400;
    font-size: 12px;
    margin: 10px 0 15px;
  }

  &__main {
    font-style: normal;
    text-align: justify;
    font-weight: 400;
    font-size: 12px;
  }
}

.main {
  padding: 20px 0 20px;

  div {
    padding: 20px 0;
  }

}

@media (min-width: 576px) {
  .registration {
    &__title {
      font-size: 20px;
    }
  }

  .quote {
    &__text {
      font-size: 16px;
    }

    &__title {
      font-size: 14px;
    }

    &__main {
      font-size: 14px;
    }
  }
}

@media (min-width: 768px) {
  .registration__title {
    margin-bottom: 0;
    margin-right: 20px;
  }
}

@media (min-width: 992px) {
  .main {
    padding-bottom: 0;
  }
}
</style>
