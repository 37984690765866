<template>
  <h4>Прогревка email</h4>
  <loader v-if="loading"/>
  <form v-else @submit.prevent="send">
    <label for="">Email:</label>
    <input v-model="email" placeholder="Введите email" type="email">
    <button class="btn btn-primary" type="submit">Отправить</button>
  </form>
  <h5 v-if="success">Отправлено</h5>
  <h5 v-if="error">Ошибка</h5>
</template>

<script>
import Loader from "@/components/UI/Loader.vue";
import api from "@/api";

export default {
  name: "EmailTest",
  components: {Loader},
  data() {
    return {
      email: '',
      loading: false,
      success: false,
      error: false,
    }
  },
  methods: {
    send() {
      if (this.email) {
        this.loading = true;
        this.error = false;
        this.success = false;
        api.sendTestEmail(this.email)
            .then(r => {
              this.loading = false
              this.success = true;
            })
            .catch((er) => {
              this.loading = false
              this.error = true;
            })

      }
    }

  }
}
</script>

<style scoped>
input {
  margin: 10px;
}
</style>