<template>
  <div class="msg text-center mt-2">
    {{ msg }}
  </div>
</template>

<script>
export default {
  name: "FormError",
  props: {
    msg: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.msg {

  color: #D12937;
  font-size: 14px;
  font-weight: 500;

}

@media (min-width: 991.98px) {
  .msg {
    font-size: 18px;
  }
}


</style>
