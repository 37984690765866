<template>
  <div v-show="isShowForm">
    <transition name="modal" >
      <ModalInfo :isTransparentBackground="true" @close="this.$emit('notShow', this.isShowForm)">

        <template v-if="!sended">


        <div class="modal__title bg-white w-80">
          <h3 class="p-0 m-0" v-html="$t('popUp_registration.title')"></h3>
        </div>


          <div class="modal__form bg-white w-100">
           <CompanyInfoForm :promocode-info="promocodeError" @submit="send"/>
          </div>


        </template>
        <template v-else>
          <div class="modal__title bg-white w-100">
            <h3 class="p-0 m-0" v-html="$t('popUp_registration.info_after_filling')"></h3>
          </div>
        </template>
      </ModalInfo>
    </transition>
  </div>
</template>

<script>
import ModalInfo from '@/components/UI/Modal/Modal.vue';
import FormItem from '@/components/Form/FormItem.vue';
import FormError from '@/components/Form/FormError.vue';
import {IMaskDirective} from "vue-imask";
import useVuelidate from "@vuelidate/core";
import {maxLength, minLength, required} from "@vuelidate/validators";
import api from "@/api";
import CompanyInfoForm from "@/components/Company/CompanyInfoForm.vue";

const mustBePhone = (value) => new RegExp("^((8|\\+7|\\+1|\\+3)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$").test(value);
export default {
  components: {
    CompanyInfoForm,
    ModalInfo, FormItem, FormError
  },



  data() {
    return {
      sended: false,
      promocodeError: {
        isError: false,
        PromocodeErrorMsg: ''
      }
    }
  },

  props: {
    isShowForm: {
      type: Boolean,
      default: false
    },
  },



  methods: {
    send(data) {
      api.sendCompanyRegistrationRequest(data.nameCompany, data.phone, data.fieldActivity, data.numberEmployees, data.promocode)
          .then(() => {
            this.sended = true;
          })
          .catch(error => {this.promocodeError.PromocodeErrorMsg = error.response.data.message; this.promocodeError.isError = true})
    }
  },

}

</script>

<style lang="scss" scoped>
.notvalid {
  border: 2px solid red;
}

.modal {

  &__title {
    padding: 20px 20px;
    margin: auto;
    text-align: center;
    border-radius: 15px;
    margin-bottom: 15px;

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
    }
  }

  &__form {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;

    border-radius: 20px;
    margin-bottom: 10px;
    padding: 20px 40px;
  }

  &__input {
    border: 0.922953px solid rgba(28, 29, 34, 0.5);
    border-radius: 8.30658px;
    margin: 15px 0 0;
    padding-left: 10px;

    width: 100%;
    height: 43.38px;


    &:hover,
    &:focus {
      outline: none !important;
      transform: scale(102%);
      border: 0.8px solid black
    }
  }


  &__select {
    background-color: #fff;
    margin-top: 15px;
    width: 100%;
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    height: 43.38px;
    border: 0.922953px solid rgba(28, 29, 34, 0.5);
    border-radius: 8.30658px;

    &:hover,
    &:focus {
      outline: none !important;
      transform: scale(102%);
      border: 0.8px solid black
    }
  }

  &__button {
    background-color: #090D39;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    padding: 15px 60px;
    border-radius: 15px;
    border: none;

    &.disabled {
      background: #8B8B8B;
      cursor: not-allowed;
      filter: drop-shadow(0px 4px 4px rgba(139, 139, 139, 0.7));
    }
  }
}

.error {
  color: #D12937;
  border-color: #D12937;
}
</style>
