   <template>
  <div id="header" class=" fixed-top header px-2 px-sm-0 ">
    <div class="header__container w-100">
      <div class="h-100 d-flex align-items-center">
        <a class="logo" href="/">
          <img alt="TW" class="cubeLogo" src="../../assets/landing-profile-genius/icon.png"><span class="name">Team Wisdom</span></a>
      </div>
      <div class=" d-none d-xl-flex align-items-center justify-content-center menu">
        <a href="#" @click="tooglePageTypes" >{{ $t('menu.decisions') }}</a>
        <a class=" dropdown-toggle" href="#" role="button" id="dropdownFeature " data-bs-toggle="dropdown" aria-expanded="false">{{ $t('menu.functional') }}</a>
        <div class="dropdown">
          <ul class="dropdown-menu profile__menu" aria-labelledby="dropdownFeature">
            <li><a class="dropdown-item profile__item" :href="$t('menu.link_profile_genius')" >{{ $t('menu.title_genius_profile') }}</a></li>
          </ul>
        </div>
        <a href="#" @click="tooglePagePlans">{{ $t("menu.rates") }}</a>
      </div>
      <div class=" d-none d-xl-flex align-items-center justify-content-end">

        <div class="order">
          <a @click="showPopup" class="button">{{ $t('title_block.btn_demo') }}</a>
        </div>
        <div class="login">
          <a href="/login" target="_blank">{{ $t('menu.btn_sign_in') }}</a>
        </div>
      </div>
      <div class="dropdown d-xl-none dropdownMenuLink_container">
        <a id="dropdownMenuLink" aria-expanded="false" class="btn btn-secondary "
           data-bs-toggle="dropdown" href="#" role="button">
          <img alt="" src="../../assets/landing-profile-genius/menu.png">
        </a>
        <ul aria-labelledby="dropdownMenuLink" class="dropdown-menu">
          <li class="d-flex justify-content-center"><a @click="tooglePageTypes">{{ $t('menu.decisions') }}</a></li>
          <li class="d-flex justify-content-center"><a href="#features">{{$t('menu.functional')}}</a></li>
          <li class="d-flex justify-content-center"><a @click="tooglePagePlans">{{ $t('menu.rates') }}</a></li>
          <li class="d-flex justify-content-center">
            <div class="orderMenu"><a href="/register" target="_blank">{{ $t('menu.btn_registration') }}</a></div>
          </li>
          <li class="d-flex justify-content-center">
            <div class="loginMenu"><a href="/login" target="_blank">{{ $t('menu.btn_sign_in') }}</a></div>
          </li>
        </ul>

      </div>
    </div>
  </div>

  <v-popup-demo :is-show-pop-up="isShowPopUp" @notShow="showPopup()" :parent-block="'Интересующий блок: Header'"></v-popup-demo>
</template>

<script>
import PopupDemo from "@/components/Landings/component/PopupDemoPromo.vue";

export default {
  components: {
    "vPopupDemo":PopupDemo,
  },
  data() {
    return {
      isShowPopUp: false,
    }
  },
  methods: {
    showPopup() {
      this.isShowPopUp = !this.isShowPopUp;
    },
    tooglePagePlans() {
      let path = this.$route.path;

      if(path === '/company-registration') {
        setTimeout(() => {
          window.location.href = '/genius-profile#types';
        }, 100);
      } else if (path === '/genius-profile' || path === '/') {
        setTimeout(() => {
          let recomendationElement = document.querySelector(".blockPlans");
          recomendationElement.scrollIntoView({block: "start", behavior: "smooth"});
        }, 100);
      }
    },
    tooglePageTypes() {
      setTimeout(() => {
        window.location.href = '/#types';
      }, 100);
    }
  }
}

</script>

<style scoped lang="scss">
.header {
  background: linear-gradient(to bottom, black, rgba(0, 0, 0, 0.5));
  padding: 15px 20px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.logo {
  display: flex;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
}

.name {
  color: white;
  display: inline-block;
  font-weight: 700;
  font-size: 25.0642px;
  line-height: 32px;
}

.cubeLogo {
  width: 30px;
  display: inline-block;
  height: auto;
  object-fit: contain;
  margin-right: 10px;
}

.menu > a {
  display: inline-block;
  text-decoration: none;
  color: white;
  margin-left: 10%;
  font-size: 17px;
  padding-bottom: 5px;
  transition: color 0.2s;
}

.order {
  text-align: center;
  display: inline-block;
  font-size: 16px;
}

.order > a {
  transition: 0.2s;
  display: inline-block;
  border: 2px #2835C1 solid;
  background: #2835C1;
  text-decoration: none;
  color: #F6F6F5;
  font-weight: 400;
}

.login {
  display: inline-block;
  font-size: 17px;
  margin-left: 5px;
}

.login > a {
  transition: 0.2s;
  display: block;
  border-radius: 8px;
  padding: 10px 30px;
  color: white;
  text-decoration: none;
  font-weight: 400;
  border: 2px transparent solid;
}

.login > a:hover {
  transition: 0.2s;
  border: 2px #2835C1 solid;
}

.dropdownMenuLink_container {
  display: inline-block;
  text-align: left;
  padding-right: 0;
}

#dropdownMenuLink {
  background: #2835C1;
  border: 2px #2835C1 solid;
}

#dropdownMenuLink:hover {
  background: transparent;
}

.dropdown-menu {
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  background-color: #000;
  border: 1px #2835C1 solid;
  &:hover {
    border: 1px #2835C1 solid;
  }
}

.dropdown-menu > li > a {
  display: inline-block;
  text-decoration: none;
  color: white;
  font-size: 16px;
  padding-bottom: 5px;
  transition: color 0.2s;

}

.dropdown-menu > li > a:hover {
  background-color: rgba(0, 0, 0, 0);
}

.profile__menu {
  left: -35px !important;
  //background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);

  &:hover {
    transition: all 0.2s;
    border: 1px #2835C1 solid;
  }
}

.orderMenu {
  display: inline-block;
  margin-top: 10px;
}

.orderMenu > a {
  transition: 0.2s;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  border: 2px #2835C1 solid;
  background: #2835C1;
  border-radius: 15px;
  text-decoration: none;
  color: #F6F6F5;
  font-size: 15px;
  font-weight: 400;
}

.loginMenu {
  display: inline-block;
  margin-top: 10px;
  font-size: 15px;
}

.loginMenu > a {
  transition: 0.2s;
  display: block;
  padding: 5px 20px 5px 20px;
  color: white;
  border-radius: 15px;
  text-decoration: none;
  font-weight: 400;
  border: 2px transparent solid;
}

.button {
  border-radius: 8px;
  padding: 10px 30px;
  font-size: 16px;
  transition: 0.2s;
  cursor: pointer;
}

.button:hover {
  box-shadow: 0 0 20px 0.5px #171d57;
  background: #1c278a;
  border-color: #1c278a;
}

@media (min-width: 768px) {
  .name {
    font-size: 30px;
  }

  .header {
    &__container {

      padding-left: 40px;
      padding-right: 40px;
    }
  }
}

@media (min-width: 1200px) {
  .header__container {
    max-width: 1200px;
    padding: 0;
  }

}
</style>