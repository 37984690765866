<template>
<button :disabled="isSending">
  <Loader v-if="isSending" :is-white="true"></Loader>
  <span v-else>{{title}}</span>
</button>
</template>

<script>
import Loader from "@/components/UI/Loader.vue";

export default {
  components: {Loader},
  props: {
    title: {
      type: String,
      required: true
    },
    isSending: {
      type: Boolean,
      default: false
    }
  }
}

</script>

<style scoped lang="scss">

button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 8px;
  padding: 15px 40px;
  font-size: 16px;
  transition: 0.2s;
  cursor: pointer;
  border: 2px #2835C1 solid;
  background: #2835C1;
  color: #F6F6F5;
}

button:hover {
  box-shadow: 0 0 20px 0.5px #171d57;
  background: #1c278a;
  border-color: #1c278a;
}

</style>