<template>
  <FormItem v-if="isRegisterUser" :isError="v$.nameUser.$errors.length != 0" :label="$t('popUp_registration.name_user')">
    <input id="name" v-model.trim="v$.nameUser.$model" class="modal__input" :class="{'active': isRegisterUser}" type="text" placeholder="Имя представителя">
  </FormItem>

  <FormItem v-if="isRegisterUser" :isError="v$.userEmail.$errors.length != 0" :label="'Email:'">
    <input id="name" v-model.trim="v$.userEmail.$model" class="modal__input" :class="{'active': isRegisterUser}" type="text" placeholder="Email">
  </FormItem>

	<FormItem :isError="v$.phone.$errors.length != 0" :label="$t('popUp_registration.contact_number')">
		<input v-if="!isLocaleEn" v-imask="mask" :value="phone" class="modal__input" :class="{'active': isRegisterUser}" placeholder="$t('popUp_registration.mask_number')"
			type="text" @accept="phoneAccept" @complete="phoneComplete" @keydown.space.prevent>

    <input v-else v-imask="maskEn" :value="phone" class="modal__input" :class="{'active': isRegisterUser}" placeholder="$t('popUp_registration.mask_number')"
           type="text" @accept="phoneAccept" @complete="phoneComplete" @keydown.space.prevent>
	</FormItem>

  <FormItem :isError="v$.nameCompany.$errors.length != 0" :label="$t('popUp_registration.name_company')" style="margin-top: 40px">
    <input id="nameCompany" v-model.trim="v$.nameCompany.$model" class="modal__input" :class="{'active': isRegisterUser}" type="text" placeholder="Название компании" >
  </FormItem>


	<FormItem v-if="data" :isError="false" :label="$t('popUp_registration.contact_person')">
		<select v-model="admin" class="modal__select" :class="{'active__select': isRegisterUser}">
			<option :value="null">{{ $t("popUp_registration.choose") }}</option>
			<option v-for="el in data.admins" :key="el.id" v-bind:value="el">{{ el.firstName }} {{ el.lastName }}</option>
		</select>
	</FormItem>
	<FormItem :isError="v$.fieldActivity.$errors.length != 0" :label="$t('popUp_registration.field_activity')">
		<select v-model="v$.fieldActivity.$model" class="modal__select" :class="{'active__select': isRegisterUser}">
			<option disabled value="">{{ $t("popUp_registration.choose") }}</option>
			<option v-for="field in fieldsActivity" :key="field" v-bind:value="field">{{ field }}</option>
		</select>
	</FormItem>

	<FormItem :isError="v$.numberEmployees.$errors.length != 0" :label="$t('popUp_registration.number_employees')">
		<select v-model="v$.numberEmployees.$model" class="modal__select" :class="{'active__select': isRegisterUser}">
			<option disabled value="">{{ $t("popUp_registration.choose") }}</option>
			<option v-for="number in numericalIntervals" :key="number.beginGap"
				v-bind:value="number.beginGap + '-' + number.endGap">
				<span v-if="!number.endGap == ''">{{ number.beginGap }} - {{ number.endGap }}</span>
				<span v-else> {{ number.beginGap }} +</span>
			</option>
		</select>
	</FormItem>
	<FormItem v-if="!data" :is-error="promocodeInfo.isError" :label="$t('popUp_registration.promo_code')" style="margin-top: 40px">
		<input id="promoCode" v-model.trim="promoCode" class="modal__input" :class="{'active': isRegisterUser}" type="text" @input="promocodeInfo.isError = false"
			@keydown.space.prevent placeholder="Промокод" >
	</FormItem>
  <FormError v-show="promocodeInfo.isError" :msg="$t('popUp_registration.promo_code_error')"></FormError>
	<div v-if="!data" class="d-flex mt-4 justify-content-center">
		<button :class="{ disabled: v$.$errors.length != 0 || isPromoError, active__button: isRegisterUser}" class="modal__button text-center" style="margin-top: 40px"
			@click="send">{{ $t("popUp_registration.btn_register") }}
		</button>
	</div>
	<div v-else class="d-flex mt-3 justify-content-end">
		<button :class="{ disabled: v$.$errors.length != 0 || isPromoError }"
			:disabled="isDisable" class="modal__edit text-center" @click="send">{{
				$t("popUp_registration.btn_save") }}
		</button>
	</div>
</template>

<script>
import FormItem from "@/components/Form/FormItem.vue";
import useVuelidate from "@vuelidate/core";
import {email, helpers, maxLength, minLength, required} from "@vuelidate/validators";
import { IMaskDirective } from "vue-imask";
import api from "@/api";
import Loader from "@/components/UI/Loader.vue";
import admin from "@/pages/Admin.vue";
import FormError from "@/components/Form/FormError.vue";
import autoritiesMixin from '@/mixins/autoritiesMixin';

const mustBePhone = (value) => new RegExp("^((8|\\+7|\\+1|\\+3)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$").test(value);

export default {
	emits: ['submit'],
	name: "CompanyInfoForm",
	components: {FormError, Loader, FormItem },
  mixins: [autoritiesMixin],
	setup() {
		return {
			v$: useVuelidate(),
		}
	},
	created() {
		this.getFieldsActivity()
	},
	mounted() {
		if (this.$props.data) {
			let props = this.$props.data
			this.nameCompany = props.name
			this.phone = props.phone
			this.fieldActivity = props.fieldActivity
			this.numberEmployees = props.numberEmployees
			this.admin = props.admin
		}
	},
	props: {
		data: {
			type: Object,
			required: false
		},
    promocodeInfo: {
      type:Object,
      default :{isError: false, PromocodeErrorMsg: ''}
    },
    isRegisterUser: {
      type: Boolean,
      default: false
    }
	},
	validations() {
    let path = this.$route.path;

    if (path === '/company-registration'){
      return {
        nameCompany: { required: required, maxLength: maxLength(30), minLength: minLength(2) },
        nameUser: {
          required:required, minLength:minLength(2)
        },
        userEmail:  {
          email: helpers.withMessage('Введите валидный email', email),
          required:helpers.withMessage('Email обязателен', required),
        },
        fieldActivity: { required },
        numberEmployees: { required },
        phone: { mustBePhone }
      }
    } else {
      return {
        nameCompany: { required: required, maxLength: maxLength(30), minLength: minLength(2) },
        fieldActivity: { required },
        numberEmployees: { required },
        phone: { mustBePhone }
      }
	  }
  },
	watch: {
		nameCompany(val) {
			this.nameCompany = val.slice(0, 30)
		}
	},
	data() {
		return {
			mask: {
				mask: '+{7}(000)000-00-00',
				lazy: false,  // make placeholder always visible
			},
      maskEn: {
        mask: '+{0}(000)000-00-00',
        lazy: false,  // make placeholder always visible
      },
			phone: '',
			nameCompany: '',
      nameUser: '',
      userEmail: '',
      emailUser: '',
			fieldActivity: '',
			numberEmployees: '',
			admin: '',
			fieldsActivity: '',
			numericalIntervals: [
				{ beginGap: 1, endGap: 25 },
				{ beginGap: 26, endGap: 50 },
				{ beginGap: 51, endGap: 75 },
				{ beginGap: 76, endGap: 100 },
				{ beginGap: 101, endGap: 150 },
				{ beginGap: 151, endGap: 200 },
				{ beginGap: 201, endGap: 250 },
				{ beginGap: 251, endGap: 300 },
				{ beginGap: 300, endGap: '' }
			],
			result: [],
      promoCode: '',
			promocodes: ['DREAMTEAM', 'VKTeams', 'HabrTeams', 'АГласс10', 'РСО10', 'РСЛ10', 'Суперкоманда', 'Мультибит5', 'CUSTDEV', 'UNITY5', 'HRMEET5',
      'CUSTDEV5'],
			isPromoError: false,
      isSended: false,
      isLocaleEn: false
		}
	},
	directives: {
		imask: IMaskDirective
	},
  beforeMount() {
    this.getLocal();
  },
  computed: {
    isDisable() {
      if(this.isSuperAdmin() || !this.data) {
        return this.isSended;
      } else {
        return  this.v$.$errors.length != 0 || this.isSended;
      }

    }
  },

	methods: {
    getLocal() {
      const currentLocale = this.$i18n.locale;
      // const currentLocale = 'en';
      this.typesInfo = require(`@/i18n/locales/${currentLocale}/result_genius.json`);
      if (currentLocale == 'en') {
        this.isLocaleEn = true;
      }
    },
		phoneComplete(e) {
			const maskRef = e.detail;
			this.phone = maskRef.value
		},
		phoneAccept(e) {
			const maskRef = e.detail;
			this.phone = maskRef.value;
		},
		async send() {
      if (!this.isSuperAdmin() || !this.data) {
        const result = await this.v$.$validate()
        if (!result) {
          // notify user form is invalid
          return
        }
      }

      this.chooseEmitResult();
      this.isSended = true;
		},
		getFieldsActivity() {
			const currentLocale = this.$i18n.locale;
			let info = require(`@/i18n/locales/${currentLocale}/company_registration_page.json`);
			this.fieldsActivity = info["popUp_registration"]["data_field_activity"]
		},
    chooseEmitResult() {
      if (this.isRegisterUser) {
        this.$emit('submit', {
          nameUser: this.nameUser,
          email: this.userEmail,
          nameCompany: this.nameCompany,
          phone: this.phone,
          numberEmployees: this.numberEmployees,
          fieldActivity: this.fieldActivity,
          adminId: this.admin == null ? null : this.admin.id,
          promocode: this.promoCode
        });
      } else {
        this.$emit('submit', {
          nameCompany: this.nameCompany,
          phone: this.phone,
          numberEmployees: this.numberEmployees,
          fieldActivity: this.fieldActivity,
          adminId: this.admin == null ? null : this.admin.id,
          promocode: this.promoCode
        });
      }
    },

  },

}
</script>

<style lang="scss" scoped>
.notvalid {
	border: 2px solid red;
}

.modal__edit {
	font-size: 14px;
	padding: 6px 50px;
	border-radius: 20px;
	background-color: #090D39;
	color: #fff;
	outline: none;
	border: none;

	&:disabled {
		color: rgba(28, 29, 34, 0.80);
		background: rgba(197, 191, 191, 0.50);

	}

}

.modal {

	&__title {
		padding: 20px 20px;
		margin: auto;
		text-align: center;
		border-radius: 15px;
		margin-bottom: 15px;

		h3 {
			font-weight: 700;
			font-size: 18px;
			line-height: 21px;
		}
	}

	&__form {
		font-weight: 400;
		font-size: 10px;
		line-height: 15px;

		border-radius: 20px;
		margin-bottom: 10px;
		padding: 40px;
	}

	&__input {
		border: 0.922953px solid rgba(28, 29, 34, 0.5);
		border-radius: 8.30658px;
		margin: 10px 0 0;
		padding-left: 10px;

		width: 100%;
		height: 43.38px;


		&:hover,
		&:focus {
			outline: none !important;
			transform: scale(102%);
			border: 0.8px solid black
		}
	}


	&__select {
		background-color: #fff;
		margin-top: 15px;
		width: 100%;
		padding: 5px;
		font-size: 16px;
		border: 1px solid #ccc;
		height: 43.38px;
		border: 0.922953px solid rgba(28, 29, 34, 0.5);
		border-radius: 8.30658px;

		&:hover,
		&:focus {
			outline: none !important;
			transform: scale(102%);
			border: 0.8px solid black
		}
	}

	&__button {
		background-color: #090D39;
		color: #fff;
		font-weight: 700;
		font-size: 18px;
		line-height: 21px;
		padding: 15px 60px;
		border-radius: 15px;
		border: none;

		&.disabled {
			background: #8B8B8B;
			cursor: not-allowed;
			filter: drop-shadow(0px 4px 4px rgba(139, 139, 139, 0.7));
		}
	}
}

.active {
  color: white;
  padding: 15px;
  background: transparent;
  border: 1px #6B6B6B solid;
  border-radius: 15px;

  &:hover, &:focus{
  border: 1px #202FD4 solid;
  transition: 0.2s;
  }

  &__select {
    color: white;
    background: transparent;
    margin-top: 15px;
    width: 100%;
    padding: 5px;
    font-size: 16px;
    border: 1px #6B6B6B solid;
    border-radius: 15px;
    height: 43.38px;

    &:hover, &:focus {
      border: 1px #202FD4 solid;
    }

    option {
      background-color: #0B0215;;
    }
  }

  &__button {
      width: 100%;
      display: block;
      outline: none;
      padding: 15px 20px;
      background: #2835C1;
      border: none;
      border-radius: 15px;
      text-decoration: none;
      color: #F6F6F5;
      font-size: 20px;
      font-weight: 400;
      transition: 0.2s;
    &[disabled]{
      background-color: grey;
    }
    &[disabled]:hover{
      background-color: grey;
      box-shadow: none;
    }
    &:hover{
      box-shadow: 0px 0px 20px 0.5px #0015FF;
      background: #0017EF;
      transition: 0.2s;
    }
    &:active{
      background: #0014CC;
      transition: 0.2s;
    }
  }
}

.error {
	color: #D12937;
	border-color: #D12937;
}
</style>