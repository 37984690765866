<template>
  <button :class="{disabled : isAnyError}" class="form__submit" type="submit" @click.prevent="$emit('submitForm')">
    <span v-if="isLoading">
      <span aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></span>
      <span aria-hidden="true" class="spinner-grow spinner-grow-sm mx-2 " role="status"></span>
      <span aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></span>
    </span>

    <span v-else>{{ title }}</span></button>
</template>

<script>
export default {
  name: "FormButton",

  props: {
    isAnyError: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.form__submit {
  width: 100%;
  margin-top: 56px;
  font-weight: 500;
  border-radius: 5.84534px;
  padding: 16px;
  color: white;
  font-size: 20px;
  border: none;
  background: #1C2E76;
  transition: .5s all linear;
  filter: drop-shadow(0px 4px 4px rgba(28, 46, 118, 0.7));

  &.disabled {
    background: #8B8B8B;
    cursor: not-allowed;
    filter: drop-shadow(0px 4px 4px rgba(139, 139, 139, 0.7));
  }

}
</style>
