<template>
  <div id="questions" class="container-fluid">
    <p class="h2">{{$t("blocks_solve_issues.title")}}</p>
  </div>
  <div id="rectangles" class="container-fluid rectangles">
    <div class="row gy-2 mb-2 my-lg-3 d-flex justify-content-center">
      <div class="col-lg rectangle fillBlue">
        <p class="h6" >{{$t("blocks_solve_issues.block_realizing_potential")}}</p>
      </div>
      <div class="col-lg rectangle gradientBorder">
        <p class="h6" >{{$t("blocks_solve_issues.block_improving_efficiency")}}</p>
      </div>
      <div class="col-lg rectangle fillBlue">
        <p class="h6" >{{$t("blocks_solve_issues.block_personnel_evaluation")}}</p>
      </div>
      <div class="col-lg rectangle blueBorder">
        <p class="h6">{{$t("blocks_solve_issues.block_formation_teams")}}</p>
      </div>
    </div>
    <div class="row gy-2 mb-2  my-lg-3 d-flex justify-content-center">
      <div class="col-lg rectangle gradientBorder left">
        <p class="h6">{{$t("blocks_solve_issues.block_conflict_prevention")}}</p>
      </div>
      <div class="col-lg rectangle fillBlue">
        <p class="h6">{{$t("blocks_solve_issues.block_personnel_decisions")}}</p>
      </div>
      <div class="col-lg rectangle blueBorder">
        <p class="h6" data-i18n="blocks_solve_issues.block_communication_team">{{$t("blocks_solve_issues.block_communication_team")}}</p>
      </div>
      <div class="col-lg rectangle fillBlue">
        <p class="h6" data-i18n="blocks_solve_issues.block_increased_motivation">{{$t("blocks_solve_issues.block_increased_motivation")}}</p>
      </div>
    </div>
    <div class="row gy-2 mb-2  my-lg-3  d-flex justify-content-center">
      <div class="col-lg rectangle fillBlue">
        <p class="h6">{{$t("blocks_solve_issues.block_talent_development")}}</p>
      </div>
      <div class="col-lg rectangle blueBorder">
        <p class="h6">{{$t("blocks_solve_issues.block_distribution_tasks")}}</p>
      </div>
      <div class="col-lg rectangle fillBlue">
        <p class="h6">{{$t("blocks_solve_issues.block_development_consciousness")}}</p>
      </div>
      <div class="col-lg rectangle gradientBorder">
        <p class="h6">{{$t("blocks_solve_issues.block_creating_atmosphere")}}</p>
      </div>
    </div>
    <div class="row gy-2 mb-2  my-lg-3 d-flex justify-content-center ">
      <div class="col-lg rectangle blueBorder left">
        <p class="h6">{{$t("blocks_solve_issues.block_detecting_closing_frame_holes")}}</p>
      </div>
      <div class="col-lg rectangle fillBlue">
        <p class="h6" >{{$t("blocks_solve_issues.block_improving_performance")}}</p>
      </div>
      <div class="col-lg rectangle gradientBorder">
        <p class="h6">{{$t("blocks_solve_issues.block_burnout_warning")}}</p>
      </div>
      <div class="col-lg rectangle fillBlue">
        <p class="h6">{{$t("blocks_solve_issues.block_establishing_teamwork")}}</p>
      </div>
    </div>
    <div class="row gy-2 mb-2  my-lg-3 d-flex justify-content-center">
      <div class="col-lg rectangle fillBlue">
        <p class="h6">{{$t("blocks_solve_issues.block_recruitment_personnel")}}</p>
      </div>
      <div class="col-lg rectangle gradientBorder">
        <p class="h6">{{$t("blocks_solve_issues.block_compliance_position")}}</p>
      </div>
      <div class="col-lg rectangle fillBlue">
        <p class="h6" >{{$t("blocks_solve_issues.block_executive_training")}}</p>
      </div>
      <div class="col-lg rectangle blueBorder">
        <p class="h6">{{$t("blocks_solve_issues.block_personnel_management")}}</p>
      </div>
    </div>
  </div>
</template>

<script>

</script>

<style scoped lang="scss">

#questions {
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 50px;
  margin-top: 200px;
}

#rectangles {
  margin-bottom: 200px;

  p {
    margin: 0;
  }
}

.rectangle {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  max-width: 300px;
  height: 60px;
  border-radius: 30px;
  text-align: center;
  vertical-align: middle;

  display: flex;
  justify-content: center;
  align-items: center;
}

.blueBorder {
  border: 2px #2835C1 solid;
}


.gradientBorder {
  box-shadow: 0px 0px 20px 0.5px #171d57;

  background: linear-gradient(black, black) padding-box,
  linear-gradient(to right, #B927B5, #2835C1) border-box;
  border: 2px solid transparent;

}

.fillBlue {
  background: rgba(34, 51, 204, 0.5);
}

@media (min-width: 1180px) {
  .rectangle.left {
    margin-left: 100px;
  }
}

</style>