<template>
    <div>
      <label class="m-2">Поиск</label>
      <input type="text" placeholder="Фамилия Имя" v-model.trim="search">
    </div>
  <div>
    <h3>Самореги</h3>
    <table class="table table-striped table-bordered border-primary ">
      <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">Телефон</th>
        <th scope="col">A</th>
        <th scope="col">V</th>
        <th scope="col">Genius</th>
        <th scope="col">Date</th>
        <th scope="col">Edit</th>
      </tr>
      </thead>
      <tbody>
      <loader v-if="isLoadingUser"></loader>
      <tr v-else v-for="user in usersUserSelf">
        <td>
          <router-link class="link" :to="'/users/' +user.id">{{ user.fullName }}</router-link>
        </td>
        <td>{{ user.email }}</td>
        <td>{{ user.phone || "Нет данных" }}</td>
        <td><img v-if="user.activated" src="@/assets/userIsConfirmResult.svg" alt=""></td>
        <td><img v-if="user.talentVerified" src="@/assets/userIsConfirmResult.svg" alt=""></td>
        <td>{{ user.talentType || "Нет данных" }}</td>
        <td>{{ formatedDate(user.created) }}</td>
          <!--<td><button class="btn" :class="{admin: user.admin, notAdmin: !user.admin }" @click="changeRoleAccess(user)"> {{ user.admin ? 'Понизить' : 'Сделать админом' }}</button></td>-->

      </tr>
      </tbody>
    </table>
    <div>
      <nav aria-label="..." class="pagination-container">
        <ul class="pagination">
          <li :class="{disabled : offsetUserSelf == 0}" class="page-item">
            <a class="page-link" @click="getUsersWithoutTeam(this.limitUserSelf, --offsetUserSelf)">Previous</a>
          </li>
          <li v-for="page in totalPagesUserSelf" :class="{active : offsetUserSelf == page - 1}" aria-current="page" class="page-item">
            <a class="page-link" @click="getUsersWithoutTeam(this.limitUserSelf, page-1)">{{ page }}</a>
          </li>
          <li :class="{disabled : offsetUserSelf == totalPagesUserSelf - 1}" class="page-item ">
            <a class="page-link" @click="getUsersWithoutTeam(this.limitUserSelf, ++offsetUserSelf)">Next</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <div>
    <h3>В командах</h3>
    <table class="table table-striped table-bordered border-primary ">
      <thead>
      <tr>
        <th scope="col">Company</th>
        <th scope="col">Team</th>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">JobTitle</th>
        <th scope="col">A</th>
        <th scope="col">V</th>
        <th scope="col">Genius</th>
        <th scope="col">Date</th>
        <th scope="col">Role</th>
        <th scope="col">Edit</th>
        <!--				<th scope="col">Сделать представителем компании</th>-->
      </tr>
      </thead>
      <tbody>
      <loader v-if="isLoadingTeam"></loader>
      <tr v-else v-for="r in usersUsersTeam">
        <td> <router-link class="link" :to="'/company/' + r.company.id">{{ r.company.name }}</router-link></td>
        <td> <router-link class="link" :to="'/teams/' + r.team.id">{{ r.team.name }}</router-link></td>
        <td> <router-link class="link" :to="'/users/' + r.user.id">{{ r.user.fullName }}</router-link></td>
        <td>{{ r.user.email }}</td>
        <td>{{ r.user.position || "Нет данных" }}</td>
        <td><img v-if="r.user.activated" src="@/assets/userIsConfirmResult.svg" alt=""></td>
        <td><img v-if="r.user.talentVerified" src="@/assets/userIsConfirmResult.svg" alt=""></td>
        <td>{{ r.user.talentType || "Нет данных" }}</td>
        <td>{{ formatedDate(r.user.created) }}</td>
        <td>{{ r.role }}</td>
        <!--				<td>-->
        <!--					<DropDown :user="r" :roles="roles" @role-changed="changeRole" />-->
        <!--				</td>-->
        <!--				<td><button class="btn btn-light" @click="makeRepresentative(r)">Сделать</button></td>-->

        <!--<td><button class="btn" :class="{admin: user.admin, notAdmin: !user.admin }" @click="changeRoleAccess(user)"> {{ user.admin ? 'Понизить' : 'Сделать админом' }}</button></td>-->
      </tr>
      </tbody>
    </table>
    <div>
      <nav aria-label="..." class="pagination-container">
        <ul class="pagination">
          <li :class="{ disabled: offsetUsersTeam == 0 }" class="page-item">
            <a class="page-link" @click="getUsersWithTeam(this.limitUsersTeam, --offsetUsersTeam)">Previous</a>
          </li>
          <li v-for="page in totalPagesUsersTeam" :class="{ active: offsetUsersTeam == page - 1 }" aria-current="page" class="page-item">
            <a class="page-link" @click="getUsersWithTeam(this.limitUsersTeam, page - 1)">{{ page }}</a>
          </li>
          <li :class="{ disabled: offsetUsersTeam == totalPagesUsersTeam - 1 }" class="page-item ">
            <a class="page-link" @click="getUsersWithTeam(this.limitUsersTeam, ++offsetUsersTeam)">Next</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>


</template>

<script>
import Loader from "@/components/UI/Loader.vue";
import api from "@/api";
import autoritiesMixin from '@/mixins/autoritiesMixin'

export default {
  name: "UserSelfTable",
	mixins: [autoritiesMixin],
  components: {Loader},
  props: {
    usersSelfTable: {
      type: Object,
      required: true
    },
    usersTeamTable: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      offsetUserSelf: this.usersSelfTable.number,
      limitUserSelf: this.usersSelfTable.size,
      totalPagesUserSelf: this.usersSelfTable.totalPages,
      usersUserSelf: this.usersSelfTable.content,
      offsetUsersTeam: this.usersTeamTable.number,
      limitUsersTeam: this.usersTeamTable.size,
      totalPagesUsersTeam: this.usersTeamTable.totalPages,
      usersUsersTeam: this.usersTeamTable.content,
      search: "",
      // changedUser: '',
      // roleUser: '',
      roles: ['ROLE_MEMBER', 'ROLE_TEAM_LEAD', 'ROLE_COMPANY_ADMIN', 'ROLE_EMPLOYEE'],
      isTyping: false,
      timerId: -1,
      isLoadingUser: false,
      isLoadingTeam: false
    }

  },
  watch:{
    search(newVal){
      this.isTyping = true;
      clearTimeout(this.timerId)
      this.timerId = setTimeout(()=>{
        this.isTyping = false

      },500)
    },
    isTyping(newVal){
      if(!newVal){
        this.getUsersWithoutTeam(this.limitUserSelf,this.offsetUserSelf);
        this.getUsersWithTeam(this.limitUsersTeam, this.offsetUsersTeam);
      }
    }

  },
  computed: {
    // users() {
    //   this.offset = this.usersProps.number;
    //   this.limit = this.usersProps.size;
    //   this.totalPages = this.usersProps.totalPages;
    //   this.$emit('changeLoading');
    //   return this.usersProps.content;
    // },
  },
  methods: {
    formatedDate(date) {
      return date.slice(0, 10);
    },
    getUsersWithoutTeam(limit, offset) {
      this.isLoadingUser = true;
      this.offsetUserSelf = offset;
      api.getUsersWithoutTeam(limit, offset, this.search)
          .then(r => {
            this.usersUserSelf = r.data.content;
            this.isLoadingUser = false;
          })
    },
    getUsersWithTeam(limit, offset) {
      this.isLoadingTeam = true;
      this.offsetUsersTeam = offset;
      api.getUsersWithTeamsSearch(limit, offset, this.search)
          .then(r => {
            this.usersUsersTeam = r.data.content;
            this.isLoadingTeam = false;
          })
    },
		// changeRoleAccess(user) {
		// 	this.$emit('changeRole', {
		// 		email: user.email,
		// 		fullName: user.fullName,
		// 		isAdmin: user.admin
		// 	})
		// }
  }
}
</script>

<style scoped lang="scss">
.link {
  color: black;
  text-decoration: none;

  &:hover {
    font-weight: bold;
  }
}

.admin {
	background-color: rgb(255, 255, 255);
}

.notAdmin{
	background-color: grey;
	color: #FFFFFF
}

.pagination-container {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}
</style>
