import { mapGetters } from "vuex";

export default {

    computed:{
        ...mapGetters({
            roles :'AuthModule/getRole',
            adminCompanies : 'AuthModule/getAdminCompanies',
            userId : 'AuthModule/getId'
        })
    },

    methods:{
        isSuperAdmin(){
            return this.roles == 'ROLE_SUPER_ADMIN'
        },
        isAdminOfCompany(companyId){
            return this.adminCompanies != null && this.adminCompanies.includes(companyId);
        },
        canManageTeamInCompany(companyId){
            return this.isSuperAdmin() || this.isAdminOfCompany(companyId);
        },
        canManageUsersInTeam(team){
            return this.isTeamLead(team) || this.isSuperAdmin() || this.isAdminOfCompany(team.company.id);
        },
        canDeleteTeam(team){
            return this.isSuperAdmin() || this.isAdminOfCompany(team.company.id)
        },
        isTeamLead(team){
            let users = team.users;
            let role = this.getRole(users)
            return role == 'ROLE_TEAM_LEAD'
        },
        getRole(users){
            for (const key in users) {
                if (Object.hasOwnProperty.call(users, key)) {
                    const user = users[key];
                    if(user.id == this.userId) return user.role
                }
            }
        }
    }
}
