
<template>
  <div class="getDemo" :id="idTitle">
    <div class="container row">
      <div class="col d-flex justify-content-center">
        <p class="getDemoTitle text-center h2">{{$t('form_demo.title')}}</p>
      </div>
    </div>
    <div class="background_form" v-if="isShowBackground">
      <img src="../../assets/landing-profile-genius/background/background_form.svg" alt="background_form">
    </div>
    <div class="container row my-3" style="z-index: 2">
      <div class="col d-flex justify-content-center">

          <div class="form__container">
            <div class="d-flex flex-column align-items-center justify-content-start my-2" style="position: relative">
              <div class="required-field">
              <input id="name" v-model.trim="name" class="getDemoForm" type="text" :placeholder="$t('form_demo.name')">
              </div>
              <div :class="{ messageShow: isNameError }" class="message">
                <p id="firstName" class="error">{{ $t('pop_up.error_fill_field') }}</p>
              </div>
            </div>
            <div class=" d-flex flex-column align-items-center justify-content-start my-2" style="position: relative">
              <div class="required-field">
              <input v-imask="mask" :value="phone" class="getDemoForm" :placeholder="$t('pop_up.phone')"
                     type="text" @accept="phoneAccept" @complete="phoneComplete" @keydown.space.prevent>
              </div>
              <div :class="{ messageShow: isPhoneError }" class="message">
                <p id="firstName" class="error">{{ $t('pop_up.error_fill_field') }}</p>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center justify-content-start my-2">
              <input id="promo" v-model.trim="promo" class="getDemoForm" type="text"
                     :placeholder="$t('pop_up.promo')" @keydown.space.prevent @input="promocodeError.isError = false">
              <FormError v-show="promocodeError.isError" :msg="$t('popUp_registration.promo_code_error')"></FormError>
            </div>
            <div class="col d-flex justify-content-start my-2">
              <ButtonDemo :title="$t('pop_up.btn_send')" class="orderDemo" @click="sendFormDemo" :is-sending="isSending"></ButtonDemo>
            </div>
          </div>
      </div>
    </div>


  </div>


  <div v-if="sendForm" id="popupNotification" class="popupShow container-fluid popupNotification d-flex justify-content-center" @click.stop="this.sendForm = false">
    <div class="popup_body align-self-center">
      <div class="popup_contentNotification popup_contentShow col-sm-">
        <div class="row">
          <div class="col d-flex justify-content-center">
            <div class="popup_title h5" >{{$t('pop_up.thanks')}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ButtonDemo from "@/components/Landings/component/ButtonDemo.vue";
import api from "@/api";
import {IMaskDirective} from "vue-imask";
import useVuelidate from "@vuelidate/core";
import FormError from "@/components/Form/FormError.vue";

const mustBePhone = (value) => new RegExp("^((8|\\+7|\\+1|\\+3)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$").test(value);

export default {
  components: {
    FormError,
    ButtonDemo
  },
  directives: {
    imask: IMaskDirective
  },
  validations() {
    return {
      // nameCompany: { required: required, maxLength: maxLength(30), minLength: minLength(2) },

      // fieldActivity: { required },
      // numberEmployees: { required },
      phone: { mustBePhone }
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  props: {
    isShowBackground: {
      type: Boolean,
      default: true
    },
    idTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      mask: {
        mask: '+{7}(000)000-00-00',
        lazy: false,
      },
      phone: '',
      name: '',
      promo: '',
      sendForm: false,
      nameError: false,
      phoneError: false,
      promocodeError: {
        isError: false,
        PromocodeErrorMsg: ''
      },
      isSending: false
    }
  },
  computed: {
    isNameError() {
      return this.nameError;
    },
    isPhoneError() {
      return this.phoneError;
    },
    isFormDisabled() {
      return  this.isNameError || this.isPhoneError;
    },
  },
  methods: {
    phoneComplete(e) {
      const maskRef = e.detail;
      this.phone = maskRef.value
    },
    phoneAccept(e) {
      const maskRef = e.detail;
      this.phone = maskRef.value;
    },
    checkName() {
      this.nameError = (this.name.length === 0);
    },
    checkPhone() {
      this.phoneError = (this.phone.length === 0);
    },
    checkAll() {
      this.checkName();
      this.checkPhone();
    },
    sendFormDemo() {
      this.checkAll();
      if(!this.isFormDisabled) {
        let text = "Интересующий раздел: Заполните форму, чтобы получить демо-доступ для компании:"
        api.sendFormPromo(this.name, this.phone, this.promo, this.parentBlock)
            .then(this.isSending = true)
            .then(r => {
              this.sendForm = true;
              this.clearFields();
            })
            .catch(error => {
              this.promocodeError.PromocodeErrorMsg = error.response.data.message;
              this.promocodeError.isError = true;
            })
            .finally(() => {
              this.isSending = false
            });
      } else {
        return
      }
    },
    clearFields () {
      this.email = '';
      this.name = '';
      this.phone = '';
    }
  }
}

</script>

<style scoped lang="scss">
.getDemo {
  font-weight: 400;
  margin-top: 120px;
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.getDemoTitle {
  margin-bottom: 20px;
  max-width: 550px;
}

.getDemoForm {
  color: white;
  padding: 15px;
  background: transparent;
  border: 3px #202FD4 solid;
  border-radius: 8px;
}

.getDemoForm:focus {
  outline: none;
  transition: 0.2s;
}

.orderDemo {
  width: 250px;
  display: inline-block;
  outline: none;
  padding: 15px 40px 15px 40px;
}

.form__container {
  display: flex;
  flex-direction: column;

}

.error {
  display: block;
  color: red;
}

.message {
  align-self: flex-start;
  width: 230px;
  transition: 0.2s;
  font-weight: 400;
  height: 0;
  padding: 0;
  opacity: 0;
}

.messageShow {
  transition: 0.2s;
  font-weight: 500;
  height: auto;
  margin-top: 5px;
  opacity: 1;
}

.message p {
  margin-bottom: 0;
  font-size: 14px;
}

.required-field::before {
  content: "*";
  color: #fff;
  position: absolute;
  top: 3px;
  right: 10px;
  padding: 0;
}

.background_form {
  position: absolute;
  top: -150px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 350px;

  img {
    width: 350px;
  }

}

@media (min-width: 768px) {
  .getDemo {
    margin-top: 200px;
    margin-bottom: 200px;
  }

  .getDemoForm {
    width: 400px;
  }

  .orderDemo {
    width: 400px;
  }
}

@media (min-width: 956px) {
  .background_form {
    img {
      width: auto;
    }
  }
}

@media (min-width: 1200px) {

  .background_form {
    top:-300px;
  }

  .getDemoForm {
    width: 230px;
    margin-right: 15px;
  }

  .orderDemo {
    width: 230px;
    max-height: 60px;
  }

  .form__container {
    flex-direction: row;
    justify-content: flex-start;
  }

  .required-field::before {
    right: 20px;
  }
}
</style>