<template>
  <Error :code="'ERR_NETWORK'" :message="$t('err_network')"></Error>
</template>

<script>

import Error from '@/components/UI/Error.vue'

export default {
  components: {Error}


}
</script>

<style scoped>

</style>
