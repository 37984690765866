<template>
  <div id="card" role="alert">
    <div :class="{ 'resolved': !isOpened }" class="invite">{{ firstName }} {{ lastName }} : {{ email }}</div>
    <JoinRequestStatus :status="status"></JoinRequestStatus>
  </div>
</template>
<script>
import JoinRequestStatus from './JoinRequestStatus.vue';

export default {
  components: {JoinRequestStatus},
  computed: {
    isOpened() {
      return this.status == "OPENED";
    }
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    firstName: {
      type: String,
      required: true
    },
    lastName: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    }
  },

}
</script>
<style lang="scss" scoped>
#card {
  display: flex;
  justify-content: space-between;
  margin: 13px;

}

.invite {

  flex-grow: 1;
  background-color: #fff;
  padding: 7px 24px;
  border-radius: 13px;
  margin-right: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #000000;
}

.resolved {
  background: #DADADA;
  color: #6C6C6C;

}

.control {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 576px) {
  .invite {

    padding: 13px 34px;
    border-radius: 13px;
    font-size: 20px;
  }
}


/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

}

@media (min-width: 988px) {


}
</style>
