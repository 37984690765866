<template>
  <div v-if="isAnyLoading" class="block__loader">
    <loader></loader>
  </div>
  <div class="mt-5" v-else>
    <div v-if="!isError" class="alert alert-success" role="alert">
      Вы подтвердили свою электронную почту
    </div>
    <div v-else class="alert alert-danger" role="alert">
      Произошла ошибка. Попробуйте еще.
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Loader from "@/components/UI/Loader.vue";
import api from "@/api";

export default {
  name: "EmailVerification",
  components: {Loader},
  data() {
    return {
      token: this.$route.query.t,
      isError: false
    }
  },
  computed: {
    ...mapGetters(['isAnyLoading'])
  },
  mounted() {
    if(this.token){
      api.verifyEmail(this.token).then(r => {
            setTimeout(() => this.$router.push('/users'), 2000)
          }
      ).catch(err => {this.isError = true});
    }
  }
}
</script>

<style scoped>
.block__loader {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>