<template>
    <CardWrapper>
        <div>
            {{ name }}
        </div>
        <div class="count">
            {{ count }}
        </div>
    </CardWrapper>


</template>

<script>
import CardWrapper from '@/components/UI/CardWrapper.vue';
export default {
    components:{ CardWrapper },
    props:{
        name :{
            type: String,
            required: true
        },
        count : {
            type : Number,
            required: true
        }
    }

}
</script>

<style scoped>


.count {
    color: #6d6c6c;
    font-weight: 700;
    font-size: 16.5455px;
    line-height: 100%;
    background: #F1F1F1;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

}
</style>
