<template>
  <div v-if="isAnyLoading" class="block__loader">
    <loader></loader>
  </div>
  <div class="w-100 " v-else>
    <CardsBlock :title="'Список ролей'">
      <div class="row gx-2 mt-3">
        <div v-for="type in this.typesName" class="col-12 col-md-6 col-lg-3 p-0 ">
          <UserButtonPureType :name="this.displayTypeName(type)" :bg-color="this.colorOfType(type)" :type="type"> {{ type}}
          </UserButtonPureType>
        </div>
      </div>
    </CardsBlock>
  </div>
</template>

<script>
import api from "@/api";
import {mapGetters} from "vuex";
import Loader from "@/components/UI/Loader.vue";
import CardsBlock from "@/components/UI/CardsBlock.vue";
import UserButtonPureType from "@/components/User/UserButtonPureType.vue";
import typesUtils from "@/mixins/typesUtils";
export default {
  name: "Types",
  components: {UserButtonPureType, CardsBlock, Loader},
  mixins:[typesUtils],
  data(){
    return{
      typesName: []
    }
  },
  computed: {
    ...mapGetters([
      'isAnyLoading',
    ])
  },
  mounted() {
    api.getTypesInfo().then(r => {
      this.typesName = Object.keys(r.data);
    })
  },
}
</script>

<style scoped>
.block__loader {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
