<template>
  <div class="blockKPI">
    <div class="blockKPI__title"><h2>Вы улучшите такие KPI, как:</h2></div>

<!--    <List :list-info="listInfo" class="blockKPI__list" :path="'white-checkmark'"></List>-->
    <div class="container blockKPI__cards">
      <div v-for="elem in listInfo">
        <CardKPI :title="elem.title" :path="elem.path"></CardKPI>
      </div>
    </div>

    <div class="backgroung_KPI" >
      <img src="../../../assets/landing-profile-genius/background/background_KPI.svg" alt="backgroung_KPI">
    </div>
  </div>
</template>

<script>
import List from "@/components/Landings/component/List.vue";
import CardKPI from "@/components/Landings/LandingProfileGenius/LandingKPI/CardKPI.vue";
export default {
  components: {
    List,
    CardKPI
  },
  data() {
    return {
      listInfo: [{title: 'Скорость и качество подбора кандидатов', path: 'speed-selection'},
        {title: 'Эффективность<br>работы', path: 'work-efficiency'},
        {title: 'Сокращение периода адаптации новых сотрудников', path: 'employee-adaptation'},
        {title: 'Уменьшение текучки на испытательном сроке', path: 'reducing-turnover'},
        {title: 'Уменьшение<br class="d-md-none"> количества конфликтов<br class="d-md-none"> в коллективе', path: 'reducing-conflicts'},
        {title: 'Предотвращение<br>выгорания', path: 'reducing-burnout'}]
    }
  }
}

</script>

<style scoped lang="scss">
.blockKPI {
  position: relative;
  text-align: center;
  margin-right: auto;
  margin-left: auto;

  display: flex;
  flex-direction: column;
  align-items: center;


  &__title {
    margin-bottom: 40px;
    max-width: 600px;
    color: #F6F6F5;
  }

  &__list {
    align-self: center;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }
}

.backgroung_KPI {
  position: absolute;
  top: 500px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

@media (min-width: 768px) {
  .blockKPI {
    &__list {
      align-self: flex-end;
    }
  }
}
</style>