<template>
    <loader v-if="isAnyLoading"></loader>
    <div v-else class="w-100">
      <CardsBlock :title="$t('page_invite_to_team.invites_teams')">
      <div class="mx-auto">
        <div v-if="joinRequests.length != 0">
          <div v-for="request in joinRequests">
            <TeamJoinRequestCard :id="request.id" :firstName="request.user.firstName"
                                 :lastName="request.user.lastName" :email="request.user.email" :status="request.status">
            </TeamJoinRequestCard>
          </div>
        </div>
        <h5 class="opacity-50 text-center mt-3" v-else>{{$t('page_invite_to_team.no_invites')}}</h5>
      </div>

      </CardsBlock>
    </div>
</template>
<script>
import api from '@/api'
import TeamJoinRequestCard from '@/components/JoinRequests/TeamJoinRequestCard.vue'
import Loader from '@/components/UI/Loader.vue'
import { mapGetters } from 'vuex'
import CardsBlock from "@/components/UI/CardsBlock.vue";
export default {
    components: {CardsBlock, TeamJoinRequestCard, Loader },
    data() {
        return {
            joinRequests: []
        }
    },
    computed: {
        ...mapGetters({
            isAnyLoading: 'isAnyLoading',
        }),

    },
    methods: {
        getJoinRequests(id) {
            api.getTeamJoinRequests(id).then(r => this.joinRequests = r.data)
        }
    },
    mounted() {
        this.$store.dispatch('AuthModule/updateSelfJoinRequests');
    },

    beforeMount() {
        this.getJoinRequests(this.$route.params.id);
    },
    async beforeRouteUpdate(to, from) {
        this.getJoinRequests(to.params.id)

    },
}
</script>
<style>

</style>
