<template>
  <Error :code="404" :message="$t('error_404')"></Error>

</template>

<script>

import Error from '@/components/UI/Error.vue'

export default {
  components: {Error}

}
</script>

<style scoped>

</style>
