<template>
<h3>{{title}}</h3>
</template>

<script>
export default {
  name: "FormTitle",
  props:{
    title : {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
h3 {
  font-weight: 700;
  font-size: 30px;
  color: #000000;
}
</style>
