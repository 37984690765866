<template>
	<div class="mb-4">
		<UserBlockTitle :title="$t('title_description_result')"></UserBlockTitle>
	</div>
	<div v-if="this.type == 'wide_profile'">
		<div class="user__mainInfo w-100">
			<div class="subtitle">
				<div>
					{{$t("wide_profile.first_paragraph_1")}}
					<span v-if="this.strongRoles.length > 1">{{$t("wide_profile.roles_are_expressed")}}
						<span v-for="(role, i) in strongRoles" :key="i">
							{{ i > 0 ? ', ' : '' }}
							<router-link :to="'/types/' + role" target="_blank" class="type">{{ this.displayTypeName(role)
							}}</router-link>
						</span>
					</span>
					<span v-else>{{$t("wide_profile.role_is_expressed")}}
						<router-link v-if="this.strongRoles == 'creator'" :to="'/types/' + this.strongRoles" target="_blank"
							class="type">{{$t('wide_profile.creator_a')}}</router-link>
						<router-link v-else :to="'/types/' + this.strongRoles" target="_blank" class="type">{{
							this.displayTypeName(this.strongRoles[0]) + 'а' }}</router-link>
					</span>.

					{{ $t("wide_profile.first_paragraph_2") }}
				</div>
				<br>
				<div v-if="this.wide_profile.noWeak">
					{{ $t("wide_profile.no_weak_roles") }}
					<span v-for="(role, i) in Object.keys(wide_profile.minRole)" :key="i">
						{{ i > 0 ? ', ' : '' }}
						<router-link :to="'/types/' + role" target="_blank" class="type">{{ this.displayTypeName(role)}}</router-link>
					</span>.

				</div>
				<div v-else>
					<div>
						<span v-if="this.weakRoles.length > 1">{{ $t("wide_profile.team_not_roles") }}</span>
						<span v-else>{{ $t("wide_profile.team_not_role") }}</span>
						<span v-for="(role, i) in weakRoles" :key="i">
							{{ i > 0 ? ', ' : '' }}
							<router-link :to="'/types/' + role" target="_blank" class="type">{{ this.displayTypeName(role)
							}}</router-link>
							
						</span>.

					</div>
					<div>
						
						<span v-if="this.weakRoles.length > 1">{{ $t("wide_profile.these_roles") }}</span>
						<span v-else>{{ $t("wide_profile.given_role") }}</span>
						{{ $t("wide_profile.can_lead_difficulties") }}
					</div>
				</div>

			</div>
		</div>

		<div class="mt-4 ">
			<div v-for="role in weakRoles" :key="i">

				<div :class="{ active: this.is_role[role] }" class="item mt-3" @click="toogle(role)">
					<div class="content">
						<span>
							{{ displayTypeName(role) }}
						</span>
						<div :style="{ 'background-color': colorOfType(role) }" class="color"></div>
					</div>
					<div class="arrow"></div>
				</div>


				<div v-if="this.is_role[role]" class="dropdown-items user__mainInfo mt-3" v-html="wide_profile.text[role]"></div>
			</div>
		</div>
	</div>

	<div v-else-if="this.type == 'narrow_profile'">
		<div class="user__mainInfo w-100">
			<div class="subtitle">
				<div>
					{{ $t("narrow_profile.first_paragraph_1") }}
					<span v-if="this.strongRoles.length > 1">{{ $t("narrow_profile.roles_are_expressed1") }}
						<span v-for="(role, i) in strongRoles" :key="i">
							{{ i > 0 ? ', ' : '' }}
							<router-link :to="'/types/' + role" target="_blank" class="type">{{ this.displayTypeName(role)
							}}</router-link>
						</span>
					</span>
					<span v-else>{{ $t("narrow_profile.role_is_expressed1") }}
						<router-link v-if="this.strongRoles == 'creator'" :to="'/types/' + this.strongRoles" target="_blank"
							class="type">{{$t('wide_profile.creator_a')}}</router-link>
						<router-link v-else :to="'/types/' + this.strongRoles" target="_blank" class="type"> <span>{{
							this.displayTypeName(this.strongRoles[0]) }}</span><span>{{ this.$t('narrow_profile.singular_role') }}</span></router-link>


					</span>.

				</div>
				<div>
					
					<span v-if="this.strongRoles.length > 1">{{$t('narrow_profile.roles_are_expressed2')}}</span>
					<span v-else>{{$t('narrow_profile.role_is_expressed2')}}</span>
					{{$t('narrow_profile.gives_certain_advantages')}}
					<span v-if="this.strongRoles.length > 1">{{$t('narrow_profile.them')}}</span>
					<span v-else>{{$t('narrow_profile.her')}}</span>{{$t('narrow_profile.worth_considering_features')}}
				</div>

			</div>
		</div>

		<div v-for="(role, i) in strongRoles" :key="i">

			<div :class="{ active: this.is_role[role] }" class="item mt-3" @click="toogle(role)">
				<div class="content">
					<span>
						{{ displayTypeName(role) }}
					</span>
					<div :style="{ 'background-color': colorOfType(role) }" class="color"></div>
				</div>
				<div class="arrow"></div>
			</div>

			<div v-if="this.is_role[role]" class="dropdown-items mt-3">
				<div class="row mb-4 gy-4 gy-lg-0 mt-lg-4">

					<div class="col-12 col-lg-6">
						<UserBlock :is-green="true" :isSmallFont="true" :withoutPadding="true" class="mb-3" v-html=" narrow_profile.text[role][0]">
							
						</UserBlock>
					</div>

					<div class="col-12 col-lg-6">
						<UserBlock :is-red="true" :isSmallFont="true" :withoutPadding="true" class="mb-3" v-html="narrow_profile.text[role][1]">
						
						</UserBlock>
					</div>
				</div>

			</div>
		</div>

		<div class="user__mainInfo mt-3">
			<div class="subtitle">
				{{$t('narrow_profile.strengths')}}
				<span v-for="(role, i) in weakRoles" :key="i">
					{{ i > 0 ? ', ' : '' }}
					<router-link :to="'/types/' + role" target="_blank"  class="type">{{ this.displayTypeName(role)
							}}</router-link>
				</span>.
			</div>
		</div>

	</div>

	<div class="user__mainInfo w-100 my-4 ">
		<div class="subtitle">
			{{$t('main_info.first_paragraph_1')}}
			<router-link to="/users" target="_blank" class="type">{{$t('main_info.genius_profile')}}</router-link>
			<span v-html="$t('main_info.first_paragraph_2')"></span>
		</div>
	</div>

</template>

<script>
import typesUtils from '@/mixins/typesUtils';
import UserBlockTitle from "@/components/User/UserBlockTitle";
import UserBlock from "@/components/User/UserBlock";

export default {
	components: { UserBlockTitle, UserBlock },
	mixins: [typesUtils],
	props: {
		resultsProps: {
			type: Object,
			require: true
		}
	},
	data() {
		return {
			percentRole: { },
			type: '',
			roles: ["creator", "driver", "teambuilder", "networker", "realizator", "checkman", "analyst", "improver"],
			strongRoles: [],
			weakRoles: [],
			wide_profile: {
				noWeak: false,
				minRole: {},
				maxRole: {},
				text: {
					"creator": this.$t('creator.gray_boarder'),
					"driver":  this.$t('speaker.gray_boarder'),
					"teambuilder":  this.$t('teambuilder.gray_boarder'),
					"networker":  this.$t('networker.gray_boarder'),
					"realizator":  this.$t('assistent.gray_boarder'),
					"checkman":  this.$t('checkman.gray_boarder'),
					"analyst":  this.$t('builder.gray_boarder'),
					"improver":  this.$t('improver.gray_boarder'),
				},
			},
			narrow_profile: {
				text: {
					"creator": [this.$t('creator.green_boarder'), this.$t('creator.red_boarder')],
					"driver": [this.$t('speaker.green_boarder'), this.$t('speaker.red_boarder')],
					"teambuilder": [this.$t('teambuilder.green_boarder'), this.$t('teambuilder.red_boarder')],
					"networker": [this.$t('networker.green_boarder'), this.$t('networker.red_boarder')],
					"realizator": [this.$t('assistent.green_boarder'), this.$t('assistent.red_boarder')],
					"checkman": [this.$t('checkman.green_boarder'), this.$t('checkman.red_boarder')],
					"analyst": [this.$t('builder.green_boarder'), this.$t('builder.red_boarder')],
					"improver": [this.$t('improver.green_boarder'), this.$t('improver.red_boarder')]
				}
			},
			is_role: {
				"creator": false,
				"driver": false,
				"teambuilder": false,
				"networker": false,
				"realizator": false,
				"checkman": false,
				"analyst": false,
				"improver": false
			},
		}
	},
	mounted() {
		this.defineType();
	},
	//computed: {
	//	weakRoles() {
  //  return this.objectArray.slice().reverse();

	//	}
	//},
	methods: {
		toogle(val) {
			this.is_role[val] = !this.is_role[val];
		},
		findMax() {
			let max = 0;
			let role = '';
			let min = 100;
			let minRole = '';

			for (let i in this.resultsProps) {
				if (this.resultsProps[i] > max) {
					max = this.resultsProps[i]
					role = i;
				}
				if (this.resultsProps[i] < min) {
					min = this.resultsProps[i];
					minRole = i;
				}
			}
			this.findPercent(max)
			this.wide_profile.maxRole[role] = max;
			this.wide_profile.minRole[minRole] = min;
		},
		findPercent(max) {
			for (let i in this.resultsProps) {
				this.percentRole[i] = (100 * this.resultsProps[i] / max).toFixed()
			}

			delete this.percentRole.verified;
			const arr = Object.entries(this.percentRole);

			// Сортируем массив пар по значениям
			arr.sort((a, b) => b[1] - a[1]);

			// Преобразуем отсортированный массив обратно в объект
			this.percentRole = Object.fromEntries(arr);

			// определеяем >70% 
			for (let i in this.percentRole) {
				if (this.percentRole[i] > 70) {
					this.strongRoles.push(i);
					//this.narrow_profile.strongRoles.push(i);
				}
			}

			let copyWeakRoles = [];
			// определяем меньше 50%
			for (let i in this.percentRole) {
				if (this.percentRole[i] < 50) {
					copyWeakRoles.push(i);
				}
			}

			this.weakRoles = copyWeakRoles.reverse();

			if (this.weakRoles.length == 0) {
				this.wide_profile.noWeak = true;
			}

		},
		defineType() {
			this.findMax();

			let count = 0;
			for (let i in this.percentRole) {
				if (this.percentRole[i] > 50) {
					count++;
				}
			}

			if (count >= 4) {
				this.type = 'wide_profile'
			} else {
				this.type = 'narrow_profile'
			}
		}
	}
}
</script>

<style lang="sass" scoped>
.user 
	&__mainInfo 
		background-color: #fff
		border-radius: 21px
		display: flex
		flex-direction: column
		padding: 30px
		font-size: 14px

		.title 
			font-weight: bold
			font-size: 16px
			margin: 0 0 20px
		

		.subtitle 
			font-size: 14px


.type 
	text-decoration: underline
	cursor: pointer
	font-size: 16px
	color: #090D39

.color
	width: 20px
	height: 20px
	border-radius: 20px

.item
	font-weight: 500
	display: flex
	align-items: center
	justify-content: space-between
	background-color: #fff
	font-size: 18px
	padding: 5px 20px 5px 30px
	max-width: 300px
	border-radius: 15px
	cursor: pointer

	&:hover
		transform: scale(102%)

	&.active
		.arrow
			transform: rotate(45deg) translate(-5px, -5px)

			&:before
				transform: translate(10px, 0)

			&:after
				transform: rotate(90deg) translate(10px, 0)

	.arrow
		transform: rotate(0)
		left: 0

		&:before, &:after
			background-color: transparent
			width: 30px
			height: 10px
			display: inline-block
			position: absolute
			border-bottom: 12px solid white
			top: 0
			left: 0
			transform: rotate(0)

		&:before
			transform: rotate(-135deg)

		&:after
			transform: rotate(135deg)

	&.active
		.arrow
			transform: rotate(0)
			transform: translate(0, -6px)

			&:before
				transform: rotate(-45deg)

			&:after
				transform: rotate(45deg)


	.arrow
		transform: rotate(0)
		left: 0

		&:before, &:after
			background-color: transparent
			width: 3px
			height: 21px
			display: inline-block
			position: absolute
			border-bottom: 12px solid black
			top: 0
			left: 0
			transform: rotate(0)

		&:before
			transform: rotate(-135deg)

		&:after
			transform: rotate(135deg)

	&.active
		.arrow
			transform: rotate(0)
			transform: translate(0, -6px)

			&:before
				transform: rotate(-45deg)

			&:after
				transform: rotate(45deg)

.content
	width: 100%
	display: flex
	align-items: center
	justify-content: space-between
	padding-right: 20px

.arrow
	width: 3px
	height: 13px
	display: inline-block
	position: relative
	bottom: 1px
	transition: 0.4s ease
	margin-top: 2px
	text-align: left
	transform: rotate(45deg)
	float: right

	&:before, &:after
		position: absolute
		content: ''
		display: inline-block
		width: 12px
		height: 3px
		background-color: #1E1E1E
		transition: 0.4s ease

	&:after
		position: absolute
		transform: rotate(90deg)
		top: -5px
		left: 5px

.dropdown-items
	transition: 0.4s ease-out

	.item
		margin-top: 7px

		.content
			span
				margin-right: 20px





</style>