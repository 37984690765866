<template>

  <ResultTestCompetence v-if="results" :result="results" ></ResultTestCompetence>

  <div v-else class="plug">
    <div class="row">
      <div class="col-12 col-lg-6 p-0">
        <div class="plug__text my-auto mx-auto h-100">
          <div class="plug__title">Тест на компетенции (демо-режим)</div>
          <div>
            <p class="mb-3" >
              Хотите узнать, насколько хорошо вы работаете в команде, управляете временем и справляетесь со стрессом? Наш тест на компетенции - это уникальный инструмент, который поможет вам оценить свои soft skills и получить ценную информацию для личного и профессионального роста.
              <br><br>
              Тест поможет вам выявить ключевые направления для вашего роста и развития навыков. Это укрепит вашу уверенность, повысит мотивацию и даст объективную оценку вашего потенциала. Пройдя тест, вы получите ценные инсайты для построения карьеры и достижения целей.
            </p>
          </div>

        </div>
      </div>

      <div class="col-12 col-lg-6 d-flex flex-column justify-content-between">
        <div>
          <div class="plug__warn w-100 bg-white">
            <img src="@/assets/user/visible.svg" alt="icon">
            <div>
              Ваш результат будет <b>виден только вам и вашему руководителю.</b>
            </div>
          </div>
          <div class="plug__warn w-100 bg-white">
            <img src="@/assets/user/clock.svg" alt="icon">
            <div>Прохождение занимает <b>примерно 15 минут.</b></div>
          </div>
          <div class="plug__warn w-100 bg-white">
            <img src="@/assets/user/info_black.svg" alt="icon">
            <div>
              <p class="mb-2">
                Старайтесь задуматься над каждым утверждением и оценить, на сколько оно подходит именно вам. Отвечайте честно и вдумчиво для получения наиболее достоверного результата.
              </p>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <button class="plug__button mt-3 mt-lg-0"
                  @click="this.$router.push('/competence?id=1')"
          >{{$t("popup_info.btn_start") }}
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import CardTest from "@/components/User/TestCompetence/CardsTest.vue";
import ResultTestCompetence from "@/components/User/TestCompetence/ResultTestCompetence.vue";

export default  {
  props: {
    results: {
      type: Object,
      required: true
    },
  },
  components: {
    CardTest,
    ResultTestCompetence
  },
  data() {
    return {
      isShow: {
        softSkills: false,
        manageCompetence: false,
      },
      id: 0
    }
  },
  methods: {
  },
  computed: {
  },
}

</script>

<style scoped lang="scss">

.plug {
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 30px;

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    margin: 0 0 15px;

  }

  &__text {
    font-weight: 400;
    font-size: 13px;
    padding: 16px 22px;
    margin: 0;
    border-radius: 20px;
    text-align: justify;
  }

  &__warn {
    font-weight: 400;
    font-size: 13px;
    border-radius: 13px;
    padding: 16px 22px;
    display: flex;
    text-align: justify;

    p {
      margin: 0;
    }

    img {
      margin-right: 20px;
      align-self: start;
    }

  }

  &__button {
    background: #090D39;
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    padding: 15px 120px;
  }
}

</style>