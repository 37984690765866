<template>
  <div id="test" class="container test m-3" :class="{'page-blocked': isPageBlocked}">
      <div :class="{ 'shake': isNeedGiveAnswer }" class="test__content">
        <div class="test__text-content">
          <div class="test__question text-center">
            {{ this.questions[this.currentQuestion].question}}
          </div>
          <div v-show="isNeedGiveAnswer" class="alert alert-danger" role="alert">
            Вы не можете пропустить вопрос
          </div>

          <div class="test__answers">
            <div id="circles-container" class="answers">



              <div id="answers" class="ans__buttons" >
                <button
                    v-for="index in 10"
                    :key="index"
                    @click="changeAnswer(this.currentQuestion, index)"
                    class="answers__button"
                    :class="{ 'active': index <= (this.questions[this.currentQuestion].answer ? this.questions[this.currentQuestion].answer : this.selectedCount), 'hoverCount': index <=  hoverSelectCount && selectedCount == null }"
                >
                  {{ index }}
                </button>
              </div>

              <div id="not-agree" class="answers__text">
                Абсолютно<br>не согласен
              </div>

              <div id="agree" class="answers__text">
                Полностью<br>согласен
              </div>

            </div>
          </div>

        </div>
      </div>

      <div class="test__control">

        <button id="back" class="control__button" @click="prevQuestion">
          <img alt="" class="control__arrow back" src="../../assets/back.svg">
        </button>

        <test-progress-bar :class="'d-lg-flex'" :current-question="currentQuestion" :questions-length="questions.length" ></test-progress-bar>


        <button v-show="(currentQuestion < questions.length - 1)" id="next" class="control__button" @click="nextQuestion">
          <img alt="" class="control__arrow next" src="../../assets/next.svg">
        </button>

        <button v-show="(currentQuestion === questions.length - 1) "
                id="next"
                class="control__button finish"
                :class="{'disable': this.questions[this.currentQuestion].answer == 0}"
                :disabled="this.questions[this.currentQuestion].answer == 0"
                @click="sendResults"
        >Завершить
        </button>
      </div>
  </div>
</template>

<script>
import TestProgressBar from "@/components/Test/TestProgressBar.vue";
import {mapActions, mapGetters} from "vuex";


export default {
  components: {
    TestProgressBar
  },
  data() {
    return {
      competence: [],
      numberQuestions: [],
      questions: {},
      results: {},
      isPageBlocked: false,
      isNeedGiveAnswer: false,
      currentQuestion: 0,
      selectedCount: null,
      hoverSelectCount: null,
    }
  },
  created() {
    this.getQuestions();
  },
  mounted() {
    this.addListeners();
    this.clearAnswer();
  },
  methods: {
    ...mapActions({
      onSendSoftSkillsResult: 'AuthModule/onSendCompetenceSoftSkills'
    }),

    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },

    getQuestions() {
      this.questions = require(`@/assets/data/question_test_competencies/questions_soft_skills.json`);
      this.questions = this.shuffleArray(this.questions);
    },

    changeAnswer(number_question, answer) {
      this.selectedCount = answer;

      this.questions[this.currentQuestion].answer = answer;
      this.isNeedGiveAnswer = false;
    },

    prevQuestion() {
      this.isNeedGiveAnswer = false;
      if (this.currentQuestion > 0) {
        this.currentQuestion--;
      }
    },
    nextQuestion() {
      if ((this.currentQuestion < this.questions.length  - 1) && (this.questions[this.currentQuestion].answer !== "")) {
        this.currentQuestion++;
        this.selectedCount = null;
        this.hoverSelectCount = null;
      } else {
        this.isNeedGiveAnswer = true;
      }
    },
    addListeners() {
      const btnAnswers = document.getElementById('answers');

      btnAnswers.addEventListener("mouseover", (event) => {
        let target = event.target;

        if (target.tagName === 'BUTTON' ) {
          this.hoverSelectCount = target.innerText;
        }
      });

      btnAnswers.addEventListener("mouseout", (event) => {
        let target = event.target;

        if (target.tagName === 'DIV' ) {
         this.hoverSelectCount = null;
        }
      });
    },

    sendResults() {
      this.isPageBlocked = true;
      this.results.answers = [];
      this.questions.forEach(question => {
        this.results.answers.push({
          number: question.number,
          answer: question.answer
        })
      })
      this.onSendSoftSkillsResult({data: this.results})
    },
    clearAnswer() {
      for (let elem of this.questions) {
        elem["answer"] = "";
      }
    },

  },
  computed: {

    ...mapGetters(
        {
          isAllowedForTests: "AuthModule/isAllowedForTests",
        }
    ),
  }


}
</script>

<style scoped lang="scss">

.slide-fade-enter-active {
  transition: all .4s ease;
}

.slide-fade-leave-active {
  transition: all .4s ease
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}

.test {
  margin: 60px auto 0;
  padding: 0;
  display: flex;
  flex-direction: column;


  &__control {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(8px + 15 * (100vw / 2560));
    flex-wrap: wrap;
  }


  &__content {
    border-radius: 10px;
    display: flex;
    flex-direction: column-reverse;
  }

  &__text-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__question {
    width: 350px;
    min-height: 80px;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 20px;
    font-weight: bold;
    font-size: calc(10px + 22 * (100vw / 3500));
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.answers {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  flex-wrap: wrap;

  &__text {
    display: flex;
    align-self: normal;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }

  &__button {
    width: 30px;
    height: 30px;
    font-size: 12px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #000000;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    transition: all 0.3s linear;
    z-index: 2;
  }
}

.ans__buttons {
  width: 400px;
  display: flex;
  justify-content:center;
  padding: 5px;
}

.active {
  color: #FFFFFF;
  background-color: #1C2E76;
  border-color: #1C2E76;
}
.hoverCount {
  color: #FFFFFF;
  background-color: #C4CBE0;
  border-color: #C4CBE0;
}

.page-blocked {
  pointer-events: none;
  opacity: 0.5;
}

.shake {
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 10000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}


.control {
  &__button {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 10px 10px;
    border-radius: 50%;
    transition: all .1s linear;
    white-space: nowrap;
    margin-right: 20px;
    margin-left: 20px;

    &:hover {
      transform: scale(103%);
    }

    &.finish {
      background: #1C2E76;
      font-weight: bold;
      color: white;
      border-radius: 10px;
    }

    &.disable {
      background: #cccccc;
      color: #969696;
    }
  }
}


@media (min-width: 529px) {
  .ans__buttons {
    width: 600px;
    padding: 20px;
  }
}

@media (min-width: 768px) {




  .test {
    //max-width: 100%;

    &__control {
      margin: 30px 0;
    }

    &__content {
      border-radius: 18px;
    }

    &__img {
      object-fit: cover;
      width: 100%;
      min-height: 400px;
      max-height: 500px;
    }

    &__text-content {
      padding: 15px 20px;
    }

    &__question {
      width: 600px;
      margin-bottom: 45px;
    }
  }

  .control__button {
    border-radius: 18px;
    padding: 10px 30px;

  }

  .answers {
    justify-content: space-between;

    &__button {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      font-size: 16px;
    }
  }

  .ans__buttons {
    width: 600px;
    padding: 20px;
  }
}

@media (min-width: 868px) {

  #back {
    order: 1;
  }

  #next {
    order: 3;
  }

  .test__control {
    flex-wrap: nowrap;
    align-items: center;
    margin: 20px 0 0;
    max-height: 500px;
  }

  .control__button {
    &.finish {
      border-radius: 18px;
    }
  }

  .test {
    display: flex;
    justify-content: center;

    &__content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0 auto;
    }
  }

}

@media (min-width: 992px) {

  #not-agree {
    order:1
  }

  #answers {
    order: 2;
  }

  #agree {
    order: 3;
  }
}

@media(min-width: 1440px) {

  .test__text-content {
    margin-top: 0;
    margin-bottom: 0;
  }
}

</style>