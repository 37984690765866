<template>
  <div id="view" :key="user.id" v-for="user in users" class="team_user my-2 my-lg-3 mx-3 ">

    <UserTableUsers
        :id="user.id"
        :can-manage-role="false"
        :firstName="user.firstName" :is-editing="false"
        :is-verified="user.talentVerified"
        :lastName="user.lastName"
        :position="user.position"
        :result="displayTypeName(user.result)"
         @open=" () => { $router.push('/users/' + user.id)} "
        :role="user.role"
        :talent="user.talent">
    </UserTableUsers>

  </div>


</template>

<script>
import UserTableUsers from '@/components/User/UserInTeamCard.vue';
import typesUtils from "@/mixins/typesUtils";
import api from "@/api";
import {required} from "@vuelidate/validators";

export default {
  mixins: [typesUtils],
  name: "TeamView2",
  components: {UserTableUsers},
  props:{
    users: {
      type : Array,
      required: true
    }
  },


}
</script>

<style scoped>

</style>