<template>
  <div class="d-flex justify-content-xl-end justify-content-center p-0">
    <div class="standartPlan" :class="{'VIPPlan': isPremium}">
      <div>
        <p class="h3">{{ title }}</p>
        <div v-if="isBasicPlans" class="price h6">
          <p class="salePrice">
            <span class="discount-price">{{ discountPrice}}</span>
            <span class="sale-info">{{ saleInfo }}</span>
          </p>

          <p class="salePrice">
            <span class="basic-price" data-i18n="plans.price_0"></span>
            <span data-i18n="plans.basic_promocode" class="sale-info"></span>
          </p>

          <del class="sale basic-price">{{ basicPrice }}</del>

          <del class="sale discount-price" data-i18n="plans.basic_price"></del>
        </div>
        <div v-else class="price h6">
          <p class="salePrice">
            <span class="discount-price">{{ discountPrice}}</span>
            <span class="sale-info">{{ saleInfo }}</span>
          </p>
          <del class="basic-price">{{ basicPrice }}</del>
        </div>

        <div class="planDescription" style="font-weight: 400;">
          <List :list-info="basicInfo" path="multicolored-checkmark"></List>

          <div v-if="isStandart && !isLocaleEn" class="discount">
            <div class="discount__title">
              Особая выгода!
            </div>
            <div class="discount__price">
              10 сотрудников за <b>10 000 ₽</b>
            </div>
            <div class="discount__without">
            / <del>15 000 ₽</del>
            </div>
          </div>

        </div>
      </div>
      <div class="orderPlan">
        <ButtonDemo :title="$t('plans.choose_plan')" @click="sendForm"></ButtonDemo>
      </div>
    </div>
  </div>

</template>

<script>

import List from "@/components/Landings/component/List.vue";
import ButtonDemo from "@/components/Landings/component/ButtonDemo.vue";

export default {
  components: {ButtonDemo, List},
  props: {
    basicInfo: {
      type: Array,
      reqiured: true
    },
    title: {
      type: String,
      reqiured: true
    },
    basicPrice: {
      type: String,
      default: ''
    },
    discountPrice: {
      type: String,
      default: ''
    },
    saleInfo: {
      type: String,
      default: ''
    },
    isBasicPlans: {
      type: Boolean,
      reqiured: true
    },
  },
  data() {
    return {
      isLocaleEn: false,
    }
  },
  created() {
    this.getLocale();
  },
  computed: {
    isPremium() {
      if (this.title === 'Премиум') {
        return true;
      }
    },
    isStandart() {
      if (this.title === 'Стандарт') {
        return true;
      }
    }

  },
  methods: {
    sendForm() {
      this.$emit('sendForm', this.title)
    },
    getLocale() {
      const currentLocale = this.$i18n.locale;
      this.typesInfo = require(`@/i18n/locales/${currentLocale}/result_genius.json`);
      if (currentLocale == 'en') {
        this.isLocaleEn = true;
      }
    },
  }
}
</script>

<style scoped lang="scss">

.standartPlan {
  min-width: 300px;
  width: 350px;
  height: 760px;
  padding: 20px 30px;
  text-align: center;
  border: 4px #202FD4 solid;
  border-radius: 30px;
  position: relative;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.discount {
  padding: 20px 20px;
  background-image: linear-gradient(97.19deg, #B927B5 7.13%, #3E77FF 95.26%);
  border-radius: 20px;
  margin-top: 30px;

  &__title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 3px;
  }

  &__price {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
  }

  &__without {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
  }
}

.price {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.salePrice {
  margin-bottom: 8px;
}

.discount-price {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;

}

.sale {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
  text-decoration-color: #D9D9D9;
}

.basic-price {
  font-size: 14px;
  font-weight: 400;
  color: #D9D9D9;
}

.sale-info {
  font-size: 16px;
  font-weight: 400;
}

.VIPPlan {
  transition: 0.2s;
  background: linear-gradient(black, black) padding-box,
  linear-gradient(to right, darkorchid, #2835C1) border-box;
  border: 4px solid transparent;
}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

  .standartPlan {
    min-width: 250px;
    width: 300px;
    height: 790px;
    padding: 20px 15px;
  }

  .orderPlan {
    left: 18%;
    bottom: 15px;
  }

}

@media (min-width: 1200px) {
  .standartPlan {
    min-width: 300px;
    width: 350px;
    height: 760px;
    padding: 20px 30px 20px;
  }

  .orderPlan {
    left: 25%;
    bottom: 20px;
  }
}

</style>