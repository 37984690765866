<template>


  <div v-if="result" class="results">
    <div class="blocks">
      <div v-for="elem in resultCompetence" >
        <div class="block__competencies">
            <div v-for="competence in elem" class="competencies">
              <div class="competencies__title" @click="openResult(competence.name)">
                <p class="m-0">{{ competence.name }}</p>

                <div class="d-flex justify-content-between">
                  <div class="progress__back" :style="{width: maxWidth + 'px'}">
                      <div  class="progress__block" :style="{width: getWidth(competence.totalResult) + 'px'}">{{competence.totalResult}}%</div>
                  </div>

                  <div>
                    <img src="@/assets/user/test-competence/arrow.svg" id="rotateImage" :class="{ arrow: isShowResult[competence.name] }" alt="arrow">
                  </div>
                </div>
              </div>

              <div class="competencies__result" >
                <transition-group name="fade">
                  <div v-for="answer in competence.answers" class="competencies__result__answers" v-if="isShowResult[competence.name]">
                    <div class="question">{{this.questions[answer.question]}}</div>
                      <div class="answer" :class="{'attention': isLittleResult(answer.answer)}">
                        {{ answer.answer }} /10
                      </div>
                  </div>
                </transition-group>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="plug">
    <div class="row">
      <div class="col-12 col-lg-6 ">
        <div class="plug__text bg-white my-auto mx-auto h-100">
          <div class="plug__title">Тест на компетенции (демо-режим)</div>
          <div>
            <p class="mb-3" >
              Хотите узнать, насколько хорошо вы работаете в команде, управляете временем и справляетесь со стрессом? Наш тест на компетенции - это уникальный инструмент, который поможет вам оценить свои soft skills и получить ценную информацию для личного и профессионального роста.
              <br><br>
              Тест поможет вам выявить ключевые направления для вашего роста и развития навыков. Это укрепит вашу уверенность, повысит мотивацию и даст объективную оценку вашего потенциала. Пройдя тест, вы получите ценные инсайты для построения карьеры и достижения целей.
            </p>
          </div>

        </div>
      </div>

      <div class="col-12 col-lg-6 mt-3 mt-lg-0 d-flex flex-column justify-content-between">
        <div>
          <div class="plug__warn w-100 bg-white">
            <img src="@/assets/user/visible.svg" alt="icon">
            <div>
              Ваш результат будет <b>виден только вам и вашему руководителю.</b>
            </div>
          </div>
          <div class="plug__warn w-100 bg-white">
            <img src="@/assets/user/clock.svg" alt="icon">
            <div>Прохождение занимает <b>примерно 15 минут.</b></div>
          </div>
          <div class="plug__warn w-100 bg-white">
            <img src="@/assets/user/info_black.svg" alt="icon">
            <div>
              <p class="mb-2">
                Старайтесь задуматься над каждым утверждением и оценить, на сколько оно подходит именно вам. Отвечайте честно и вдумчиво для получения наиболее достоверного результата.
              </p>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <button class="plug__button w-100 mt-3 mt-lg-0"
                  @click="restartTest()"
          >{{$t("popup_info.btn_start") }}
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script >
import Tooltip from "@/components/UI/Tooltip.vue";
import ModalInfo from "@/components/UI/Modal/ModalInfo.vue";

export default {
  components: {ModalInfo, Tooltip},
  props: {
    result: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      resultCompetence: {
        firstPart: [],
        secondPart:[]
      },
      firstPartCompetence: [],
      secondPartCompetence: [],

      questions: {},
      isShowModal: false,
      isShowResult: {},
    }
  },
  methods: {
    getQuestions(){
      if (this.result) {
        let questions = require(`@/assets/data/question_test_competencies/questions_soft_skills.json`);

        this.result['usersResult'].forEach(elem => {

          for (let answer of elem.answers) {
            const obj = questions.find(obj => obj.number == answer.question);
            this.questions[answer.question] = obj.question;
          }
        })
      }
    },
    getWidth(result) {
      if(result * (this.maxWidth/100) < 42) {
        return 42;
      }
      return result * (this.maxWidth/100);
    },
    isLittleResult(result) {
      if (Number(result) < 5) return true;
    },
    setIsShow() {
      if (this.result) {
        this.result['usersResult'].forEach(elem => {
          this.isShowResult[elem.name] = false;
        })
      }
    },
    openResult(name) {
      this.isShowResult[name] = !this.isShowResult[name];
    },
    openAll() {
      if (!this.isOpen) {
        for(let i in this.isShowResult) {
          this.isShowResult[i] = true;
        }
        this.isOpen = true;
      } else {
        for(let i in this.isShowResult) {
          this.isShowResult[i] = false;
        }
        this.isOpen = false;
      }
    },
    restartTest() {
      this.$router.push('/competence')
    },
    divideArrayPart() {
      if (this.result) {
        for (let i = 0; i < this.result['usersResult'].length; i++) {
          if (i % 2 === 0) {
            this.resultCompetence.firstPart.push(this.result['usersResult'][i]);
          } else {
            this.resultCompetence.secondPart.push(this.result['usersResult'][i]);
          }
        }
      }
    }
  },
  computed: {
    maxWidth(){
      if(window.innerWidth > 1200 ) {
        return 220
      } else return 150;
    },
    tooltipOpenAll(){
      if(this.isOpen) {
        return "Скрыть всё"
      } else {
        return "Открыть всё"
      }
    }
  },
  mounted() {
    this.setIsShow();
    this.divideArrayPart();
    this.getQuestions();
  }
}
</script>

<style scoped lang="scss">

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.results {
  &__title {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-bottom: 5px;
    flex-wrap: wrap;
  }

  .back {
    margin: auto 0;

    &__button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      color: #070C1F;
      padding: 5px 11px;
      border: none;
      background: #FFFFFF;
      border-radius: 11px;
      transition: 0.2s all linear;

      &:hover {
        transform: scale(98%);
      }

      & img {
        max-height: 20px;
        margin-right: 5px;
      }
    }
  }

  .title {
    margin-right: 30px;
    font-size: 27px;
  }



  &__block {
    display: flex;
    flex-direction: column;
  }
}

.blocks {
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  align-items: center;

  .block__competencies {
    display: flex;
    flex-direction: column;
  }
}

.competencies {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;

  &__title {
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 15px;
    transition: all 0.2s linear;
    font-size: 12px;

    &:hover {
      transform: scale(1.02);
    }
  }

  &__progress {
    display: flex;
    justify-content:space-between;
    align-items: center;


  }

  &__result {
    max-width: 400px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__answers {
      background:#fff;
      padding: 20px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
    }

    .question {
      max-width: 250px;
      display: flex;
      align-items: center;
      font-size: 12px;
    }

    .answer {
      border: 2px solid rgb(10, 15, 63);
      border-radius: 8px;
      color: rgb(10, 15, 63);
      font-weight: 500;
      padding: 10px;
      margin-left: 5px;
      height: 48px;
    }
  }
}

.progress {
  width: 80%;
  height: 25px;
  border-radius: 30px;
  margin-left: 20px;

  &__back {
    background-color: #EAEDF6;
    border-radius: 8px;
    margin-right: 20px;
    width: 50%;
    transition: all 0.2s linear;
  }

  &__block{
    text-align: center;
    color:white;
    font-weight: 700;
    font-size: 20px;
    border-radius: 8px;
    background-color: #0A0F3F;
  }
}

.verify__button {
  font-weight: 500;
  font-size: 16px;
  padding: 5px 20px;
  border: none;
  border-radius: 11px;

  &:hover {
    transform: scale(102%);
  }

  &:active {
    transform: scale(105%);
  }

  &.restart {
    color: #000000;
    background: #FFFFFF;
  }
}

.attention {
  border: 2px solid #a62b2b !important;

  color: #58151c !important;
}

#rotateImage {
  transition: transform 0.3s ease-in;
}

.arrow {
  transform: rotate(180deg);
}

.open_all {
  transform: rotate(180deg);
}

.plug {
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //background-color: #fff;
  padding: 30px;

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    margin: 0 0 15px;

  }

  &__text {
    font-weight: 400;
    font-size: 13px;
    padding: 16px 22px;
    margin: 0;
    border-radius: 20px;
    text-align: justify;
  }

  &__warn {
    font-weight: 400;
    font-size: 13px;
    border-radius: 13px;
    padding: 16px 22px;
    display: flex;
    text-align: justify;
    margin-bottom: 12px;

    p {
      margin: 0;
    }

    img {
      margin-right: 20px;
      align-self: start;
    }

  }

  &__button {
    background: #090D39;
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    padding: 15px 120px;
  }
}

@media (min-width: 550px) {
  .competencies {
    width: 500px;

    &__title {
      font-size: 16px;
    }

    &__result {
      max-width: 500px;

      .question {
        max-width: 370px;
        font-size: 16px;
      }
    }
  }
}

@media (min-width: 768px) {
  .plug__text {
    padding: 31px 50px 50px;
  }
}

@media (min-width: 992px) {
  .blocks {
    flex-direction: row;
    justify-content:space-around;
    align-items: flex-start;
  }

  .competencies {
    width: 450px;

    &__result {
      max-width: 450px;

      .question {
        max-width: 300px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .competencies {
    width: 500px;

    &__result {
      max-width: 500px;

      .question {
        max-width: 350px;
      }
    }
  }
}

@media (min-width: 1400px) {
  .competencies {
    width: 600px;

    &__result {
      max-width: 550px;

      .question {
        max-width: 400px;
      }
    }
  }
}
</style>