<template>
	<div id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel" class="offcanvas offcanvas-start"
		data-bs-backdrop="true" data-bs-scroll="false" tabindex="-1">


		<div class="offcanvas-header ">
			<div class="logo" @click="this.$router.push('/')">
				Team Wisdom
			</div>
			<button id="offcanvasHide" aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" type="button"
				@click="isMenuOpened = false"></button>
		</div>
		<hr class="d-none d-md-block">
		<div class="offcanvas-body ">
			<div class="d-md-none mobile__menu flex-grow-1 mt-1">
				<nav class="mobile-nav">
					<div :class="{ active: isTeamPage }" class="mobile-nav__item" @click="this.$router.push('/teams'); hideMenu()">
						<a>{{ $t('menu_side_bar.team') }}</a>
					</div>
					<div :class="{ active: isUserPage && !isRequestsPage }" class="mobile-nav__item"
						@click="this.$router.push('/users'); hideMenu()">
						<a>{{ $t('menu_side_bar.user_profile') }}</a>
					</div>
					<div :class="{ active: isRequestsPage }" class="mobile-nav__item"
						@click="this.$router.push('/requests'); hideMenu()">
						<a class="position-relative">
							{{ $t('menu_side_bar.Invites') }} <span v-if="isHaveOpenedJoinRequests"
								class=" position-absolute  start-100 translate-middle p-1 bg-danger rounded-circle notification">
								<span class="visually-hidden">New alerts</span>
							</span></a> 
					</div>
				</nav>

			</div>
      <div v-if="isShowRegister" class="form-text text-center mt-3 mt-lg-0" style="color: rgba(28, 29, 34, 0.5); font-size: 14px;
line-height: 16px; margin-bottom: 16px;" v-html="$t('menu_side_bar.text_registered_company')"></div>
			<div v-if="isShowRegister" class="mobile-nav__item blue" @click="this.$router.push('/teams?register=true'); hideMenu()">
				<a style="color: white">{{$t("menu_side_bar.signUp")}}</a>
			</div>
			<TeamAndComapnyDropMenu @hideMenu="hideMenu"></TeamAndComapnyDropMenu>
		</div>


		<div class="offcanvas-footer">
			<div class="mobile-nav__item logout d-md-none" @click="this.logoutUser">
				<a>{{ $t('menu_side_bar.Sign_out') }}</a>
			</div>
			<div id="menuButton" aria-controls="offcanvasScrolling" class="menu-button d-none d-md-flex"
				data-bs-target="#offcanvasScrolling" data-bs-toggle="offcanvas" type="button"
				@click="isMenuOpened = !isMenuOpened">
				<div :class="{ opened: isMenuOpened }" class="menu-button__arrow"></div>
			</div>
		</div>
	</div>


</template>
<script>
import HeaderAccount from "@/components/UI/HeaderAccount.vue"
import UserMenu from "@/components/UI/TeamAndCompanyDropdownMenu.vue"
import { mapActions, mapGetters } from "vuex";

export default {
	name: 'OffCanvasMenu',
	components: {HeaderAccount, TeamAndComapnyDropMenu: UserMenu },
	props: {
		isHaveOpenedJoinRequests: { type: Boolean, },
		isMenuOpened: { type: Boolean, },
		isRequestsPage: { type: Boolean, },
		isTeamPage: Boolean,
		isUserPage: { type: Boolean, },
	},


  data() {
    return {


      offCanvas: {},
    }
  },
  computed:{
    ...mapGetters({
      getTeams: 'AuthModule/getTeams',
      getAdminCompanies: 'AuthModule/getAdminCompanies'


		}),
    isShowRegister(){
      return this.getTeams.length === 0 && this.getAdminCompanies.length === 0
    }

	},
	beforeMount() {
	},
	mounted() {
		this.offCanvas = document.getElementById('menuButton');

	},
	methods: {
		hideMenu() {
			this.offCanvas.click();
		},
		...mapActions({
			logoutUser: "AuthModule/onLogout"
		}),
	}
}
</script>
<style lang="scss" scoped>
.offcanvas {
	background: #EAEDF6;
	z-index: 9999;
}

.offcanvas-header {
	background-color: #0A0F3F;
	color: white;

	& button {
		background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
	}
}

.offcanvas-body {
	margin: 0 20px;
}

.notification {
	width: 12px !important;
	height: 12px !important;
	border: none !important;
	top: 15%;
	margin-left: 5px;
}

.mobile-nav {


	&__item {
		margin: 5px 0;
		background: rgba(217, 217, 217, 0.5);
		border-radius: 10px;
		text-align: center;
		padding: 15px 0;
		color: rgba(28, 29, 34, 0.5);
		font-weight: 700;
		font-size: 20px;

		&.logout {
			margin: 25px 20px;
		}

		&.active {
			background: #FFFFFF;
			color: #000000;
		}
    &.blue {
      color: #FFFFFF;
      font-weight: bold;
      background: #090D39;
      margin-bottom: 15px;

      &:hover {
        transform: scale(102%);
      }
    }
		& a {
			cursor: pointer;
			text-decoration: none;
			color: rgba(28, 29, 34, 0.5);
		}



	}
}

hr {
	margin: 0 20px;
}

.offcanvas {
	width: 100% !important;
}


.menu-button {
	position: relative;
	left: 100%;
	visibility: visible;
	width: 50px;
	height: 50px;
	background-color: #fff;
	border-radius: 50%;
	margin: 25px;

	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid rgba(28, 29, 34, 0.5);

	&:hover {
		box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.6);
		-moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.6);
		-webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.6);
		-o-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.6);
	}

	&__arrow {
		width: 10px;
		height: 10px;
		border: 3px solid rgba(28, 29, 34, 0.5);
		border-bottom: none;
		border-left: none;
		transform: rotate(45deg);
		position: relative;
		left: -1px;
		transition: all 0.3s ease;

	}
}

.opened {
	border: 2.99999px solid rgba(28, 29, 34, 0.5);
	border-top: none;
	border-right: none;
	left: 1px;
}


.logo {
	font-size: 22px;
	font-weight: 700;

	line-height: 32px;
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

	.offcanvas-header {
		background-color: unset;
		color: unset;

		& button {
			background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2300%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
		}
	}

	.mobile-nav {


		&__item.blue {
			border-radius: 15px;
			margin-top: 0;
			font-size: 16.5517px;
			padding: 10px 0;
		}
	}

	.offcanvas {
		width: 300px !important;
		background-color: #fff;
	}

	.notification {
		margin-left: 0;
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
	.logo {
		font-size: 28.0642px;
	}

	.offcanvas {
		width: 400px !important;
	}

}
</style>
