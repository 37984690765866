<template>
  <AppUI v-if="isAuth && !isLandingProfileGenius"></AppUI>

  <LandingHeader v-if="isLandingProfileGenius && !isAnyLoading"></LandingHeader>
   <router-view>

  </router-view>


</template>

<script>
import AppUI from '@/components/UI/AppUI.vue';
import {mapActions, mapGetters} from 'vuex';
import LandingHeader from "@/components/Landings/LandingHeader.vue";

export default {
components:{AppUI, LandingHeader},
  methods:{
  ...mapActions({
    'login': 'AuthModule/onLogin',
  })
  },
computed: {
		...mapGetters({
			isAuth: "AuthModule/isAuthenticated",
      getUsername: "AuthModule/getUsername",
      getPassword: "AuthModule/getPassword",
      isAnyLoading: 'isAnyLoading',
		}),
  isLandingProfileGenius() {
    let path = this.$route.path;
    return (path === '/genius-profile') || (path === '/company-registration') || (path === '/');
  }
	},
  mounted(){
  if(this.isAuth){
    this.login({
      email: this.getUsername,
      password: this.getPassword,
      redirect:false
    })
  }

  }
}

</script>

<style lang="scss">



.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
}
#app{
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
}
</style>
