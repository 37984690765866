<template>
  <div id="main" class="body">
    <loader v-show="isLoading"></loader>
    <div v-show="!isLoading" style="width: 100%;">
      <LandingTitleBlock></LandingTitleBlock>

      <LandingCorporateIndividual></LandingCorporateIndividual>

      <LandingFeature></LandingFeature>

      <FormDemo :is-show-background="false" :id-title="'form'"></FormDemo>

      <LandingPlans></LandingPlans>

      <LandingReviews></LandingReviews>

      <LandingSolveIssues></LandingSolveIssues>

      <LandingStart :is-show-background="false"></LandingStart>

      <LandingFooter></LandingFooter>

    </div>

  </div>

  <v-popup-demo :is-show-pop-up="isShowPopUp" @notShow="showPopup()" :parent-block="'Интересующий блок: Появляющийся через минуту попап'"></v-popup-demo>
</template>

<script>
import LandingTitleBlock from "@/components/Landings/LandingMain/LandingTitleBlock.vue";
import LandingCorporateIndividual from "@/components/Landings/LandingMain/LandingCorporateIndividual.vue";
import FormDemo from "@/components/Landings/FormDemo.vue";
import LandingPlans from "@/components/Landings/LandingPlans.vue";
import LandingReviews from "@/components/Landings/LandingReviews.vue";
import LandingStart from "@/components/Landings/LandingStart.vue";
import LandingFooter from "@/components/Landings/LandingFooter.vue";
import LandingFeature from "@/components/Landings/LandingMain/LandinFeature.vue";
import LandingSolveIssues from "@/components/Landings/LandingMain/LandingSolveIssues.vue";
import PopupDemo from "@/components/Landings/component/PopupDemo.vue";
import Loader from "@/components/UI/Loader.vue";
import {mapGetters} from "vuex";

export default {
  name: "Landing",
  components: {
    Loader,
    "vPopupDemo":PopupDemo,
    LandingTitleBlock, LandingCorporateIndividual, FormDemo, LandingPlans, LandingReviews, LandingStart, LandingFooter, LandingFeature, LandingSolveIssues},
  mounted() {
    setTimeout(() => {
      this.showPopup();
    }, 60000);
    setTimeout(() => {
      this.loading();
    }, 200);
  },
  data() {
    return {
      isShowPopUp: false,
      isLoading: true
    }
  },
  methods: {
    showPopup() {
      this.isShowPopUp = !this.isShowPopUp;
    },
    loading() {
      this.isLoading = !this.isLoading;
    }
  },
  computed: {
    ...mapGetters({
      isAnyLoading: 'isAnyLoading',
    })
  },
  metaInfo: function() {
    return {
      title: "Управление командой: платформа для организации эффективной работы сотрудников - TeamWisdom",
      meta: [
        { name: 'description', content: "Помогаем выявить сильные стороны ваших сотрудников и построить команду, где каждый на своём месте. Своевременный мониторинг показателей поможет предотвратить выгорание и улучшить результаты." }
      ]
    }}
}
</script>

<style scoped lang="scss">


.body{
  min-height: 100vh;
  height: 100%;
  background: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.body {
  background-image: url(@/assets/landing-main/background.svg);
  background-size: 100%;
  height: 100%;
  width: 100%;
}


//.body {
//  height: 100%;
//  color: white;
//  width: 100%;
//  background-color: #0B0215;
//  background-image: url(@/assets/landing-main/background.svg);
//  background-size: 100%;
//}


@media (min-width: 576px) {
}

@media (min-width: 768px) {
  section {
    margin-top: 70px;
  }
}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {
}
</style>