import { createI18n } from "vue-i18n";
import pluralRules from "@/i18n/rules/pluralization.js";
import numberFormats from "@/i18n/rules/numbers.js";
import datetimeFormats from "@/i18n/rules/datetime.js";
import ruUserAccount from "@/i18n/locales/ru/user_account.json";
import enUserAccount from "@/i18n/locales/en/user_account.json";
import ruMenu from "@/i18n/locales/ru/menu.json";
import enMenu from "@/i18n/locales/en/menu.json";
import ruTestManage from "@/i18n/locales/ru/test_manage.json";
import enTestManage from "@/i18n/locales/en/test_manage.json";
import ruLoginAccount from "@/i18n/locales/ru/login_account.json";
import enLoginAccount from "@/i18n/locales/en/login_account.json";
import ruError from "@/i18n/locales/ru/error.json";
import enError from "@/i18n/locales/en/error.json";
import ruInvitation from "@/i18n/locales/ru/invitation.json";
import enInvitation from "@/i18n/locales/en/invitation.json";
import ruCompanyRegistrationPage from "@/i18n/locales/ru/company_registration_page.json";
import enCompanyRegistrationPage from "@/i18n/locales/en/company_registration_page.json";
import ruTypeNames from "@/i18n/locales/ru/type_names.json";
import enTypeNames from "@/i18n/locales/en/type_names.json";
import ruCompanyData from "@/i18n/locales/ru/company_data.json";
import enCompanyData from "@/i18n/locales/en/company_data.json";
import ruRecommendations from "@/i18n/locales/ru/recommendations.json";
import enRecommendations from "@/i18n/locales/en/recommendations.json";
import ruLandingMain from "@/i18n/locales/ru/landing_main.json";
import enLandingMain from "@/i18n/locales/en/landing_main.json";

const VARS = require('../../jvjr-env.json');
// const defaultValue = 'en';
const defaultValue = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_DEFAULT_LOCALE : VARS["DEFAULT_LOCALE "].trim();
export default createI18n({
  locale: defaultValue, // <--- 1
  fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE, // <--- 2
  legacy: false, // <--- 3
	messages:{
		ru: {...ruUserAccount, ...ruMenu, ...ruTestManage, ...ruLoginAccount, ...ruError, ...ruInvitation, ...ruCompanyRegistrationPage, ...ruTypeNames, ...ruCompanyData, ...ruRecommendations, ...ruLandingMain},
		en: {...enUserAccount, ...enMenu, ...enTestManage, ...enLoginAccount, ...enError, ...enInvitation, ...enCompanyRegistrationPage, ...enTypeNames, ...enCompanyData, ...enRecommendations, ...enLandingMain}
	},
	pluralRules,
	datetimeFormats,
	numberFormats
})