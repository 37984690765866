<template>
  <main :class="{disabled : isFormDisabled, error : isAnyError && !isFormDisabled}"
        class="form__main bg-white my-4 d-flex flex-column align-items-center"  :style="{'min-height': minHeight + 'px' }">
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: "FormWrapper",

  props: {
    isFormDisabled: {
      type: Boolean,
      required: true
    },
    isAnyError: {
      type: Boolean,
      required: true
    },
    isShowFooter:{
      type: Boolean,
      default: true
    },
    minHeight:{
      type: Number,
      default: 500
    }
  }
}
</script>

<style lang="scss" scoped>
.form {


  &__main {
    padding: 30px 35px 20px 35px;

    border-radius: 36px;


    box-sizing: border-box;
    transition: all .3s linear;
    filter: drop-shadow(0px 0px 70px rgba(52, 75, 164, 0.32));

    &.disabled {
      filter: drop-shadow(0px 0px 64.5481px rgba(0, 0, 0, 0.16));
    }

    &.error {
      filter: drop-shadow(0px 0px 70px rgba(249, 41, 55, 0.25));
    }


  }
  &__footer {
    font-size: 16px;
    color: #9B9B9B;

    & a {
      transition: all .2s linear;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

  }
}

@media (min-width: 991.98px) {
  .form__main {
    padding: 40px 55px 35px 55px;

  }
}

</style>
