<template>
    <div id="card"  role="alert">
        <!--<div :class="{ 'resolved': !isOpened }" class="invite">Вас пригласили в команду <b>{{ team }}</b> из компании <b>{{ company }}</b></div>-->
				<div :class="{ 'resolved': !isOpened }" class="invite">{{ $t("page_invitation.invitation", {name_team:team, name_company:company}) }}</div>
				<JoinRequestStatus v-if="!isOpened" :status="status"></JoinRequestStatus>
        <div class="control" v-else>
            <div class="control__btn accept" @click="$emit('accept',id)"><img src="@/assets/accept.svg" alt="Да"></div>
            <div class="control__btn decline" @click="$emit('decline',id)"><img src="@/assets/decline.svg" alt="Да"></div>
        </div>
    </div>
    <p v-if="isCompanyFull" class="text-danger text-center">Вы не можете присоединиться к этой компании</p>
</template>
<script>
import JoinRequestStatus from '@/components/JoinRequests/JoinRequestStatus.vue';

export default {
  components: {JoinRequestStatus},
    props: {
        id: {
            type: Number,
            required: true
        },
        team: {
            type: String,
            required: true
        },
        company: {
            type: String,
            required: true
        },
        status: {
            type: String,
            required: true
        },
        isCompanyFull: false,
    },
    computed: {
        isOpened() {
            return this.status == "OPENED";
        }
    },

}
</script>
<style lang="scss" scoped>

#card {
    display: flex;
    justify-content: space-between;
    margin: 13px;

}

.invite {

    flex-grow: 1;
    background-color: #fff;
    padding: 7px 24px;
    border-radius: 13px;
    margin-right: 12px;
    font-weight: 400;
    font-size: 11px;
    line-height: 100%;
    color: #000000;
}
.resolved{
  background: #DADADA;
  color: #6C6C6C;

}
.control{
    display: flex;
    justify-content: space-between;
}
.control__btn {
    width: 54px;
    border-radius: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  &:hover{
    transform: scale(102%);
  }
    &:active{
        transform: scale(105%);
    }
    &.accept {
        margin-right: 5px;
        background: #0A0F3F;
        & img {
            width: 18px;
            height: 13px;
        }
    }
    &.decline {
        background: #D1D1D1;
        & img {
            width: 18px;
            height: 18px;
        }
    }
}
@media (min-width: 576px) {
  .control__btn {
    width: 74px;
  }
    .invite {

    padding: 13px 34px;
    border-radius: 13px;
    font-size: 15px;
}
}


/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
	.invite {

    padding: 13px 34px;
    border-radius: 13px;
    font-size: 24px;
}
}
@media (min-width: 988px) {

.invite {

    padding: 13px 34px;
    border-radius: 13px;
    font-size: 20px;
}
.control__btn {
    width: 74px;
    border-radius: 13px;
}
}
</style>
