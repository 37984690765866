<template>
  <Error :code="'500'" :message="$t('error_500')"></Error>
</template>

<script>

import Error from '@/components/UI/Error.vue'

export default {
  components: {Error}


}
</script>

<style scoped>

</style>
