<template>
  <div class=" blockBackground">
    <div class=" title">
        <div class="">
          <h1 class="titleText">Управляйте человеческими ресурсами, зная типажи своих сотрудников</h1>
        </div>

        <div class="order">
          <a class="button" @click="showPopup()"> Заказать демо</a>
        </div>
    </div>
  </div>
<v-popup-demo :is-show-pop-up="isShowPopUp" @notShow="showPopup()" :parent-block="'Интересующий блок: Управляйте человеческими ресурсами, зная типажи своих сотрудников'"></v-popup-demo>

</template>

<script>

import PopupDemo from "@/components/Landings/component/PopupDemo.vue";

export default {
  components: {
    "vPopupDemo":PopupDemo,
  },

  data() {
    return {
      isShowPopUp: false,
    }
  },

  methods: {
    showPopup() {
      this.isShowPopUp = !this.isShowPopUp;
    }
  }
}

</script>

<style scoped lang="scss">
//.blockBackground {
//  position: relative;
//
//  &::before {
//    content: "";
//    position: absolute;
//    bottom: -10%;
//    left: 0;
//    width: 100%;
//    height: 100%;
//    background-image: url(@/assets/landing-profile-genius/background.svg);
//    z-index: 1;
//    /* Помещаем фоновое изображение за контент блока */
//  }
//}

.title {
  padding: 150px 20px 120px;
  margin: 0 auto;
  font-size: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;

  background-image: url(@/assets/landing-profile-genius/background.svg);
  background-clip: padding-box;
  background-size: cover;
  background-position: center;
}

.titleText {
  max-width: 600px;
  margin: 0 auto 50px;
  font-weight: 700;
  color: #F6F6F5;
  text-align: center;
  //
  //h1 {
  //  font-size: 54px;
  //  font-weight: 700;
  //  line-height: 57px;
  //  letter-spacing: 0;
  //  text-align: center;
  //
  //}
}

.order {
  text-align: center;
  display: inline-block;
  font-size: 16px;
}

.order > a {
  transition: 0.2s;
  display: inline-block;
  border: 2px #2835C1 solid;
  background: #2835C1;

  color: #F6F6F5;
  font-weight: 400;
}

.button {
  border-radius: 8px;
  padding: 15px 60px;
  transition: 0.2s;
  cursor: pointer;

  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0;
  text-align: left;
  text-decoration: none;
}

.button:hover {
  box-shadow: 0 0 20px 0.5px #171d57;
  background: #1c278a;
  border-color: #1c278a;
}

@media (min-width: 768px) {
  .title {
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 200px;
  }
}

</style>