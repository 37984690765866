<template>
  <div v-if="!isReady" class="block__loader">
    <loader></loader>
  </div>
	<div v-else class="container my-5">
		<div v-for="i of Object.keys(this.adminData.userRegStats)">
		</div>
		<h4> Общее количество регистраций : {{ adminData.totalUsers }}</h4>
		<div class="d-flex mt-2">
			<div class="w-25">
				<h5>Подтвержденные Email:</h5>
				<Pie :options="emails.options" :data="emails.data"></Pie>
			</div>
			<div class="w-25">
				<h5>Валидность теста</h5>
				<Pie :options="talent.options" :data="talent.data"></Pie>
			</div>

      <div class="w-50">
        <h5>Регистрации</h5>
        <Bar :options="regs.options" :data="regs.data"></Bar>
      </div>
		</div>


    <div class="d-flex justify-content-between">

      <div class="w-50">
        <h5>Регистрации с командой</h5>
        <Bar :options="regsWithTeam.options" :data="regsWithTeam.data"></Bar>
      </div>

      <div class="w-50" style="margin-right: 5px; width: 400px">
      <h5>Реактивация</h5>
      <Bar :options="reactivatedStats.options" :data="reactivatedStats.data"></Bar>
      </div>
    </div>

		<UserTables :users-self-table="adminData.usersWithoutTeams" :users-team-table="adminData.usersWithTeams"  ></UserTables>

    <hr class="my-3">

    <div class="w-50">

      <RecoveredTeams :teams-props="adminData.recoveredTeams"></RecoveredTeams>
    </div>

    <hr class="my-3">

    <EmailTest />
    

		</div>

		<transition name="modal">
			<Modal v-if="isMakeAdmin"
				:title="'Сделать сотрудника ' + this.makingAdminUser.fullName + ' ' + this.makingAdminUser.newRole"
				@close="isMakeAdmin = false">
				<div class="d-flex justify-content-between align-items-center mt-4">
					<span> {{ $t("delete.are_you_sure") }}</span>
					<span class="btn-delete" @click="changeRoleAccess(this.makingAdminUser)">Да</span>
				</div>
			</Modal>
		</transition>

		<transition name="modal">
			<Modal v-if="isMakeRepresent"
				:title="'Сделать сотрудника ' + this.makingRepresent.fullName + ' представителем компании ' + this.makingRepresent.companyName"
				@close="isMakeRepresent = false">
				<div class="d-flex justify-content-between align-items-center mt-4">
					<span> {{ $t("delete.are_you_sure") }}</span>
					<span class="btn-delete" @click="makeRepresentative(this.makingRepresent)">Да</span>
				</div>
			</Modal>
		</transition>
</template>

<script>
import UserTables from "@/components/Admin/UserTables.vue"
import RecoveredTeams from "@/components/Admin/RecoveredTeams.vue";
import api from "@/api";
import Loader from "@/components/UI/Loader.vue";
import { mapGetters } from "vuex";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { Pie, Bar } from 'vue-chartjs'
import EmailTest from "@/components/Admin/EmailTest.vue";
import Modal from '@/components/UI/Modal/Modal.vue';
ChartJS.register(ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
	name: "Admin",
	components: { EmailTest, Loader, UserTables, Pie, Bar, Modal, RecoveredTeams },
	data() {
		return {
			isReady: false,
			adminData: {},
			emails: {
				data: {
					labels: ['Подтвержден', 'Не подтвержден'],
					datasets: [{
						backgroundColor: ['#41B883', '#DD1B16'],
						data: []
					}]
				},
				options: {
					responsive: true
				}
			},
			talent: {
				data: {
					labels: ['Да, я согласен', 'No rate'],
					datasets: [{
						backgroundColor: ['#41B883', '#DD1B16'],
						data: []
					}]
				},
				options: {
					responsive: true
				}
			},
			regs: {
				data: {
					labels: [],
					datasets: [{
						label: 'Регистрации',
						backgroundColor: '#7cb5ec',
						data: []
					}]
				},
				options: {
					responsive: true
				}
			},
			regsWithTeam: {
				data: {
					labels: [],
					datasets: [{
						label: 'Регистрации в компании',
						backgroundColor: '#7cb5ec',
						data: []
					}]
				},
				options: {
					responsive: true
				}
			},
			reactivatedStats: {
				data: {
					labels: [],
					datasets: [{
						label: 'Реактивация клиентов',
						backgroundColor: '#7cb5ec',
						data: []
					}]
				},
				options: {
					responsive: true
				}
			},
			isMakeAdmin: false,
			makingAdminUser: { fullName: '', email: '' },
			isUserAdmin: false,
		}
	},
	methods: {
		requestChangeRole(data) {
			this.isMakeAdmin = true;
			this.makingAdminUser.fullName = data.fullName;
			this.makingAdminUser.email = data.email;
			this.isUserAdmin = data.isAdmin
		},

		changeRoleAccess(email, isAdmin) {
			api.sendChangeRoleAccess(email, isAdmin)
				.then(r => { window.location.reload() });
			this.isMakeAdmin = false;
		},
    // getUsers(limit, offset) {
    //
    //   this.isLoading = true;
    //   api.getUsersWithoutTeam(this.adminData.usersWithoutTeams.size,this.adminData.usersWithoutTeams.number, this.search)
    //       .then(r => {
    //         this.usersWithoutTeam = r.data;
    //       })
    //   api.getUsersWithTeamsSearch(this.adminData.usersWithTeams.size,this.adminData.usersWithTeams.number, this.search)
    //       .then(r => {
    //         this.usersWithTeam = r.data}
    //   )
    // },
	},
	computed: {
		...mapGetters(['isAnyLoading']),
	},
	mounted() {
		api.getAdminPage().then(r => {
			this.adminData = r.data;
      console.log(this.adminData)
			this.emails.data.datasets[0].data.push(r.data.totalActivatedUsers, r.data.totalUsers - r.data.totalActivatedUsers);
			this.talent.data.datasets[0].data.push(r.data.totalVerified, r.data.totalUsers - r.data.totalVerified);
			this.adminData.userRegStats.forEach(i => {
				this.regs.data.labels.push(i.month + '-' + i.year);
				this.regs.data.datasets[0].data.push(i.count);
			});
			this.adminData.userInTeamsStatistics.forEach(i => {
				this.regsWithTeam.data.labels.push(i.month + '-' + i.year);
				this.regsWithTeam.data.datasets[0].data.push(i.count);
			});
      this.adminData.recoveredStats.forEach(i => {
        this.reactivatedStats.data.labels.push(i.month + '-' + i.year);
        this.reactivatedStats.data.datasets[0].data.push(i.count);
      });
      console.log(this.adminData);
			this.isReady = true;
		})
	}
}
</script>

<style lang="scss" scoped>
.block__loader {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-delete {
 	color: #e73535;
 	cursor: pointer;

 	&:hover {
 		font-weight: bold;
 		color: red;
 	}
 }</style>