<template>
  <div class="block__slider">
    <div class="reviews-slider mt-5 slider container">
      <div class="slider-wrapper align-items-center swiper-wrapper ">
        <div v-for="(slide, index) in slides" :key="index" class="slider-card swiper-slide">
          <div class="slider-card-text">{{$t(`trust_us.${slide.text}`)}}</div>
          <div class="slider-card-name">{{$t(`trust_us.${slide.name}`)}}</div>
          <div class="slider-card-position">{{$t(`trust_us.${slide.role}`)}}</div>
          <div class="slider-card-company">{{ (slide.company.length === 0)? '': $t(`trust_us.${slide.company}`)}}</div>
        </div>
      </div>
      <div class="slider-pagination d-flex justify-content-center mt-4"></div>
      <!--    <div class="swiper-pagination"></div>-->
      <div class="swiper-button-next" ref="nextButton" @click="nextSlide"></div>
      <div class="swiper-button-prev" ref="prevButton" @click="prevSlide"></div>
  <!--    <div class="swiper-button-prev"></div>-->
  <!--    <div class="swiper-button-next"></div>-->
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
// import Swiper from "swiper/dist/js/swiper.min.js";

export default {
  data() {
    return {
      slides: [
        { text: 'slide_text_aglass',
          name: 'slide_name_aglass',
          role: 'slide_role_aglass',
          company: 'slide_company_aglass' },
        { text: 'slide_text_program_region',
          name: 'slide_name_program_region',
          role: 'slide_role_program_region',
          company: 'slide_company_program_region'
        },
        { text: 'slide_text_Displayforce',
          name: 'slide_name_Displayforce',
          role: 'slide_role_Displayforce',
          company: 'slide_company_Displayforce'
        },
        { text: 'slide_text_developer',
          name: 'slide_name_developer',
          role: 'slide_role_developer',
          company: ''
        },
        { text: 'slide_text_content_manager',
          name: 'slide_name_content_manager',
          role: 'slide_role_content_manager',
          company: ''
        },
        { text: 'slide_text_expert_developer',
          name: 'slide_name_expert_developer',
          role: 'slide_role_expert_developer',
          company: ''
        },
      ],
      swiper: null,
    };
  },
  mounted() {
    this.swiper = new Swiper(".slider", {
      slidesPerView: 1,
      spaceBetween: 15,
      centeredSlides: true,
      initialSlide: 1,
      slideActiveClass: 'active',
      loop: true,
      pagination: {
        el: ".slider-pagination",
        clickable: true,
      },
      breakpoints: {
        1000: {
          slidesPerView: 3,
          spaceBetween: 30
        }
      },

    });
  },
  methods: {
    nextSlide() {
      this.swiper.slideNext()
    },
    prevSlide() {
      this.swiper.slidePrev()
    }
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  },
};
</script>

<style scoped lang="scss">

.block__slider {
  margin-top: 120px;
}

.reviews-slider{
  overflow: hidden;
  position: relative;
}
.swiper-pagination-bullet {

  background-color: #999;
  opacity: 1;
}

.swiper-pagination-bullet-active {

  background-color: #fff;
  opacity: 1;
}

.slider-card {
  border-radius: 25px;
  border: 2px solid #2835C1;
  opacity: 0.25;
  padding: 60px 30px;
  text-align: center;
  transition: all .4s linear;
}

.slider-card.active {
  transition: all .4s linear;
  border-color: #202FD4;
  opacity: 1;

}

.slider-card.visible {


}

.slider-card.active .slider-card-name {
  font-size: 14px;
}

.slider-card.active .slider-card-company {
  font-size: 14px;
}

.slider-card.active .slider-card-text {
  font-size: 16px;
}

.slider-card.active .slider-card-position {
  font-size: 14px;
}

.slider-card-name {
  font-size: 12.835px;
  font-weight: 500;
  text-align: end;
  margin-top: 10px;
  font-style: italic;

}

.slider-card-position {
  font-size: 12.835px;
  text-align: end;
  font-style: italic;
}

.slider-card-text {
  font-size: 12.835px;
  text-align: start;

}

.slider-card-company {
  font-size: 12.835px;
  text-align: end;
  font-style: italic;
}

@media(min-width: 768px) {
  .block__slider{
    margin-top: 200px;
  }
}

@media(min-width: 1200px) {
  .block__title {
    max-width: 1200px;
  }

}
</style>