<template>
	<div class="d-flex progress__text justify-content-between mx-2 fw-bold mb-2 mt-2">
		<span>{{ name }}</span>
		<span>{{ persents }}</span>
	</div>
	<div class="progress" :class="{ active: isBgColor }">
		<div class="progress-bar" role="progressbar" :style="{ width: persents, backgroundColor: bgColor }"
			:aria-valuenow="input" aria-valuemin="0" :aria-valuemax="max"></div>
	</div>
</template>

<script>
export default {
	name: "UserProgressBar",
	props: {
		bgColor: {
			type: String,
			default: '#1C2E76;'
		},
		name: {
			type: String,
			require: true
		},
		max: {
			type: Number,
			require: true
		},
		input: {
			type: Number,
			require: true
		},
		isBgColor: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			persents: Math.round((this.input / this.max) * 100) + '%'
		}
	}
}
</script>

<style scoped>
.progress__text {
	text-transform: uppercase;

}

.progress {
	height: 30px;
	border-radius: 20px;
}

.progress-bar {
	border-radius: 20px;
}

.active {
	background-color: #C4CBE0;
}

@media (min-width: 1200px) {
	.active {
		background: #E7E7E7;
	}
}
</style>
