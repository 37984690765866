<template>
	<div class="btn_type">
		{{ name }}
		<div class="btn_bgColor" :style="{ backgroundColor: bgColor }"> <div v-show="number">{{ number }}</div></div>
	</div>
</template>

<script>
export default {
	props: {
		bgColor: {
			type: String,
			default: '#1C2E76;'
		},
		name: {
			type: String,
			require: true
		},
		type: {
			type: String,
			require: true
		},
		number:{
			type: Number,
			default: null,
      required: false
		}
	},
}
</script>

<style lang='scss' scoped>
.btn_type {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 50px;
	margin: 5px;
	background-color: #fff;
	border-radius: 21.8873px;
	border: none;

	color: #1C1D22;
	font-weight: 700;
	font-size: 16px;

	cursor: pointer;

	&:hover {
		transform: scale(105%);
	}
}



.btn_bgColor {
	width: 70px;
	height: 30px;
	border-radius: 21.8873px;
	display: flex;
	justify-content: center;
	align-items: center;
	color:#fff;
}

@media (min-width: 576px) {
	.btn_type {
		padding: 12px 65px;
		font-size: 17px;
	}
}

@media (min-width:768px) {}

@media (min-width: 992px) {
	.btn_type {
		padding: 12px 20px;
		font-size: 14px;
	}

}

@media (min-width: 1200px) {
	.btn_type {
		padding: 12px 30px;
		font-size: 17px;
	}
}

@media (min-width: 1400px) {
	.btn_type {
		padding: 12px 40px;
		font-size: 18px;
	}
}
</style>
