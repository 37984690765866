<template>
  <div class="cardType">
    <div class="cardType__img">
      <img :src="imageUrl" alt="type_profile">
    </div>
    <div class="cardType__title">
      <h4 :style="{ color: this.colorOfType(path) }">{{title}}</h4>
      <p>{{subTitle}}</p>
    </div>
  </div>

</template>

<script>
import typesUtils from '@/mixins/typesUtils';

export default {
  mixins: [typesUtils],
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    },
  },
  computed: {
    imageUrl() {
      let addres = this.path;
      return require(`@/assets/pure_type/${addres}.png`);
    }
  },
}

</script>

<style scoped lang="scss">
.cardType {
  max-width: 450px;
  display: flex;
  text-align: left;
  margin-bottom: 40px;
  align-items: center;

  &__img {
    margin-right: 20px;
    img {
      width: 100px;
      border-radius: 16px;
    }
  }

  &__title {

    h4 {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      color: #FFFFFF;
      margin-bottom: 0;
    }
    .cardType {
      max-width: 400px;
      min-width: 350px;

     }

  }
}

@media(min-width:  768px) {
  .cardType__img {
    img {
      width: 160px;
      border-radius: 20px;
    }
  }

  .cardType__title  {
    h4{
      font-size: 24px;
    }
  }
}

@media( min-width: 1084px) {
  .cardType {
    max-width: 450px;
    min-width: 400px;
  }
}


@media( min-width: 1200px) {
  .cardType {
    max-width: 500px;
    min-width: 400px;

    &__img {
      img {
        width: 160px;
      }}
  }
}
</style>