<template>
  <div class="control__progress">
		{{ $t("genius_profile.numeration", {number_current: currentQuestion + 1, number_all: questionsLength}) }}
    <div class="progress" style="height: 20px; border-radius: 8px; background-color: #C4CBE0;">

      <div :aria-valuenow="currentQuestion" :style="{width: (this.currentQuestion / (questionsLength-1)) * 100 + '%'}"
           aria-label="Basic example"
           aria-valuemax="24"
           aria-valuemin="0" class="progress-bar" role="progressbar"
           style=" background-color: #1C2E76;"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestProgressBar",
  props: {
    currentQuestion: {
      type: Number,
      required: true
    },
    questionsLength: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.control__progress {
  text-align: center;
  width: 100%;

  font-size: 15px;
  max-width: 500px;
  margin: 20px auto;
	order: 3;
}

//@media (min-width: 768px) {
//	.control__progress {
//		order:2;
//	}
//}

@media (min-width: 991.98px) {
  .control__progress {
    margin: 0;
    //max-width: none;
    justify-content: center;
    font-size: 15px;
    align-items: center;
    display: none;
		order:2;
  }

  .progress {
    width: 100%;
    max-width: 250px;
    margin-left: 30px;
  }


}
@media (min-width: 1400px) {
  .control__progress{
    font-size: 20px;
  }
}
</style>
