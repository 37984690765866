<template>
    <div id="joinRequestForm">
        <input  v-model.trim="v$.email.$model" type="email" id="name" required placeholder="E-mail*">
        <div  v-if="isAnyLoading">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <button @click="confirm" v-else alt="+">Отправить</button>
    </div>
    <p class="error" v-show="isErrors" v-for="error of v$.$errors" :key="error.$uid">
        {{ error.$message }}
    </p>
    <p class="error" v-show="isRequestExists">
        Приглашение уже отправлено
    </p>
    <p class="error" v-show="isUserNotExists">
        Пользователя не существует
    </p>
  <p style="
    font-size: 13px;
    margin-top: 8px;" v-show="isSended" >
    Приглашение отправлено
  </p>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { mapGetters } from "vuex";
import { helpers, required, email } from "@vuelidate/validators";
export default {
    emits: ['add'],
    data() {
        return {
            isErrors: false,
            email: '',
          isSended: false,
        }
    },
    props: {
        isRequestExists: {
            type: Boolean,
            required: true
        },
        isUserNotExists: {
            type: Boolean,
            required: true
        }
    },
    setup() {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
            email: {
                required: helpers.withMessage('E-mail обязателен', required),
                email: helpers.withMessage('Введите E-mail', email),
                $lazy: true
            }
        }
    },
    methods: {
        async confirm() {
            const result = await this.v$.$validate()
            if (!result) {
                this.isErrors = true
                return
            }
            if (!this.v$.$silentErrors.length) {
                this.isErrors = false
                this.isSended = true;
                this.$emit('add', this.email)
                this.email = '';
            }
        }
    },
    computed: {
        ...mapGetters([
            'isAnyLoading',
        ]),
    }

}
</script>
<style scoped lang="scss">
#joinRequestForm {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & button {
        font-weight: 500;
        font-size: 10.1111px;
        line-height: 106%;
        border: 1px solid #2AB573;
        border-radius: 11px;
        width: 124px;
        padding: 10px;
        background-color: #fff;
        &:active{
                transform: scale(105%);
            }
    }
}

input {
    padding: 6px 13px;
    background: #E6E6E6;
    border-radius: 11px;
    font-size: 14px;
    border: none;
    width: 80%;
    margin-right: 7px;
    &:focus{
        outline: none;
    }
}

img {
    cursor: pointer;
}

.error {
    padding: 0;
    margin: 0;
    color: #D12937;
    font-size: 13px;
    margin-top: 8px;
}
</style>
