<template>
<div class="bg-white w-100 ">
  <h5>{{title}}</h5>
</div>
</template>

<script>
import UserBlock from "@/components/User/UserBlock";

export default {
  name: "UserBlockTitle",
  components: {UserBlock},
  props:{
    title:{
      type: String,
      require: true
    }
  }

}
</script>

<style scoped>
h5{
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 18px;
  font-weight: bold;

}
div{
  border-radius: 13px;
  padding: 14px;
}
</style>
