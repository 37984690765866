<template>
  <div v-if="isAnyLoading" class="block__loader">
    <loader></loader>
  </div>
    <div v-else class="w-100">
      <CardsBlock :title="$t('page_invitation.title_invites')">
        <div v-if="getJoinRequests.length !== 0" class="mx-auto mt-3">
            <div v-for="(request) in getOpenedRequests" class="item">
                <JoinRequestCard @accept="accept" @decline="decline" :id="request.id" :team="request.team.name"
                    :company="request.company" :status="request.status" :is-company-full="request.id == fullJoinRequest">
                </JoinRequestCard>
            </div>
          <div v-for="request in getAcceptedRequests" class="item">
            <JoinRequestCard @accept="accept" @decline="decline" :id="request.id" :team="request.team.name"
                             :company="request.company" :status="request.status">
            </JoinRequestCard>
          </div>
          <div v-for="request in getDeclinedRequests" class="item">
            <JoinRequestCard @accept="accept" @decline="decline" :id="request.id" :team="request.team.name"
                             :company="request.company" :status="request.status">
            </JoinRequestCard>
          </div>
        </div>
        <h5 class="opacity-50 text-center mt-3" v-else>{{$t("page_invitation.no_invites")}}</h5>
      </CardsBlock>
    </div>
</template>
<script>
import api from '@/api'
import JoinRequestCard from '@/components/JoinRequests/JoinRequestCard.vue'
import Loader from '@/components/UI/Loader.vue'
import { mapGetters } from 'vuex'
import CardsBlock from '@/components/UI/CardsBlock.vue'
export default {
    components: { JoinRequestCard, Loader,CardsBlock },
    data() {
        return {
            joinRequests: [],
            fullJoinRequest: -1,
        }
    },
    computed: {
        ...mapGetters({
            isAnyLoading: 'isAnyLoading',
            getJoinRequests: 'AuthModule/getJoinRequests'
        }),

        getOpenedRequests() {
          return this.getJoinRequests.filter(request => request.status === 'OPENED')
        },
        getAcceptedRequests() {
          return this.getJoinRequests.filter(request => request.status === 'ACCEPTED')
        },
        getDeclinedRequests() {
          return this.getJoinRequests.filter(request => request.status === 'DECLINED')
        }
    },
    methods: {

        accept(id) {
            api.acceptJoinRequests(id).then(setTimeout(() => {
                this.$store.dispatch('AuthModule/updateSelfJoinRequests')
                this.$store.dispatch('AuthModule/updateSelfTeams')
                this.$store.dispatch('AuthModule/updateSelfCompanies')
            }, 100)).catch(err => {
               if(err.response.status === 400){
                  this.fullJoinRequest = id;
               }
            })
        },
        decline(id) {
            api.declineJoinRequests(id).then(setTimeout(() => {
                this.$store.dispatch('AuthModule/updateSelfJoinRequests')
            }, 100))
        }
    },
    mounted() {
        this.$store.dispatch('AuthModule/updateSelfJoinRequests');
    }
}
</script>
<style>
.block__loader {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
