<template>
	<div class="dropdown">
		<button @click="toogle()" class="button" >Открыть доступ</button>
		<div v-if="isShowBackground" id="myDropdown" class=" dropdown-content">
			<transition-group name="fade">
				<input :key="'input'" type="text" v-model="searchQuery" placeholder="Введите имя или фамилию" class="input__search">
				<div :key="n" v-for="(team, n) in this.filteredCommandData" class="menu">
					<p>{{ team.name }}</p>
					<hr>
					<div class="menu__item" v-for="(user) in team.users">
						<label class="d-flex justify-content-between">
							<p class="m-0" :class="{ 'greyColor': this.getUserById(user.id).admin || this.getUserById(user.id).inTeam }">
								{{ this.getUserById(user.id).firstName }} {{ this.getUserById(user.id).lastName }}
							</p>
							<input style="margin-left: 10px;" type="checkbox" class="form-check-input" v-model="this.getUserById(user.id).allowed"
								:disabled="this.getUserById(user.id).admin || this.getUserById(user.id).inTeam" @change="isChange = true">
						</label>
					</div>

				</div>

				<button :key="'button'" class="dropdown__button" :class="{disable: !isChange || isLoading}" @click="updateUsersAllowed" :disable="isSending">
          <loader v-if="isLoading" :is-white="true"></loader>
          <span v-else>Ок</span>
        </button>
			</transition-group>

		</div>
	</div>

</template>

<script>
import api from '@/api';
import autoritiesMixin from '@/mixins/autoritiesMixin'
import Loader from "@/components/UI/Loader.vue";
export default {
  components: {Loader},
	mixins: [autoritiesMixin],
	props: {
		teamId: {
			type: Number,
			required: true
		},
    isResSealed: {
      type: Boolean,
      required: true
    },
		users: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isShowBackground: false,
			commandData: {},
			searchQuery: '',
			isSending: false,
      resSealed: this.isResSealed,
      isChange: false,
      isLoading: false
    }
	},
	methods: {
		toogle() {
			this.isShowBackground = !this.isShowBackground;
		},
		updateCommandData() {
			if (this.users.length) {
				for (let user of this.users) {
					for (let team of user.teams) {
						const teamId = team.id;
						if (!this.commandData[teamId]) {

							this.commandData[teamId] = { name: team.name, count: team.count, users: [] };
						}

						this.commandData[teamId].users.push({ id: user.id });
					}
				}
			}
		},
		updateUsersAllowed() {
      this.isLoading = true;
			let resultArray = [];
			for (let elem of this.users) {
				if (elem.allowed) {
					resultArray.push(elem.id)
				}
			}
			api.setUsersAllowedTeam(this.teamId, resultArray).then(
				this.isSending = true
			)
			.then(r => {this.isSending = false; this.isLoading = false;})
		}
	},
	computed: {
		getUserById(userId) {
			return function (userId) {
				return this.users.find(user => user.id === userId) || {};
			}.bind(this);
		},
		filteredCommandData() {
    const query = this.searchQuery.toLowerCase();
    return Object.entries(this.commandData).reduce((acc, [teamId, team]) => {
      const filteredUsers = team.users.filter(user => {
        const fullName = `${this.getUserById(user.id).firstName} ${this.getUserById(user.id).lastName}`.toLowerCase();
        return fullName.includes(query);
      });

      if (filteredUsers.length > 0) {
        acc[teamId] = { ...team, users: filteredUsers };
      }

      return acc;
    }, {});
  },
	},
	watch: {
		users: {
			handler: 'updateCommandData',
			immediate: true,
		},
	},
}

</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.greyColor {
	color: #969696;
}

.form-check-input:checked {
  background-color: #0A0F3F;
  border-color: #0A0F3F;;
}

.dropdown {
	position: relative;
	display: flex;
	flex-direction: column;
	z-index: 100000;
	align-items: end;

	&__button {
		background-color: #070C1F;
		color: #FFFFFF;
		width: 100%;
		text-align: center;
		border-radius: 8px;
		display: flex;
		justify-content: center;
    transition: all 0.3s ease;
	}
}


button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 500;
	font-size: 14px;
	background-color: #070C1F;
	color: #FFFFFF;
	border: none;
	border-radius: 11px;
	padding: 10px 20px;
	transition: 0.2s all linear;

	&:hover {
		transform: scale(98%);
	}
}

.dropdown-content {
	position: absolute;
	top: 40px;

	background-color: rgba(0, 0, 0, 0);
	min-width: 200px;
  max-width: 300px;
	z-index: 1;

	font-weight: 700;
	font-size: 14px;
	line-height: 100%;

	background-color: #fff;
	padding: 10px;
	border-radius: 15px;
	border: 1px solid #070C1F;
}

.menu {
	p {
		margin-bottom: 5px;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #fff;
		margin: 10px 0;
		border-radius: 12px;
		cursor: pointer;

		&:hover {
			transform: scale(98%);
		}
	}
}

.input__search {
	margin-top: 10px;
	margin-bottom: 10px;
	border-radius: 5px;
	min-height: 25px;
}


@media (min-width: 768px) {
	.dropdown-content {
		left: 0;
	}
}
</style>