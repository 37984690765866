<template>
  <div class="container" id="title">
    <div class=" d-flex">
      <div class="">
        <h1 class="titleText h1" >{{ $t('title_block.title') }}</h1>
      </div>
    </div>
    <div class="line"></div>

    <div class=" subtext">
      <p class="h6">{{ $t('title_block.subtitle') }}</p>
    </div>

    <div class="d-flex flex-wrap align-items-center justify-content-sm-start mt-2">
      <ButtonDemo :title="$t('title_block.btn_demo')" @click="showPopup" style="margin-bottom: 8px"></ButtonDemo>

      <div id="learnMore" class="learnMore mx-0 mx-sm-2">
        <a  href="#questions" >{{ $t('title_block.btn_learn_more') }}</a>
      </div>
    </div>

  </div>

  <v-popup-demo :is-show-pop-up="isShowPopUp" @notShow="showPopup()" :parent-block="'Интересующий блок: Управление командой и организация эффективной работы сотрудников'"></v-popup-demo>
</template>

<script>
import ButtonDemo from "@/components/Landings/component/ButtonDemo.vue";
import PopupDemo from "@/components/Landings/component/PopupDemo.vue";
export default {
  name: "LandingTitleBlock",
  components: {
    ButtonDemo,
    "vPopupDemo":PopupDemo,
  },
  data() {
    return {
      isShowPopUp: false,
    }
  },
  methods: {
    showPopup() {

      this.isShowPopUp = !this.isShowPopUp;
    }
  }
}

</script>

<style scoped lang="scss">
#title {
  margin-top: 150px;
  margin-bottom: 200px;
  font-size: 60px;
}

.container {
  padding: 0 20px;
}

.titleText {
  margin-bottom: 50px;
  font-weight: 700;
  color: #FFFFFF;
  max-width: 350px;
}

.line {
  width: 100px;
  height: 0;
  border: 2px #2835C1 solid;
  margin-bottom: 16px;
}

.subtext {
  color: #8E8E8E;
  font-size: 20px;
  line-height: 59.91px;
  max-width: 450px;
  margin-bottom: 16px;
}


.learnMore {
  text-align: center;
  display: inline-block;
  font-size: 16px;

  a {
    transition: 0.2s;
    display: block;
    color: white;
    text-decoration: none;
    font-weight: 400;
    border: 2px transparent solid;
    border-radius: 8px;
    padding: 15px 40px;

    &:hover {
      transition: 0.2s;
      border: 2px #2835C1 solid;
    }
  }
}

@media(min-width: 523px) {
  .titleText {
    max-width: 400px
  }
}

@media(min-width: 915px) {
  .titleText {
    max-width: 500px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
    padding: 0;
  }

}

@media (min-width: 1400px) {
  .subtext {
    max-width: 600px;
  }
}

</style>