<template>
	<div id="back" class="mb-3">
		<div class="d-flex justify-content-between">
			<button @click="goBack"><img src="@/assets/back.svg" alt=""> <span>{{ $t("button.back") }}</span></button>
      <!-- Вынести в slot-->
			<DropDownMenu v-if="showMain"></DropDownMenu>
		</div>
	</div>
</template>

<script>
import DropDownMenu from "@/components/User/DropDownMenuTalent.vue";
export default {
	name: "BackAndMainButtons",
	components: { DropDownMenu },
	props: {
		mainTitle: String,
		mainPath: String,
		showMain: {
			type: Boolean,
			default: true
		}
	},
  methods: {
    goBack() {
      // this.$router.back();
      this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped>
#back {
	padding-left: 0 !important;

}

button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 500;
	font-size: 14px;
	color: #070C1F;
	padding: 5px 11px;
	border: none;
	background: #FFFFFF;
	border-radius: 11px;
	transition: 0.2s all linear;

	&:hover {
		transform: scale(98%);
	}

	& img {
		max-height: 20px;
		margin-right: 5px;
	}
}
</style>
