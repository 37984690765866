<template>
  <div id="features" class="container feature">
    <div class=" feature__title">
      <p class="h2">{{$t("screens_functionality.title")}}</p>
    </div>

    <div class="d-flex justify-content-center flex-wrap gx-lg-0  my-5">
        <div id="changeFeaturesGeniusProfile" class="button align-items-center d-flex justify-content-center" :class="{'selected__future': isTest.isGeniusTest, 'feature__button': !isTest.isGeniusTest}"
             @click="changeDescriptionGenius">
          {{$t("screens_functionality.title_genius_profile")}}
        </div>

      <div class="d-flex justify-content-center ">
        <div id="changeFeaturesLevelOfAwareness" class="button align-items-center d-flex justify-content-center "
              @click="changeDescriptionLevels" :class="{'selected__future': isTest.isLevelsTest, 'feature__button': !isTest.isLevelsTest}">
          {{$t("screens_functionality.title_levels_awareness")}}
        </div>
      </div>

    </div>


      <FeatureCard v-if="isTest.isGeniusTest"  :text="$t('screens_functionality.text_genius_profile')" :btn-title="$t('screens_functionality.btn_test_genius')" :is-genius-test="true"></FeatureCard>
      <FeatureCard v-else-if="isTest.isLevelsTest"  :text="$t('screens_functionality.text_levels_awareness')" :btn-title="$t('screens_functionality.btn_start')" :is-genius-test="false"></FeatureCard>


  </div>

</template>

<script>
import ButtonDemo from "@/components/Landings/component/ButtonDemo.vue";
import FeatureCard from "@/components/Landings/LandingMain/LandingFeature/FeatureCard.vue";

export default {
  components: {
    ButtonDemo,
    FeatureCard
  },
  data() {
    return {
      isTest: {
        isGeniusTest: true,
        isLevelsTest: false
      }
    }
  },
  methods: {
    changeDescriptionGenius() {
      this.isTest.isGeniusTest = true;
      this.isTest.isLevelsTest = false;
    },
    changeDescriptionLevels() {
      this.isTest.isLevelsTest = true;
      this.isTest.isGeniusTest = false;
    }
  }
}
</script>

<style scoped lang="scss">
.feature {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 200px;

  &__title {
    max-width: 600px;
    text-align: center;
    margin: 0 auto;
    p {
      /*font-size: calc(1.375rem + 1.5vw);*/
      /*font-weight: 700;*/
      /*line-height: 1.1;*/
      /*font-size: 37px;*/
      /*font-weight: 700;*/
      /*line-height: 51px;*/
      text-align: center;
      margin: 0;
    }
  }

  &__button {
    font-size: 16px;
    border-radius: 100px;
    text-align: center;
    border: 2px #1A247E solid;
    color: #848484FF;
    cursor: pointer;
    width: 350px;
    padding: 5px 90px;
    position: relative;
    overflow: hidden;
    font-weight: 400;
  }
}




.button {
  font-size: 16px;
  border-radius: 100px;
  text-align: center;
  cursor: pointer;
  width: 350px;
  padding: 5px 90px;
  position: relative;
  overflow: hidden;
  font-weight: 400;
  z-index: 2;
}


.feature__button {
  border: 2px #1A247E solid;
  color: #848484FF;
}

#changeFeaturesGeniusProfile {
  margin-right: 20px;
}

.selected__future {
  background: #2835C1;
  border: 2px #2835C1 solid;
  color: #FFFFFF;
  cursor: default;
  z-index: 3 !important;
}

.feature__button::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: #2835C1;
  top: 0;
  left: -100%;
  opacity: 0.5;
  transition: 0.3s;
  z-index: -1;
}

.feature__button:hover {
  color: #fff;
  z-index: 3;
}

.feature__button:hover::before {
  left: 0;
}


.featuresDescription {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 30px;
  text-align: left;
  max-width: 600px;
}

.orderFeatureDemo > a {
  display: inline-block;
  border-radius: 8px;
  padding: 10px 30px;
  background: #2835C1;
  margin-top: 30px;
  text-decoration: none;
  color: #F6F6F5;
  font-size: 20px;
  font-weight: 400;
  transition: 0.2s;
}

#hiddenorderFeaturesDemoButton {
  display: none;
}

#orderFeaturesDemoButton {
  display: inline-block;
}

#orderFeaturesDemoButton:hover {
  box-shadow: 0px 0px 20px 0.5px #0015FF;
  background: #0017EF;
  transition: 0.2s;
  cursor: pointer;
}

#orderFeaturesDemoButton:active {
  background: #0014CC;
  transition: 0.2s;
}

.orderFeature > a {
  display: inline-block;
  /*padding: 20px 45px 20px 45px;*/
  background: #2835C1;
  /*border-radius: 15px;*/
  margin-top: 30px;
  text-decoration: none;
  color: #F6F6F5;
  /*font-size: 20px;*/
  font-weight: 400;
  transition: 0.2s;
}

/*.orderFeature > a:hover {*/
/*    box-shadow: 0px 0px 20px 0.5px #0015FF;*/
/*    background: #0017EF;*/
/*    transition: 0.2s;*/
/*    cursor: pointer;*/
/*}*/

/*.orderFeature > a:active {*/
/*    background: #0014CC;*/
/*    transition: 0.2s;*/
/*}*/

#orderFeaturesButtonHidden {
  display: none;
}

.clear {
  height: 40px;
}

.clear > p {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 10px 25px 10px 25px;
  width: 250px;
  border-radius: 15px;
  text-decoration: none;
  font-size: 20px;
  font-family: Ubuntu;
  font-weight: 700;
  border: 6px #202FD4 solid;
}

.dark {
  height: 40px;
}

.dark > p {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 10px 25px 10px 25px;
  width: 150px;
  border-radius: 15px;
  text-decoration: none;
  background: #081153;
  font-size: 20px;
  font-family: Ubuntu;
  font-weight: 700;
}

@media (max-width: 1200px) {
  .corporate{
    width: 480px;
  }

  .independent {
    width: 480px;
  }
}

@media (max-width: 1055px) {

  #featureImg {
    /*width: 70%;*/
    margin-left: 10%;
  }
}

@media (max-width: 992px) {
  #changeFeaturesGeniusProfile {
    margin: 0;
    margin-bottom: 12px;
  }

  .feature__button {
    font-size: 12px;
    width: 350px;
  }

  /*.feature__button {*/
  /*    font-size: 18px;*/
  /*}*/

  #featureImgColumn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #featureImg {
    /*width: 45%;*/
    /*width: 300px;*/
    margin-left: 0;
    margin-bottom: 40px;
  }

}

@media (max-width: 768px) {

  #featureImg {
    /*width: 60%;*/

  }

  .corporate{
    width: 350px;
  }

  .independent {
    width: 350px;
  }

  .typeName > p {
    font-size: 30px;
  }

}

</style>