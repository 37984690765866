<template>
    <div class="wrapper w-100 container">
        <p class="title">{{title}}</p>
        <hr>
        <div class="cards">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        title:{
            type: String,
            required: false
        }
    }

}
</script>
<style>
hr {
    margin: 0;
    height: 2px;
    border: none;
    color: rgba(28, 29, 34, 0.4);
    background-color: rgba(28, 29, 34, 0.4);
}

.title {
    margin: 20px 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    color: #000000;
}
.cards{
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media(min-width: 1400px) {
  .cards{
    justify-content: start;
  }
}
</style>