export default {
    data(){
        return{
            isPasswordHidden: false
        }
    },
    methods:{
        hidePassword(){
            const passwordField = document.getElementById('password');
            const passwordConfirmField = document.getElementById('passwordConfirm');
            this.isPasswordHidden = true;
            passwordField.setAttribute('type','text');
            passwordConfirmField.setAttribute('type','text');
            setTimeout(()=>{
              passwordField.setAttribute('type','password');
              passwordConfirmField.setAttribute('type','password');
              this.isPasswordHidden = false;
            },1500)
        },
    }
}
