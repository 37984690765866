<template>
    <div id="card" class="d-flex" :style="{'--text-color' : textColor, '--bg-hover-color' : hoverBgColor, '--bg-color' : bgColor}">
        <slot></slot>
    </div>
</template>
<script>

export default {
  props:{
    bgColor: {
      type: String,
      default: 'white'
    },
    hoverBgColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0.6)'
    },
    textColor: {
      type: String,
      default: '#1C1D22'
    },
  },
}
</script>
<style lang="scss" scoped>
#card {
    color: var(--text-color);
    background-color: var(--bg-color);
    border-radius: 24px;
    font-weight: bold;
    font-size: 22px;
    min-width: 320px;
    padding: 20px 30px;
    justify-content: space-between;
    transition: 0.2s all linear;
}

#card:hover {
  background-color: var(--bg-hover-color);
    cursor: pointer;
    transform: scale(0.98);
}
@media (min-width: 576px) {
    #card{
        min-width: 410px;
    }
}
</style>
