<template>
  <div id="header" >
    <div class="container">
      <AppLogo></AppLogo>

      <div class="header-menu d-none d-md-flex">
        <router-link :to="'/teams'" :class="{ active: isTeamPage}" class="header-menu__item">
          <img alt="Teams"  src="@/assets/header-menu/team.svg">
        </router-link>
        <router-link :class="{ active: isUserPage }" :to="'/users'"
                     class="header-menu__item">
          <img alt="Menu" src="@/assets/header-menu/profile.svg">
        </router-link>
        <router-link :class="{ active: isRequestsPage }" :to="'/requests'"
                     class="header-menu__item">
          <img v-if="!isHaveOpenedJoinRequests" alt="Notification" src="@/assets/header-menu/notifications/empty.svg">
          <img v-else alt="Notification" src="@/assets/header-menu/notifications/active.svg">
        </router-link>
      </div>

      <div aria-controls="offcanvasScrolling" class="header-burger d-md-none" data-bs-target="#offcanvasScrolling"
           data-bs-toggle="offcanvas" type="button">
        <img alt="" src="@/assets/burger.svg">
      </div>

      <HeaderAccount class="d-none d-md-flex"></HeaderAccount>
    </div>
  </div>
</template>

<script>
import HeaderAccount from "@/components/UI/HeaderAccount.vue";
import AppLogo from "@/components/UI/AppLogo.vue";
import {mapGetters} from "vuex";

export default {
  components: {AppLogo, HeaderAccount},
  data() {
    return {

    }
  },
  props: {
    isUserPage: Boolean,
    isTeamPage: Boolean,
    isRequestsPage: Boolean,
    isHaveOpenedJoinRequests: Boolean
  },
  mounted() {

  },
  computed: {
    ...mapGetters({
      teams: "AuthModule/getTeams",
    }),
  }

}
</script>

<style lang="scss" scoped>


.header-burger {
  padding: 7px;
  & img {
    width: 25px;
    height: 25px;
  }
}


#header {
  position: sticky;
  top: 0px;
  z-index: 999;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 30px;
  color: #FFFFFF;
  background-color: #0A0F3F;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-menu {
  display: flex;

  &__item {
    opacity:0.5;
    cursor: pointer;
    margin: 0 15px;
    transition : all .3s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &:after {
      transition : all .3s linear;
      width: 0;
      content    : "";
    }
    & img {
      width: auto;
      height: 20px;
    }
  }
}
.active{
  opacity:1;
}
.active:after {
  content: "";
  width: 26px;
  height: 2px;
  border-radius: 105px;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 50px;

}

@media (min-width: 1400px) {
  .logo {
    font-size: 28.0642px;
  }
  .offcanvas {
    width: 400px !important;
  }
  #header {
    height: 80px;
  }
  .header-menu__item {
    width: 48px;
    height: 48px;
    margin: 0 7px;
  }
  .active:after {
    height: 3px;
    top: 60px;

  }
}
</style>
