<template>
  <div class="user__menu justify-content-between justify-content-md-center mb-4">
    <div :class="{ 'activeTest': test == 1 }" class="py-3 px-4 user__menu__item "
         @click="changeTest(1)">
      {{ $t("personal_account.genius_profile") }}
    </div>

    <div v-if="!$route.params.id || isSuperAdmin()"
         :class="{ 'activeTest': test == 2, 'hideTest': isLocaleEn }"
         class="py-3 px-4 user__menu__item position-relative" @click="changeTest(2)">
      {{ $t("personal_account.levels_consciousness") }}
      <span v-if="isLevelResult && isTestDone"
            class="position-absolute  start-100 translate-middle p-1 bg-danger rounded-circle notification"> </span>
    </div>

    <div :class="{ 'activeTest': test == 3 }" class="py-3 px-4 user__menu__item "
         @click="changeTest(3)">
      Тест на компетенции
    </div>

  </div>
</template>

<script>
import autoritiesMixin from "@/mixins/autoritiesMixin";
export default {
  mixins: [ autoritiesMixin],
 props: {
   isLocaleEn: {
     type: Boolean,
     required: true
   },
   test: {
     type: Number,
     default: 1
   },
   isLevelResult: {
     type: Boolean,
     required: true
   },
   isTestDone: {
     type: Boolean,
     required: true
   }
 },
 methods: {
   changeTest(test) {
     this.$emit('changeTest', test)
   }
 }
}
</script>

<style scoped lang="scss">

.user__menu {
  display: flex;
  font-size: 13px;

  &__item {
    color: rgba(28, 29, 34, 0.3);
    border-bottom: 2px solid rgba(28, 29, 34, 0.3);
    cursor: pointer;
    transition: all 0.2s ease-in;
    text-align: center;

    &:hover {
      color: rgba(28, 29, 34, 0.6);
      border-bottom: 2px solid rgba(28, 29, 34, 0.6);
    }

    &.activeTest {
      color: #1C1D22;
      border-bottom: 2px solid #1C1D22;
    }

    &.hideTest {
      display: none;
    }
  }
}
</style>