<template>
	<div class="rounds">
		<!--<ul class="round" v-for="answer in this.question" >
			<li>{{ answer[1] }}</li>
		</ul>-->
		<div class="round" v-for="answer in this.question">
			<div>{{ answer[1] }}</div>
		</div>
	</div>

</template>

<script>
import { useLink } from 'vue-router';

export default {
	name: "TestAnswer",
	props: {
		question: {
			type: [],
			required: true
		}
	},
	methods: {
		//changeItem(event) {
		//	this.$emit('update:question', event.target.value)
		//}
	}
}
</script>

<style lang="scss" scoped>
.rounds {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}
.round {
	margin: 10px;
	border-radius: 50%;


	&:nth-child(1), &:nth-child(5) {
		height: 5em;
		width: 5em;
	}

	&:nth-child(2), &:nth-child(4){
		height: 3em;
		width: 3em;
	}

	&:nth-child(3) {
		height: 2em;
		width: 2em;
	}
	

	&:nth-child(1), &:nth-child(2) {
		border: 2px solid #77dd77;
	}

	&:nth-child(4), &:nth-child(5) {
		border: 2px solid #ff7033;
	}

	&:nth-child(3) {
		
		border: 2px solid #8a7f8e;
	}
	
	& div {


		cursor: pointer;
		font-size: 12px;

		transition: .2s all linear;

		
	}
	&.active {
			background: #1C2E76;
			color: white;

		}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 768px) {


	//.answer__item {
	//	min-width: 350px;
	//}


}

@media (min-width: 991.98px) {
	//.answer__item {
	//	max-width: 600px;
	//	min-width: 500px;
	//}

}

@media (min-width: 1400px) {
	//.answer__item {
	//	font-size: 16px;
	//}
}
</style>
