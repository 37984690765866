<template>
<div class="answer__item">{{question}}</div>
</template>

<script>
export default {
  name: "TestAnswer",
  props:{
    question:{
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.answer__item {
  background-color: #D9D9D9;
  border-radius: 15px;
  padding: 10px 15px;
  margin: 7px 0;
  cursor: pointer;
  font-size: 12px;

  transition: .2s all linear;

&.active {
   background: #1C2E76;
   color: white;

 }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 768px) {


  .answer__item {
    min-width: 350px;
  }


}
@media (min-width: 991.98px) {
  .answer__item {
		max-width: 600px;
  	min-width: 500px;
  }

}
@media (min-width: 1400px){
  .answer__item{
    font-size: 16px;
  }
}
</style>
