<template>
  <Error :code="'403'" :message="$t('error_403')"></Error>
</template>

<script>

import Error from '@/components/UI/Error.vue'

export default {
  components: {Error}


}
</script>

<style scoped>

</style>
