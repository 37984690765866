<template>
  <div id="view" class="w-100 my-2 my-lg-3 mx-3 ">
    <div class="w-100">
      <div :class="{active : isOpened}" class="item z-99" @click="toogle()">
        <div class="content">
         <span>
      {{ displayTypeName(currentType) }}
      </span>
          <div :style="{'background-color' : colorOfType(currentType)}" class="color"></div>
        </div>
        <div class="arrow"></div>
      </div>
      <div v-if="isOpened" class="dropdown-items">
        <div v-for="type in typesNames.filter(t => t!== currentType)" class="item" @click="changeType(type)">
          <div class="content">
             <span>
              {{ displayTypeName(type) }}
             </span>
            <div :style="{'background-color' : colorOfType(type)}" class="color"></div>
          </div>
        </div>
      </div>
    </div>


    <div v-for="user in users.filter(u => u.talent)" class="d-flex flex-column flex-sm-row stats">
      <div @click="$router.push('/users/' + user.id)" class="w-100  stats-fullname">
        {{ user.firstName }} {{ user.lastName }}
      </div>
      <div class="w-100 stats-percents d-flex align-items-center justify-content-between">
        <span>
        {{ getTypeResult(user, currentType) }}%
        </span>
        <div class="progress" style="background-color: #D1D1D1">
          <div :aria-valuenow=" getTypeResult(user, currentType)" :style="{ width: getTypeResult(user, currentType) + '%', backgroundColor: colorOfType(currentType) }"
               aria-valuemax="100"
               aria-valuemin="0" class="progress-bar" role="progressbar"></div>
        </div>
      </div>
    </div>
  </div>

	<transition name="fade">
			<div class="translucent-background" :class="{rounded : isTeamPage}" v-show="isOpened" @click="toogle()"></div>
		</transition>
</template>

<script>

import typesUtils from "@/mixins/typesUtils";

export default {
  mixins: [typesUtils],
  name: "TeamView3",
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentType: "creator",
      typesNames: ["creator", "driver", "teambuilder", "networker", "realizator", "checkman", "analyst", "improver"],
      isOpened: false,
      isTeamPage:false,
    }
  },

  methods: {
    toogle() {
      this.isOpened = !this.isOpened;
    },
    changeType(type) {
      this.isOpened = !this.isOpened;
      this.currentType = type;
    },
    getTypeResult(user, type) {
      if (user.talent)
        return Math.round(user.talent[type])
    }
  }
}
</script>

<style lang="sass" scoped>
.stats
  margin-top: 20px
  font-weight: 500
  font-size: 20px
  &-fullname
    font-weight: 700
    cursor: pointer
    &:hover
      transform: scale(102%)
  &-percents
    .progress
      width: 80%
      height: 25px
      border-radius: 30px
      margin-left: 20px

::after, ::before
  box-sizing: content-box

.item
  font-weight: 500
  display: flex
  position: relative
  align-items: center
  justify-content: space-between
  background-color: #fff
  font-size: 18px
  padding: 5px 20px 5px 30px
  max-width: 300px
  border-radius: 25px
  cursor: pointer
  z-index: 7

  &:hover
    transform: scale(102%)

  &.active
    .arrow
      transform: rotate(45deg) translate(-5px, -5px)

      &:before
        transform: translate(10px, 0)

      &:after
        transform: rotate(90deg) translate(10px, 0)

  .arrow
    transform: rotate(0)
    left: 0

    &:before, &:after
      background-color: transparent
      width: 3px
      height: 10px
      display: inline-block
      position: absolute
      border-bottom: 12px solid white
      top: 0
      left: 0
      transform: rotate(0)

    &:before
      transform: rotate(-135deg)

    &:after
      transform: rotate(135deg)

  &.active
    .arrow
      transform: rotate(0)
      transform: translate(0, -6px)

      &:before
        transform: rotate(-45deg)

      &:after
        transform: rotate(45deg)


  .arrow
    transform: rotate(0)
    left: 0

    &:before, &:after
      background-color: transparent
      width: 3px
      height: 10px
      display: inline-block
      position: absolute
      border-bottom: 12px solid black
      top: 0
      left: 0
      transform: rotate(0)

    &:before
      transform: rotate(-135deg)

    &:after
      transform: rotate(135deg)

  &.active
    .arrow
      transform: rotate(0)
      transform: translate(0, -6px)

      &:before
        transform: rotate(-45deg)

      &:after
        transform: rotate(45deg)

.content
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between
  padding-right: 30px

.arrow
  width: 13px
  height: 13px
  display: inline-block
  position: relative
  bottom: 1px
  transition: 0.4s ease
  margin-top: 2px
  text-align: left
  transform: rotate(45deg)
  float: right

  &:before, &:after
    position: absolute
    content: ''
    display: inline-block
    width: 12px
    height: 3px
    background-color: #1E1E1E
    transition: 0.4s ease

  &:after
    position: absolute
    transform: rotate(90deg)
    top: -5px
    left: 5px


.color
  width: 20px
  height: 20px
  border-radius: 20px

.dropdown-items
  transition: 0.4s ease-out
  position: absolute
  z-index: 7

  .item
    margin-top: 7px

    .content
      span
        margin-right: 20px
.translucent-background 
  position: fixed
  overflow-y: auto
  top: 0
  left: 0
  background-color: rgba(0, 0, 0, 0.5)
  height: 100%
  width: 100%
  z-index: 5
  &.rounded
    border-radius: 57.3879px !important

@media(min-width: 580px)
  .stats-fullname,.stats-procents
    width: 50%

</style>