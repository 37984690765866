<template>
  <div v-if="isLoading" class="block__loader">
    <loader></loader>
  </div>
    <div v-else class="w-100" >
        <CompanyAndTeamWrapper :title="$t('companies.list_companies')">
            <div v-for="company in companies" class="item">
                <TeamCard :name="company.name" :count="company.count"
                    @click="this.$router.push('/company/' + company.id)">
                </TeamCard>
            </div>
        </CompanyAndTeamWrapper>
        <div v-if="isSuperAdmin()">
            <CompanyAndTeamWrapper :title="$t('companies.all_companies')">
                <div v-for="company in allCompanies" class="item">
                    <TeamCard :name="company.name" :count="company.count"
                        @click="this.$router.push('/company/' + company.id)">
                    </TeamCard>
                </div>
                <div class="item opacity-75">
                    <AddTeamForm :isExists="isAddingCompanyExists" :addComponent="$t('companies.add_company')" @add="addCompany"></AddTeamForm>
                </div>
            </CompanyAndTeamWrapper>
        </div>
    </div>
</template>

<script>


import Loader from '@/components/UI/Loader.vue';
import AddTeamForm from '@/components/Team/AddTeamCard.vue';
import CompanyAndTeamWrapper from '@/components/UI/CardsBlock.vue'
import TeamCard from '@/components/Team/TeamCard.vue';
import { mapGetters } from 'vuex';
import api from '@/api';
import autoritiesMixin from '@/mixins/autoritiesMixin';
export default {
    components: { AddTeamForm, TeamCard, CompanyAndTeamWrapper, Loader },

    mixins: [autoritiesMixin],

    data() {
        return {
          allCompanies: [],
          isAddingCompanyExists: false,
          isLoading: false
        }
    },

    computed: {
        ...mapGetters({
            companies: 'AuthModule/getCompanies',
            isAnyLoading : 'isAnyLoading'
        })

    },

    methods: {
        addCompany(name) {
          this.isLoading = true;
            api.createCompany(name).then(r => {
                this.getAllCompanies();
                this.isAddingCompanyExists = false;
            })
                .catch(err => err.response.status == 409 ? this.isAddingCompanyExists = true : '')
                .finally(() => this.isLoading = false)
        },
        getAllCompanies() {
          this.isLoading = true;
            api.getAllCompanies().then(r => this.allCompanies = r.data)
                .catch(err => console.log(err))
                .finally(() => this.isLoading = false)
        }
    },
    mounted() {
        this.$store.dispatch('AuthModule/updateSelfCompanies')
        if (this.isSuperAdmin()) {
            this.getAllCompanies();
        }
    }

}
</script>

<style scoped>
.block__loader {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item {
    margin: 15px 15px 15px 0;
}
</style>
