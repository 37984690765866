<template>

  <div v-if="isAnyLoading" class="block__loader">
    <loader></loader>
  </div>
  <div v-else-if="isPageLoaded" id="level" class="container my-4">
    <BackAndMainButtons :show-main="false"></BackAndMainButtons>

    <div class="mb-4">

      <div class="d-flex flex-column align-items-center flex-lg-row justify-content-between mb-4 align-items-lg-start ">
        <div class="w-100 d-flex justify-content-between flex-column mb-3 mb-lg-0">
          <div class="bg-white p-3 p-sm-3 p-md-4 p-lg-5 h-100" style="border-radius: 15px;">
            <div class="level__title h-100">
              <div v-for="one in this.title" :style="{marginBottom: $last ? '0' : '8px'}">
                <h3>{{ one }}</h3>
              </div>
            </div>
          </div>
          <div class="user__item d-flex mt-2 mt-xl-4">
            <div v-for="text in info['emotions']">
              <UserItem :withoutPadding="false">{{ text }}</UserItem>
            </div>
          </div>
        </div>


        <div class="my-lg-0 level__all bg-white w-100 h-100 p-4 rounded-4">
          <div v-for="(name,i) in levelNames"
               @click="$router.push('/levels/' + i)"
               :style="{'background-color' : colors[i-1]}" class="level__item">{{ i }}. {{ name }}</div>

        </div>
      </div>
    </div>

    <div class="mb-4">
      <UserBlock>
        <div v-html="this.info['descriptionLevel']"></div>
      </UserBlock>
    </div>

    <div class="mb-4">
      <div class=" d-flex flex-column">
        <UserBlock>

          <h4 class="menege__title">Тип руководителя: {{ this.info["NameTypesManager"] }}</h4>
          <div v-html="this.info['typesManager']"></div>

        </UserBlock>
      </div>
    </div>

    <div class="row d-flex ">
      <div class="col-12 col-lg-6">
        <div class="mb-4">
          <UserBlockTitle :title="'Совет для вас:'"></UserBlockTitle>
        </div>
        <div class="mb-4">
          <UserBlock>
            <ul>
              <li v-for="text in this.info['adviceYou']">{{ text }}</li>
            </ul>
          </UserBlock>
        </div>


      </div>
      <div class="col-12 col-lg-6">
        <div class="mb-4">
          <UserBlockTitle :title="'Совет для коллег:'"></UserBlockTitle>
        </div>
        <div class="mb-4">
          <UserBlock>
            <ul>
              <li v-for="text in this.info['adviceColleagues']">{{ text }}</li>
            </ul>
          </UserBlock>
        </div>
      </div>
    </div>

    <div class="d-block mt-3">
      <UserBlockTitle :title="'Смотрите также:'"></UserBlockTitle>
    </div>
    <div class="row gx-2 mt-3">
      <div v-for="(level, index) in Object.keys(this.levelNames)" v-show="level != info['levelNumber'] + 1"
           class="col-12 col-md-6 col-lg-4 p-0 ">
        <UserButtonPureType @click="$router.push('/levels/' + level)" :bg-color="'#0A0F3F'"
                            :name="this.levelNames[level]"
                            :number="level" :type="this.levelNames[level]"> {{level}}
        </UserButtonPureType>
      </div>
    </div>
  </div>
</template>

<script>
import UserBlock from "@/components/User/UserBlock";
import NewResultDisplay from "@/components/User/TalentResultDisplay";
import Loader from "@/components/UI/Loader";
import UserBlockTitle from "@/components/User/UserBlockTitle";
import UserItem from "@/components/User/UserItem.vue";
import UserButtonPureType from "@/components/User/UserButtonPureType.vue"
import LevelTestDisplay from "@/components/Test/LevelTestDisplay.vue";
import {mapGetters} from "vuex";
import api from "@/api"
import BackAndMainButtons from "@/components/UI/BackAndMainButtons.vue";

export default {
  components: {
    BackAndMainButtons,
    UserBlock,
    NewResultDisplay,
    Loader,
    UserBlockTitle,
    UserItem,
    UserButtonPureType,
    LevelTestDisplay
  },
  data() {
    return {
      info: {},
      id: '',
      isPageLoaded: true,
      title: [],
       colors:['#EC3E34','#E99737','#F6B818','#28B576','#11828D','#1C6CAD','#734398'],
      levelNames: {
        " 7": "Просветление",
        " 6": "Творчество",
        " 5": "Вдохновение",
        " 4": "Альтруизм",
        " 3": "Рационализм",
        " 2": "Защита",
        " 1": "Выживание",
      }
    }
  },

  methods: {
    getLevel(id) {
      this.isPageLoaded = false;
      api.getLevelInfo().then(r => {
        this.id = id - 1;
        this.info = r.data[id - 1];
        this.isPageLoaded = true;
      });
    }
  },
  beforeMount() {
    this.getLevel(this.$route.params.id);
  },
  async beforeRouteUpdate(to, from) {
    this.getLevel(to.params.id)
  },
  watch: {
    info: function (val) {
      let help = val['fullNameLevel'];
      this.title = help.split('—');
    }
  },
  computed: {
    ...mapGetters([
      'isAnyLoading',
    ])

  }
}
</script>

<style lang='scss' scoped>
* {
  font-family: 'Ubuntu', sans-serif;
  color: #1E1E1E;
}

.block__loader {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menege__title {
  padding: 0;
  font-size: 18px;
  font-weight: bold;
}

.level {
  &__item {
    text-align: center;
    color:white;
    margin: 5px 0;
    border-radius: 20px;
    padding: 7px 0;
    cursor: pointer;
    transition: all .2s linear;
    &:hover{
      transform: scale(102%);
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 106%;
      margin: 0;
    }

  }
}

.user {
  &__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    div {
      margin: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  &__more {
    color: #1E1E1E;
    font-weight: 500;
    font-size: 16px;
    background: #E7E7E7;
    border-radius: 21.8873px;
    border: none;
    padding: 8px 52px;

    &:hover {
      transform: scale(105%);
    }
  }
}

@media (min-width: 576px) {
  .level__img {
    img {
      max-width: 550px;
    }
  }
}

@media (min-width: 768px) {
  .level {
    &__img {
      height: 100%;

      img {
        max-width: 750px;
        max-height: 450px;
      }
    }

    &__title {
      h3 {
        font-size: 23px;
      }
    }
  }

  .user__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .user__result {
    max-width: 35%;
  }

  .level {
    &__all{
      margin-left: 10px;
    }

    &__title {
      h3 {
        font-size: 26px;
      }
    }
  }
}

/*// X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}

/*// XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {
  .level__title {
    h3 {
      font-size: 33px;
    }
  }


}
</style>
