<template>
  <div class="container blockTeamWisdom">
    <div class="blockTeamWisdom__title">
      <h2>Team Wisdom</h2>
      <p>Автоматизация управления персоналом, оценки сотрудников <br> и подбора команд</p>
    </div>

    <div class="blockTeamWisdom__content">
      <div class="blockTeamWisdom__content__text">
        <p>Team Wisdom — это платформа для оценки персонала и формирования эффективных команд. С помощью опросов и тестирований вы сможете выявить сильные стороны членов вашего коллектива, подобрать сотрудников и сбалансировать свою команду так, чтобы каждый чувствовал себя на своём месте.</p>
        <p>Рекомендации платформы и аналитика командных показателей помогут вам принимать осознанные кадровые решения и развивать свои управленческие навыки.</p>
      </div>
      <div class="blockTeamWisdom__content__img">
        <img src="../../../assets/landing-profile-genius/logo-blue.png" alt="logo">
      </div>
    </div>
  </div>

</template>

<script>

</script>

<style scoped lang="scss">

.blockTeamWisdom {

  margin-bottom: 120px;
  padding: 0 20px;

  &__title {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;

    h2 {
      font-size: 48px;
      font-weight: 700;
      line-height: 51px;
      text-align: center;
      margin-bottom: 40px;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      max-width: 600px;

      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__text {
      max-width: 550px;
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      text-align: left;
      margin-right: 50px;
      margin-bottom: 40px;
    }

    &__img {
      img {
        max-width: 350px;
        margin-right: 20px;
      }
    }

  }

}

@media (min-width: 768px) {
  .blockTeamWisdom {
    margin-bottom: 200px;
  }
}

@media (min-width: 911px) {
  .blockTeamWisdom {
    padding: 0 40px;

    &__content {
      flex-direction: row;

      &__text {
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .blockTeamWisdom {
    &__img {
      img {
        max-width: 400px;
      }
    }
  }
}

</style>