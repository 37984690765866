<template>
  <div v-if="isAnyLoading" class="block__loader">
    <loader></loader>
  </div>

	<div v-else-if="isPageLoaded" id="user" class="container my-4">
		<BackAndMainButtons :main-title="'Другие роли'" :main-path="'/types'"></BackAndMainButtons>

		<div :style="{ 'background-color': this.colorOfType(this.id) }" class="header">
			<h2>{{ this.displayTypeName(this.id) }}</h2>
		</div>
		<div class="d-lg-flex justify-content-between">
			<div class="user_info d-lg-flex flex-column justify-content-between">
				<div class="user__superpowers">
					<div class="user__superpower w-sm-100 w-lg-50">
						<!--Суперсила-->
						<div class="title">{{ $t("headers_pure_types.superpower") }}</div>
						<div class="subtitle aline-items-center"> {{ this.typesInfo['superpower'] }}</div>
					</div>
					<div class="user__superpower w-sm-100 w-lg-50">
						<!--Карьера-->
						<div class="title w-40">{{ $t("headers_pure_types.career_path") }}</div>
						<div class="subtitle aline-items-center">{{ this.typesInfo['career'] }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-4"></div>
		<div class="d-flex flex-column-reverse  align-items-center flex-lg-row justify-content-between mb-4">
			<div class="user__mainInfo w-100">
				<div class="title">{{ $t("headers_personal_account.about_you") }}</div>
				<div class="subtitle" v-html="this.typesInfo['main_info']">
				</div>
			</div>
			<img class="user__img mb-4 mb-lg-0" :src="imageUrl" alt="photo">
		</div>

		<div class="mb-4">
			<UserBlockTitle :title="$t('headers_personal_account.working_project')"></UserBlockTitle>
		</div>

		<div class="row mb-4 gy-4 gy-lg-0 mt-lg-4">
			<div class="col-12 col-lg-6">
				<UserBlock :is-green="true">
					<ul>
						<li v-for="text in this.typesInfo['project']['adv']">{{ text }}</li>
					</ul>
				</UserBlock>
			</div>
			<div class="col-12 col-lg-6">
				<UserBlock :is-red="true">
					<ul>
						<li v-for="text in this.typesInfo['project']['disadv']">{{ text }}</li>
					</ul>
				</UserBlock>
			</div>
		</div>

		<div class="mb-4">
			<UserBlockTitle :title="$t('headers_personal_account.working_team')"></UserBlockTitle>
		</div>

		<div class="row mb-4 gy-4 gy-lg-0 mt-lg-4">
			<div class="col-12 col-lg-6">
				<UserBlock :is-green="true">
					<ul>
						<li v-for="text in this.typesInfo['communication']['adv']">{{ text }}</li>
					</ul>
				</UserBlock>
			</div>
			<div class="col-12 col-lg-6">
				<UserBlock :is-red="true">
					<ul>
						<li v-for="text in this.typesInfo['communication']['disadv']">{{ text }}</li>
					</ul>
				</UserBlock>
			</div>
		</div>

		<div class="mb-4">
			<UserBlockTitle :title="$t('headers_pure_types.strengths')"></UserBlockTitle>
		</div>
		<div class="user__item d-none d-md-flex mb-4">
			<div v-for="text in this.typesInfo['skills']">
				<UserItem>{{ text }}</UserItem>
			</div>
		</div>

		<div class="user__item text-center d-md-none mb-4">
			<div v-for="text in this.typesInfo['skills'].slice(0, 5)">
				<UserItem>{{ text }}</UserItem>
			</div>
			<button class="user__more \ btn-block dropdown-toggle mt-2 mb-2" type="button" @click="isShowSkils = !isShowSkils"
				v-if="!isShowSkils">
				{{ $t("headers_personal_account.btn_show_more") }}
			</button>
			<transition-group name="flip-list">
				<div v-for="text in this.typesInfo['skills'].slice(5)" :key="text">
					<UserItem v-if="isShowSkils">{{ text }}</UserItem>
				</div>
			</transition-group>
			<button class="user__more mt-2 mb-2" type="button" @click="isShowSkils = !isShowSkils" v-show="isShowSkils">
				{{ $t("headers_personal_account.btn_hide") }}
			</button>
		</div>

		<div class="mb-4">
			<UserBlockTitle :title="$t('headers_personal_account.posotions')"></UserBlockTitle>
		</div>
		<div class="user__item d-none d-md-flex mb-4">
			<div v-for="text in this.typesInfo['positions']">
				<UserItem>{{ text }}</UserItem>
			</div>
		</div>

		<div class="user__item text-center d-md-none mb-4">
			<div class="" v-for="text in this.typesInfo['positions'].slice(0, 5)">
				<UserItem>{{ text }}</UserItem>
			</div>
			<button class="user__more dropdown-toggle mt-2 mb-2" type="button" @click="isShowPositions = !isShowPositions"
				v-if="!isShowPositions">
				{{ $t("headers_personal_account.btn_show_more") }}
			</button>
			<transition-group name="flip-list">
				<div v-for="text in this.typesInfo['positions'].slice(5)" :key="text">
					<UserItem v-if="isShowPositions">{{ text }}</UserItem>
				</div>
			</transition-group>
			<button class="user__more mt-2 mb-2" type="button" @click="isShowPositions = !isShowPositions"
				v-show="isShowPositions">
				{{ $t("headers_personal_account.btn_hide") }}
			</button>
		</div>
	</div>
</template>


<script>
import UserBlock from "@/components/User/UserBlock";
import UserTextBlock from "@/components/User/UserTextBlock";
import UserBlockTitle from "@/components/User/UserBlockTitle";
import UserItem from "@/components/User/UserItem.vue";
import UserTableUsers from "@/components/User/UserInTeamCard.vue";
import api from "@/api";
import { mapGetters } from "vuex";
import typesUtils from "@/mixins/typesUtils";
import Loader from "@/components/UI/Loader.vue";
import BackAndMainButtons from "@/components/UI/BackAndMainButtons.vue";
export default {
	components: { BackAndMainButtons, UserBlock, Loader, UserTextBlock, UserBlockTitle, UserItem, UserTableUsers },
	mixins: [typesUtils],
	data() {
		return {
			typesInfo: {},
			id: '',
			isShowSkils: false,
			isShowPositions: false,
			isPageLoaded: false,
		}
	},
	methods: {
		getType(id) {
			this.isPageLoaded = false;
			api.getTypesInfo().then(r => {
				this.id = id;
				this.getTypeGenius(id);
				this.isPageLoaded = true;
			})
		},
		getTypeGenius(id) {
			const currentLocale = this.$i18n.locale;
			let info = require(`@/i18n/locales/${currentLocale}/result_genius.json`);
			this.typesInfo = info[id];
		}
	},

	beforeMount() {
		this.getType(this.$route.params.id);
	},
	async beforeRouteUpdate(to, from) {
		this.getType(to.params.id)

	},
	computed: {
		...mapGetters([
			'isAnyLoading',
		]),
		imageUrl() {
			let addres = this.id;
			return require(`@/assets/pure_type/${addres}.png`);
		}
	},



}
</script>

<style lang='scss' scoped>
* {
	font-family: 'Ubuntu', sans-serif;
	color: #1E1E1E;
	//font-weight: 400;
	//font-size: 1rem;
	//line-height: 110%;
}

.block__loader {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
	color: white;
}

.row {
	font-size: 14px;
}

.flip-list-move {
	transition: all 0.5s;
}

.flip-list-item {
	display: inline-block;
	margin-right: 10px;
}

.flip-list-enter-active,
.flip-list-leave-active {
	transition: all 1s;
}

.flip-list-enter,
.flip-list-leave-to

/* .list-leave-active до версии 2.1.8 */
	{
	opacity: 0;
	transform: translateY(30px);
}

.header {
	text-align: center;
	padding: 20px 0;
	border-radius: 14px;
	margin-bottom: 25px;

	h2 {
		font-weight: 700;
		font-size: 25px;
		line-height: 106%;
		margin: 0 auto;
		color: white;
		text-transform: uppercase;
	}
}

.user {
	&__superpowers {
		display: flex;
		flex-direction: column;


	}


	&__superpower {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #fff;
		border-radius: 14px;
		padding: 10px 34px;
		margin-bottom: 10px;

		&:last-child {
			margin: 0;
		}

		.title {
			margin-right: 20px;
			font-weight: 700;
			font-size: 16px;
			line-height: 106%;
		}

		.subtitle {
			font-weight: 400;
			font-size: 14px;
			line-height: 106%;

		}
	}

	&__mainInfo {
		background-color: #fff;
		border-radius: 21px;
		display: flex;
		flex-direction: column;
		padding: 30px;
		.title {
			font-weight: bold;
			font-size: 16px;
			margin: 0 0 20px ;
		}

		.subtitle {
			font-size: 14px;
		}
	}

	&__img {
		width: 100%;
		overflow: hidden;
		object-fit: cover;
		border-radius: 21px;
	}

	&__text {
		font-size: 18px;
	}

	&__results {
		width: 40%;
	}

	&__item {
		div {
			margin: 3px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
		}

	}

	&__more {
		color: #1E1E1E;
		font-weight: 500;
		font-size: 16px;
		background: #E7E7E7;
		border-radius: 21.8873px;
		border: none;
		padding: 8px 52px;

		&:hover {
			transform: scale(105%);
		}
	}


}


@media (min-width: 576px) {
	.user__superpower .title {
		font-size: 16px;
	}
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
	.user__item {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {

	.user {

		&____result {
			width: 35%;
		}

		&__superpowers {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		&__superpower {
			margin-bottom: 0;
			margin-right: 10px;
		}

		&__mainInfo {
			height: 335px;


		}
		&__img {
			margin-left: 24px;
			max-width: 335px;
		}
	}
}




/*// X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
	.user__mainInfo {
		padding: 30px 40px;

	}
}

/*// XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {
	#user {
		padding: 30px 40px;
	}

	.user__mainInfo {

	}

}
</style>
