<template>
	<div @click="closeModal">
		<transition name="modal">

      <ModalInfo v-if="isShowModal" :isTransparentBackground="true" @close="isShowModal = false">
        <div class="plug" v-if="isShow.talentTest">
          <div class="row">
            <div class="col-12 col-lg-6 p-0">
              <div class="plug__text my-auto mx-auto h-100">
                <div class="plug__title">{{ $t("default_information.about_test") }}</div>
                <div>
                  <p class="mb-3" v-html="$t('default_information.main') ">
                  </p>
                </div>

              </div>
            </div>

            <div class="col-12 col-lg-6 d-flex flex-column justify-content-between">
              <div>
                <div class="plug__warn w-100 bg-white">
                  <img src="@/assets/user/visible.svg" alt="icon">
                  <div v-html="$t('default_information.info_visibility_result')"></div>
                </div>
                <div class="plug__warn w-100 bg-white">
                  <img src="@/assets/user/clock.svg" alt="icon">
                  <div v-html="$t('default_information.info_duration')"></div>
                </div>
                <div class="plug__warn w-100 bg-white">
                  <img src="@/assets/user/info_black.svg" alt="icon">
                  <div>
                    <p class="mb-2" v-html="$t('default_information.add_infotmation')"></p>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-center">
                <button class="plug__button mt-3 mt-lg-0"
                        @click="this.isShowModal = !this.isShowModal">{{$t("popup_info.btn_start") }}</button>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="isShow.levelTest" class="plug">
            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="plug__text bg-white my-auto mx-auto h-100">
                  <div class="plug__title">Уровни осознанности (демо-режим)</div>
                  <div>
                    <p class="mb-3">
                      Уровень осознанности — это сочетание переживаний, убеждений и потребностей, свойственных человеку в
                      определенный период жизни. Ваш текущий уровень сознания влияет на ваш образ мышления и отражается на всем,
                      что
                      вы делаете.
                      На каждом уровне есть доминирующая эмоция и модель восприятия, от которых зависят не только желания и
                      мотивация
                      человека, но и его физические и моральные силы.
                    </p>
                    <p class="m-0">
                      Наш опрос поможет вам разобраться, как именно вы видите окружающий мир. Вы не только узнаете распределение
                      своего сознания по 7 уровням, но и получите ценные советы для выстраивания эффективного взаимодействия с
                      окружающими
                      и развития своей сознательности.
                    </p>
                  </div>

                </div>
              </div>

              <div class="col-12 col-lg-6 mt-3 mt-lg-0 d-flex flex-column justify-content-between">
                <div>
                  <div class="plug__warn w-100 bg-white">
                    <img alt="icon" src="@/assets/user/visible.svg">
                    <div>Ваш результат будет <b>виден только вам и вашему руководителю.</b></div>
                  </div>
                  <div class="plug__warn w-100 bg-white">
                    <img alt="icon" src="@/assets/user/clock.svg">
                    <div>Прохождение занимает <b>примерно 15 минут.</b></div>
                  </div>
                </div>

                <div class="d-flex justify-content-center">
                  <button class="plug__button mt-3 mt-lg-0"
                          @click="isShowFeedback = true">К тесту
                  </button>
                </div>
              </div>
            </div>
        </div>
        <div v-else class="plug">
          <div class="row">
            <div class="col-12 col-lg-6 p-0">
              <div class="plug__text my-auto mx-auto h-100">
                <div class="plug__title">Тест на компетенции (демо-режим)</div>
                <div>
                  <p class="mb-3" >
                    Хотите узнать, насколько хорошо вы работаете в команде, управляете временем и справляетесь со стрессом? Наш тест на компетенции - это уникальный инструмент, который поможет вам оценить свои soft skills и получить ценную информацию для личного и профессионального роста.
                    <br><br>
                    Тест поможет вам выявить ключевые направления для вашего роста и развития навыков. Это укрепит вашу уверенность, повысит мотивацию и даст объективную оценку вашего потенциала. Пройдя тест, вы получите ценные инсайты для построения карьеры и достижения целей.
                  </p>
                </div>

              </div>
            </div>

            <div class="col-12 col-lg-6 d-flex flex-column justify-content-between">
              <div>
                <div class="plug__warn w-100 bg-white">
                  <img src="@/assets/user/visible.svg" alt="icon">
                  <div>
                    Ваш результат будет <b>виден только вам и вашему руководителю.</b>
                  </div>
                </div>
                <div class="plug__warn w-100 bg-white">
                  <img src="@/assets/user/clock.svg" alt="icon">
                  <div>Прохождение занимает <b>примерно 15 минут.</b></div>
                </div>
                <div class="plug__warn w-100 bg-white">
                  <img src="@/assets/user/info_black.svg" alt="icon">
                  <div>
                    <p class="mb-2">
                      Старайтесь задуматься над каждым утверждением и оценить, на сколько оно подходит именно вам. Отвечайте честно и вдумчиво для получения наиболее достоверного результата.
                    </p>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-center">
                <button class="plug__button mt-3 mt-lg-0"
                        @click="this.isShowModal = !this.isShowModal">{{$t("popup_info.btn_start") }}</button>
              </div>
            </div>
          </div>
        </div>
      </ModalInfo>
		</transition>
	</div>
</template>

<script>
import Modal from '@/components/UI/Modal/Modal.vue'
import ModalInfo from "@/components/UI/Modal/ModalInfo.vue";

export default {
	emits: ['closeModal'],
	components: {
    ModalInfo,
		Modal
	},
	props: {
		isShowModal: {
			type: Boolean,
			default: false
		},
		isShow: {
			type: Object,
			default: true
		},
	},
	methods: {
		closeModal() {
			this.$emit('closeModal')
		}
	}
}

</script>

<style lang="scss" scoped>
.modal {
	&__title {
		padding: 7px 0;
		margin: auto;
		font-weight: 700;
		font-size: 13px;
		line-height: 39px;
		text-align: center;
		border-radius: 20px;
		margin-bottom: 10px;
	}

	&__subtitle {
		font-weight: 400;
		font-size: 10px;
		line-height: 15px;
		padding: 10px 20px;
		border-radius: 20px;
		text-align: justify;
		margin-bottom: 10px;
	}

	&__button {
		background-color: #090D39;
		color: #fff;
		font-weight: 700;
		font-size: 13px;
		line-height: 21px;
		padding: 7px 60px;
		border-radius: 15px;
		border: none;
	}
}


.plug {
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 30px;

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    margin: 0 0 15px;

  }

  &__text {
    font-weight: 400;
    font-size: 13px;
    padding: 16px 22px;
    margin: 0;
    border-radius: 20px;
    text-align: justify;
  }

  &__warn {
    font-weight: 400;
    font-size: 13px;
    border-radius: 13px;
    padding: 16px 22px;
    display: flex;
    text-align: justify;

    p {
      margin: 0;
    }

    img {
      margin-right: 20px;
      align-self: start;
    }

  }

  &__button {
    background: #090D39;
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    padding: 15px 120px;
    //width: 100%;
  }
}


@media (min-width: 400px) {
	.modal__subtitle {
		font-size: 12px;
		line-height: 17px;
		padding: 20px 40px;
	}
}

@media (min-width: 768px) {

	.modal {
		&__title {
			font-size: 24px;
			padding: 15px 0;
			margin-bottom: 15px;
		}

		&__subtitle {
			font-size: 14px;
			line-height: 21px;
			padding: 20px 50px;
			margin-bottom: 15px;
		}

		&__button {
			font-size: 18px;
			padding: 15px 80px;
		}
	}

  .plug__warn {
    img {
      margin-right: 15px;
    }
  }

  .plug__text {
    //padding: 31px 31px 0;
  }
}

@media (min-width: 998px) {
  .plug__warn {
    padding: 16px 22px;
  }
}

</style>