<template>
	<div class="form__item">
		<label :class="{red: errors.length !== 0}" class="form__label">{{ label }}</label>
		<input :value="modelValue" class="form__input" :type="type" :readonly="readonly" @input="onChange">
	</div>
	<div class="error">
		<p class="mx-1" v-for="err in errors">{{ err.$message }}</p>
	</div>
</template>


<script>

export default {
	name: "UserProfileInput",

	props: {
		modelValue: String,
		label: String,
		type: String,
		readonly: Boolean,
		errors: Object,
	},
	methods: {
		onChange(e) {

			this.$emit("update:modelValue", e.target.value);
		}
	},
}
</script>

<style lang="scss" scoped>
.form {

	&__item {
		margin-top: 12px;

	}

	&__label {
		font-size: 12px;
		opacity: 0.5;
	}

	&__input {
		border: none;
		width: 100%;
		background: #E6E6E6;
		border-radius: 9.47142px;
		font-size: 11.9797px;
		color: #000000;
		padding: 5px 12px;

		&:focus {
			outline-color: #090D39;
		}
	}
}
.red{
  color:red;
  opacity: 1;
}
.error {
	position: absolute;
	color: red;
	font-size: 12px;
	display: flex;
}
</style>