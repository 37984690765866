<template>
  <div class="row mx-2 gx-lg-5">
    <div id="featureImgColumn" class=" col-12 col-lg-5 order-lg-2 ">
      <img id="featureImg" :src="imageUrl"  alt="result">
    </div>
    <div class="col-12 col-lg-7 order-lg-1 featuresDescription">
      <p id="pDescription" class="pDescription">{{ text }}</p>
      <div class="orderFeature">
        <ButtonDemo :title="btnTitle" @click="routerToPage"></ButtonDemo>
      </div>
    </div>

  </div>

</template>

<script>

import {defineComponent} from "vue";
import ButtonDemo from "@/components/Landings/component/ButtonDemo.vue";
import {useI18n} from "vue-i18n";

export default defineComponent({
  components: {ButtonDemo},
  setup() {
    const { t } = useI18n()
    return { t }
  },
  props: {
    text: {
      type: String,
      required: true
    },
    isGeniusTest: {
      type: Boolean,
      required: true
    },
    btnTitle: {
      type: String,
      required: true
    },
  },
  computed: {
    imageUrl() {
      if(this.isGeniusTest) {
        let addres = this.$t('img');
        return require(`@/assets/locales/${addres}`);
      } else {
        return require(`@/assets/landing-main/leves.png`);
      }
    }
  },
  methods: {
    routerToPage() {
      if (this.isGeniusTest) {
        window.open('/genius-profile', '_blank');
      } else {
        this.$router.push('/test');
      }
    }
  }
})
</script>

<style scoped lang="scss">

#featureImg {
  max-height: 250px;
  margin-left: 0;
  margin-bottom: 40px;
}

#featureImgColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}


.featuresDescription {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 30px;
  text-align: left;
  max-width: 600px;
}

.pDescription{
  font-size: 16px;
  margin: 0;
}

.orderFeature{
  margin-top: 30px;
}

@media (min-width: 992px) {

  #featureImg {
    margin-left: 30%;
    margin-bottom: 0;
  }

}

</style>