<template>
 <div class="w-100 mt-3 container">
    <BackAndMainButtons :show-main="false"></BackAndMainButtons>
  </div>
  <Loader v-if="isAnyLoading"></Loader>

    <div v-else class="bg-white p-5 rounded-5">
      <div class="user__form">
        <UserUpdateForm :email="user.email"
                        :first-name="user.firstName"
                        :hide-talent="user.hideTalent"
                        :last-name="user.lastName"
                        :position="user.position"
                        :phone="user.phone"
                        @update="update"
        ></UserUpdateForm>
      </div>
    </div>
</template>

<script>
import BackAndMainButtons from "@/components/UI/BackAndMainButtons.vue";
import {mapActions, mapGetters} from "vuex";
import Loader from "@/components/UI/Loader.vue";
import api from "@/api";
import UserUpdateForm from "@/components/User/UserUpdateForm.vue";

export default {
  name: "UserSettingsByAdmin",
  components: {Loader, BackAndMainButtons,UserUpdateForm},
  computed: {
    ...mapGetters({
      isAnyLoading: ['isAnyLoading'],
    })
  },
  mounted() {
     api.getUserById(this.$route.params.id).then(r => {
       this.user = r.data;
     })
  },
  methods:{
    update(data){
      api.updateUserById(this.user.id, data.firstName, data.lastName, data.phone, data.position,data.hideTalent).then(r => {
        this.user = r.data;
      })
    }
  },
  data(){
    return{
      user: {}
    }
  }
}
</script>

<style scoped>

</style>