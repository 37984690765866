<template>
  <div class="container type_profile">
    <div class="title">
      <h2>Типы профилей</h2>
      <p>Чтобы эффективно использовать ресурсы человека,
        нужно понимать, что он умеет делать лучше всего.
      </p>
    </div>

    <div class="container cards">
      <div v-for="elem in cardsType">
        <CardTypeProfile
            :title="elem.title"
            :sub-title="elem.subTitle"
            :path="elem.path">
        </CardTypeProfile>
      </div>
    </div>
  </div>

</template>

<script>
import CardTypeProfile from "@/components/Landings/LandingProfileGenius/LandingTypeProfile/CardTypeProfile.vue";

export default {
  components: {
    CardTypeProfile
  },
  data() {
    return {
      cardsType: {
        creator: {
          title: 'Мечтатель',
          subTitle: 'Интуиция, воображение, готовность познавать и применять новое',
          path: 'creator'
        },
        driver: {
          title: 'Оратор',
          subTitle: 'Видеть в идее возможности и перспективы и зажигать ей окружающих',
          path: 'driver'
        },
        networker: {
          title: 'Связист',
          subTitle: 'Умение вести переговоры, приходить к компромиссу и налаживать связи',
          path: 'networker'
        },
        teambuilder: {
            title: 'Наставник',
            subTitle: 'Cпособность замечать и раскрывать таланты в других, объединять людей и создавать дружелюбную атмосферу',
            path: 'teambuilder'
        },
        realizator: {
          title: 'Ассистент',
          subTitle: 'Cпособность планировать, доводить дела до конца, соблюдать дедлайны и понимать, чего хотят окружающие',
          path: 'realizator'
        },
        checkman: {
          title: 'Инспектор',
          subTitle: 'Контроль людей и процессов, оценка рисков и результатов работы',
          path: 'checkman'
        },
        analyst: {
          title: 'Конструктор',
          subTitle: 'Систематизация информации, анализ показателей, автоматизация процессов и повышение продуктивности',
          path: 'analyst'
        },
        improver: {
          title: 'Ювелир',
          subTitle: 'Способность замечать недостатки и доводить до совершенства проекты и технологии',
          path: 'improver'
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.type_profile {
  margin-top: 120px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: #F6F6F5;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    margin-top : 20px;
    margin-bottom: 20px;
  }
}

.cards {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .type_profile {
    margin-top: 200px;
  }
}

@media (min-width: 992px) {
  .cards {
    margin-right: 30px;
    margin-left: 30px;
    flex-direction: row;
  }
}
</style>