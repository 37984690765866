<template>
  <div class="tryIt">
    <div >
        <p class="tryIt__title h2">{{ $t("block_time_start.title") }}</p>
    </div>

    <div class="orderTryIt">
      <ButtonDemo :title="$t('block_time_start.to_try')" @click="$router.push('/register')"  ></ButtonDemo>
    </div>


    <div class="background_start" v-if="isShowBackground">
      <img src="../../assets/landing-profile-genius/background/background_start.svg" alt="background_start">
    </div>
  </div>
</template>

<script>
import ButtonDemo from "@/components/Landings/component/ButtonDemo.vue";
import {useI18n} from "vue-i18n";

export default {
  components: {
    ButtonDemo
  },
  props: {
    isShowBackground: {
      type: Boolean,
      default: true
    }
  }
}

</script>

<style scoped lang="scss">

.tryIt {
  font-weight: 500;
  margin-top: 150px;
  margin-bottom: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tryIt__title {
  text-align: center;
  margin-bottom: 40px;

  p {
    margin-bottom: 0;
  }
}

.orderTryIt {
  max-width: 1200px;
  z-index: 2;

  a {
    display: inline-block;
    background: #2835C1;
    margin-top: 30px;
    padding: 15px 60px;
    text-decoration: none;
    color: #F6F6F5;
    font-size: 16px;
    font-weight: 400;
    transition: 0.2s;
  }
}

.background_start {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;


  img {
    width: 90vw;
    max-height: 700px;
  }
}

@media (min-width: 500px) {
  .background_start {
    top: -100px;
  }
}

@media (min-width: 756px) {
  .background_start {
    top: -150px;
  }
}

@media (min-width: 956px) {
  .background_start {
    top: -250px;
  }
}

@media(min-width: 1200px) {
  .background_start {
    top: -350px;
  }
}

</style>