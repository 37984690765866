<template>
  <div v-if="!token" id="recovery" class="w-100 container">
    <div v-if="!isRecoverySend">
      <FormWrapper :is-any-error="isEmailError" :is-form-disabled="false" :is-show-footer="false" :min-height="0">
        <FormTitle :title="$t('password_recovery.password_recovery')"></FormTitle>
        <form class="login__form form ">
          <FormItem :is-error="isEmailError" :label="$t('password_recovery.email')">
            <input @keydown.space.prevent id="email" v-model.trim="v$.email.value.$model" class="form__input" type="email"
                   @focus="resetLoginError">
          </FormItem>
          <label v-if="notFound" style="color: red; font-size: 14px;" for="">{{$t("password_recovery.email_not_found")}}</label>
          <FormButton :is-any-error="isEmailError" :is-loading="isLoading" :title="$t('password_recovery.confirm')"
                      @submitForm="createRecovery">
          </FormButton>
        </form>
      </FormWrapper>
    </div>
    <div v-else class="text-center mt-5">
      <h3>Проверьте ваш почтовый ящик</h3>
    </div>
  </div>
  <div v-else id="change">

    <FormWrapper :is-any-error="isPasswordError" :is-form-disabled="false" :is-show-footer="false" :min-height="0">
      <FormTitle :title="$t('password_recovery.input_new_password')"></FormTitle>
      <form class="login__form form ">
        <FormItem :label="$t('password_recovery.password')" :is-error="isPasswordError">
          <div class="password__container">
            <input @keydown.space.prevent id="password" @focus="resetLoginError" v-model.trim="v$.password.$model"
                   class="password__input form__input" type="password">
            <img v-show="!isPasswordError && password && !isPasswordHidden" src="@/assets/show-password.svg"
                 @click="hidePassword" class="password__eye" alt="">
          </div>
        </FormItem>
        <FormItem :label="$t('password_recovery.repeat_password')" :is-error="isPasswordError">
          <div class="password__container">
            <input @keydown.space.prevent id="passwordConfirm" @focus="resetLoginError" v-model.trim="v$.confirmPassword.$model"
                   class="password__input form__input" type="password">
            <img v-show="!isPasswordError && password && !isPasswordHidden" src="@/assets/show-password.svg"
                 @click="hidePassword" class="password__eye" alt="">
          </div>
        </FormItem>
        <div v-for="err in v$.$errors">
          <FormError :msg="err.$message"></FormError>
        </div>
        <FormButton :is-any-error="isPasswordError" :is-loading="isLoading" :title="$t('password_recovery.confirm')"
                    @submitForm="changePassword">
        </FormButton>
      </form>
    </FormWrapper>
  </div>
</template>

<script>

import FormTitle from "@/components/Form/FormTitle.vue";
import FormItem from "@/components/Form/FormItem.vue";
import FormButton from "@/components/Form/FormButton.vue";
import FormWrapper from "@/components/Form/FormWrapper.vue";
import useVuelidate from "@vuelidate/core";
import {email, helpers, minLength, required, sameAs} from "@vuelidate/validators";
import api from "@/api";
import showPasswordMixin from "@/mixins/showPasswordMixin";
import FormError from "@/components/Form/FormError.vue";
import {ref} from "vue";
import utf from "utf8";

export default {
  name: "PasswordRecovery",
  components: {FormError, FormButton, FormItem, FormTitle, FormWrapper},
  mixins: [showPasswordMixin],

  setup() {
    const externalResults = ref({})
    return {
      externalResults,
      v$: useVuelidate({ $externalResults: externalResults })
    }
  },
  validations() {
    return {
      email: {
        value: {
          email: helpers.withMessage('Введите валидный email', email),
          required: helpers.withMessage('Email обязателен', required),
        }
      },
      password:{
        required: helpers.withMessage('Пароль обязателен', required),
        minLength: helpers.withMessage('Пароль не менее 8 символов', minLength(8)),
      },
      confirmPassword:{
        sameAs: helpers.withMessage('Пароли не совпадают', sameAs(this.password))
      }
    }
  },
  computed: {
    isEmailError() {
      return this.v$.email.value.$errors.length !== 0 || this.email.error;
    },
    isPasswordError() {
      return this.v$.password.$errors.length !== 0 || this.isPasswordInvalid
          || this.v$.confirmPassword.$errors.length !== 0
    },
  },
  methods: {
    passwordInvalid(arr){
      const errors = {
        password: arr
      }
      Object.assign(this.externalResults, errors)
      this.isPasswordInvalid = true
    },
    passwordErrorsToUtf(errorStr) {
      errorStr = errorStr.slice(2,-2);
      return utf.decode(errorStr).split(',');
    },
    async changePassword(){
      this.v$.password.$touch()
      this.v$.confirmPassword.$touch()
      if(!this.v$.password.$invalid && !this.v$.confirmPassword.$invalid){
          api.changePasswordByRecovery(this.token, this.password)
              .then( r => {this.$router.push("/login?action=pswd_changed");})
              .catch(err=> {
                if (err.response.status === 400) {
                    this.passwordInvalid(this.passwordErrorsToUtf( JSON.stringify(err.response.data.errors)))
                } else if (err.response.status === 404) {
                    this.passwordInvalid(['Невозможно восстановить пароль',' Попробуйте снова ввести почту'])
                }
              })
      } else {
        this.isPasswordInvalid= true;
      }
    },


    async createRecovery() {
      this.v$.email.value.$touch()
      if (!this.v$.email.value.$invalid) {
        this.isLoading = true

        api.createPasswordRecovery(this.email.value).then(
            r => {
              this.isRecoverySend = true
              this.isLoading = false
            }
        ).catch(
            err => {
              this.notFound =true;
              this.email.error = true
              this.isLoading = false
            }
        )
      }
    },
    resetLoginError() {
      this.notFound =false
      this.email.error = false
      this.isPasswordInvalid = false
      this.v$.password.$reset();
      this.v$.confirmPassword.$reset();
    }
  }
  ,
  data() {
    return {
      notFound: false,
      email: {
        value: '',
        error: false
      },
      isLoading: false,
      isRecoverySend: false,
      token: this.$route.query.t,
      password: '',
      confirmPassword: '',
      isPasswordInvalid: false
    }
  }
}
</script>

<style lang="scss" scoped>

#recovery, #change {
  width: 100%;
  max-width: 540px;
  padding: 0 15px;
}

.form {
  width: 100%;
  margin: 35px;

  &__input {
    margin-top: 7px;
    padding: 10px 5px 10px 20px;
    border: 0.8px solid black;
    border-radius: 5.84534px;
    height: 50px;
    transition: .2s all linear;

    &:focus {

      outline: none !important;
      transform: scale(102%);
      border: 0.8px solid black
    }
  }
  .password {
    &__container {
      display: flex;
      align-items: center;
      margin-top: 7px;
    }

    &__input {
      margin-top: 0;
      width: 100%;
      position: relative;
    }

    &__eye {
      left: 88%;
      width: 25px;
      height: 25px;
      opacity: 0.7;
      position: absolute;
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }

  @media (min-width: 500px) {
    .password__eye{
      left: 90%;
    }
  }


  .password {
    &__container {
      display: flex;
      align-items: center;
      margin-top: 7px;
    }

    &__input {
      margin-top: 0;
      width: 100%;
      position: relative;
    }

    &__eye {
      left: 88%;
      width: 25px;
      height: 25px;
      opacity: 0.7;
      position: absolute;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }

  @media (min-width: 500px) {
    .password__eye {
      left: 90%;
    }
  }
}

</style>
