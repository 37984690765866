<template>
 <div :class="{red : isRed,green : isGreen, 'bg-white p-4 p-lg-5' : !withoutPadding , isFont: isSmallFont}">
   <slot></slot>
 </div>
</template>

<script>
export default {
  name: "UserBlock",
  props:{
    isGreen:{
      type: Boolean,
      require: false
    },
    isRed:{
      type: Boolean,
      require: false
    },
    withoutPadding:{
      type: Boolean,
      require: false
    },
		isSmallFont: {
			type: Boolean,
			default: false
		}
  }
}
</script>

<style scoped>

div{
  border-radius: 21px;
  width: 100%;
  height: 100%;

}
div.red{
  border: 2px red solid;
}
div.green{
  border: 2px green solid;
}

div.isFont {
	font-size: 14px;
	background-color: #fff;
	padding: 1.5rem;
}
</style>
