<template>
    <div :class="{
        'opened': status == 'OPENED'
    }" class="status">{{ text }}</div>
</template>
<script>
export default {
    props: {
        status: {
            type: String,
            required: true
        }
    },
    computed: {
        text() {
            if (this.status == 'OPENED') {
                return 'Открыто'
            } else
                if (this.status == 'ACCEPTED') {
                    return 'Принято'
                } else {
                    return 'Отклонено'
                }
        }
    }
}
</script>
<style scoped>
.status {
    font-weight: 700;
    font-size: 11px;
    line-height: 100%;
  background: #DADADA;
  color: #fff;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    text-align: center;


}


.opened {
    background: #fff;
    color: #000;
}

@media (min-width: 576px) {
    .status {

        padding: 15px 21px;
        font-size: 15px;
        min-width: 153px;

    }
}

@media (min-width: 988px) {
    .status {

        font-size: 17px;


    }
}
</style>