<template>
	<div id="card" :class="{ active: isSmallVersion }">
		<img alt="" class="card__img" :src="imgURL">
		<div v-html="text" class="card__text"></div>
	</div>
</template>

<script>
export default {
	name: "LandingHelpCard",
	props: {
		text: {
			type: String
		},
		imgURL: {
			type: String,
		},
		isSmallVersion: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="scss" scoped>
#card {
	display: inline-flex;
	align-items: center;
	background-color: #fff;
	border: 1px solid rgba(239, 239, 239, 0.6);
	padding: 23px;
	box-shadow: 0 0 29px rgba(99, 122, 166, 0.3);
	border-radius: 8px;
	width: 100%;
}

.active {
	padding: 15px;
	border-radius: 21px;
	.card__text {
		font-size: 14px;
	}
}

.card__img {
	width: 60px;
	height: 60px;
	max-width: 100%;
	margin-right: 35px;
}

.card__text {
	color: #2C3847;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 900;
}

@media (min-width: 576px) {
	#card {
		min-width: 420px;
	}
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
	#card {
		padding: 28px;
		min-width: 520px;
	}

	.card__img {}

	.card__text {

		font-size: 16px;
	}
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
	#card {
		min-width: 430px;
	}

	.card__img {
		width: 60px;
		height: 60px;

	}

}

/*// X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {}

/*// XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {

	#card {
		min-width: 526px;

	}

	.active {
		.card__text {
		font-size: 14px;
	}
	}

	.card__img {
		margin-right: 35px;
	}

	.card__text {
		font-size: 18px;
	}
}
</style>
