<template>
	<div class="container block my-5 w-50">
		<div style="margin: 0 auto; text-align: center;">
			<div class=" px-auto">
				<img src="@/assets/icon/icon_success.svg" alt="icon_success">
				<h1 class="mt-4" style="font-size: 18px;">Оплата прошла успешно</h1>
				<p v-if="this.data.Shp_amount > 1" style="font-size: 18px;">Вашей компании добавлены {{ this.data.Shp_amount }} {{ getNotificationString }}
				</p>
				<p v-else-if="this.data.Shp_amount == 1" style="font-size: 18px;">Вашей компании добавлен {{ this.data.Shp_amount }} {{
					getNotificationString }}</p>
				<p class="text-center" style="font-size: 18px;">Спасибо за то, что выбрали TeamWisdom</p>
			</div>

			<button class="button" @click="this.$router.push('/users')">Вернуться в личный кабинет</button>
		</div>
	</div>
</template>

<script>
export default {

	data() {
		return {
			isSuccessfulPayment: false,
			title: '',
			message: '',
			data: {},
		}
	},
	beforeMount() {
		let dataPayment = this.$route.query;
		Object.keys(dataPayment).forEach(key => { this.data[key] = dataPayment[key] })
		if (this.data.isPayment == 'true') {
			this.data.isPayment = true
		} else {
			this.data.isPayment = false
		}
	},
	computed: {
		getNotificationString() {
			switch (this.data.Shp_amount % 10) {
				case 1:
					return 'доступ';
					break;
				case 2:
				case 3:
				case 4:
					return 'доступа';
					break;
				default:
					return 'доступов';
			}
		}
	},
	methods: {
	}
}

</script>

<style scoped lang="scss">
.block{ 
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: 0 auto;
}
.button {
	font-size: 15px;
	padding: 8px 15px;
	background-color: #090D39;
	margin: 0 auto;
	color: #fff;
	font-weight: 700;
	line-height: 21px;

	border-radius: 15px;
	border: none;
	transition: all .2s linear;

	&:hover {
		transform: scale(1.02);
	}
}
</style>