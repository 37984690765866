<template>
  <div class="header-account d-flex align-items-center">
    <div class="header-account__date me-3">{{ formatedDate }}</div>

    <svg height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg"
         @click="this.$emit('hideMenu'); logout()">
      <path
          d="M1.55556 14C1.12778 14 0.761444 13.8478 0.456555 13.5434C0.152185 13.2386 0 12.8722 0 12.4444V9.33333H1.55556V12.4444H12.4444V1.55556H1.55556V4.66667H0V1.55556C0 1.12778 0.152185 0.761444 0.456555 0.456555C0.761444 0.152185 1.12778 0 1.55556 0H12.4444C12.8722 0 13.2386 0.152185 13.5434 0.456555C13.8478 0.761444 14 1.12778 14 1.55556V12.4444C14 12.8722 13.8478 13.2386 13.5434 13.5434C13.2386 13.8478 12.8722 14 12.4444 14H1.55556ZM5.83333 10.8889L4.74444 9.76111L6.72778 7.77778H0V6.22222H6.72778L4.74444 4.23889L5.83333 3.11111L9.72222 7L5.83333 10.8889Z"/>
    </svg>

  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  data: () => ({
    date: new Date(),
  }),
  mounted() {
    setInterval(this.updateTime, 10000);
  },
  methods: {
    updateTime() {
      this.date = new Date();
    },
    ...mapActions({
      logoutUser: "AuthModule/onLogout"
    }),
    logout() {
      this.logoutUser();
    },
  },
  computed: {
    formatedDate() {
      return this.date.toLocaleString("ru-RU").replace(",", "").slice(0, -3)
    }

  }
}
</script>
<style lang="scss" scoped>
.header-account {
  display: flex;
  max-width: 220px;
  justify-content: center;
  margin: 15px auto;
  align-items: center;

  & svg {
    cursor: pointer;
    fill: black;
  }

}


@media (min-width: 768px) {

  .header-account {
    display: flex;
    align-items: center;
    margin: 0;

    & svg {

      fill: white;
    }

  }

}
</style>