<template>
	<div class="container block my-5 w-50">
		<div style="margin: 0 auto; text-align: center;">
			<div class=" text-center px-auto mx-auto">
				<img src="@/assets/icon/icon_warning.svg" alt="icon_warning">
				<h1 class="pb-3 mt-4" style="font-size: 18px;">Ошибка оплаты</h1>
				<p>Во время обработки вашего платежа возникла проблема, и он не был завершен.<br> Пожалуйста, <a href="mailto:support@teamwisdom.ru">свяжитесь с нами</a></p>
			</div>
			<button class="button" @click="this.$router.push('/users')">Вернуться в личный кабинет</button>
		</div>
	</div>
</template>

<script>
export default {

	data() {
		return {
			isSuccessfulPayment: false,
			title: '',
			message: '',
			data: {},
		}
	},
	beforeMount() {
		let dataPayment = this.$route.query;
		Object.keys(dataPayment).forEach(key => { this.data[key] = dataPayment[key] })
		if (this.data.isPayment == 'true') {
			this.data.isPayment = true
		} else {
			this.data.isPayment = false
		}
	},
	computed: {
		getNotificationString() {
			switch (this.data.Shp_amount % 10) {
				case 1:
					return 'доступ';
					break;
				case 2:
				case 3:
				case 4:
					return 'доступа';
					break;
				default:
					return 'доступов';
			}
		}
	},
	methods: {
	}
}

</script>

<style scoped lang="scss">
.block{ 
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: 0 auto;
}
.button {
	font-size: 15px;
	padding: 8px 15px;
	background-color: #090D39;
	margin: 0 auto;
	color: #fff;
	font-weight: 700;
	line-height: 21px;

	border-radius: 15px;
	border: none;
	transition: all .2s linear;

	&:hover {
		transform: scale(1.02);
	}
}
</style>